import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from 'styled-components';
import dark from './styles/theme/dark';
import { AuthProvider } from './hooks/auth';
import GlobalStyle from './styles/GlobalStyle';
import { ActionProvider } from './hooks/actions';
import light from './styles/theme/light';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ActionProvider>
      <ThemeProvider theme={light}>
        <AuthProvider>
          <GlobalStyle />
          <App />
        </AuthProvider>
      </ThemeProvider>
    </ActionProvider>
  </React.StrictMode>
);


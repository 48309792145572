import React from 'react';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';


interface ModalProps {
    open: boolean;
    onClose: () => void;
    title: string;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    
    boxShadow: 24,
    p: 4,
};


const CustomModal: React.FC<ModalProps> = ({ open, onClose, title }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Text in a modal
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                </Typography>
            </Box>

        </Modal>
    );
};

export default CustomModal;
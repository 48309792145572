import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importe o useHistory do React Router
import { Button } from '@mui/material';
import { Container } from './styles';
import UCConsultarEntitiesContabilidadesByUserId from './useCases/uc-consultar-entidade-cliente';
import { useAuth } from '../../hooks/auth';


const MasterGroupView: React.FC = () => {

    const navigate = useNavigate()
    const { handleOpen, setTitle, setDialogContextText, signOut } = useAuth();

    const loadEntitiesByUserId = () => {
        
        UCConsultarEntitiesContabilidadesByUserId.run()

    }

    useEffect(() => { 

    }, [])

    return (
        <>
            <Button
                onClick={() => {
                    navigate('/')
                }}
            >Voltar  </Button>
            <Container>
                here
            </Container>
        </>
    )
}

export default MasterGroupView;
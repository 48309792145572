import styled from 'styled-components';

export const Containerd = styled.div`
    padding:50px 0 0 0;
    h2,h3{
        text-align:center;
        padding:0 0 5px 0;
        color:${props => props.theme.color.info};
        text-decoration:underline
    }    
    
    h4{        
        padding:0 0 5px 0;        
    }

    width:90%;
    margin:0 auto;

    .MuiAvatar-root{
        background-color:${props => props.theme.color.info}
    }

    .MuiPaper-root{
        span.destaque{
            font-weight:bolder;
            color:red;            
        }
    }
`


export const GridoContainer = styled.div`
    
    .MuiDataGrid-columnHeaderTitle{
        color: ${props => props.theme.color.black}
    }

    .MuiDataGrid-cellContent, .MuiDataGrid-cell--withRenderer{
        color: ${props => props.theme.color.info}
    }
`

export const Desktop = styled.div`
    display:none;    
    @media only screen and (min-width: 768px) {
        display:inline;        
    }  

`

export const Mobile = styled.div`
    display:none;
    @media only screen and (max-width: 767px) {        
        display:inline;        
    }    
`
import React, { useState } from 'react';
import '../navbar.css';
import { Button } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router-dom'; // Importe o useHistory do React Router
import { ROTA_LOGIN } from '../../../constants/uri/constants.uri';

const Navbar: React.FC = () => {
    const navigate = useNavigate()
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleToggle = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <a href="#" className="navbar-brand">O Guarda-Livros</a>
                <button className="navbar-toggle" onClick={handleToggle}>
                    ☰
                </button>
                <ul className={`navbar-menu ${isMobileMenuOpen ? 'active' : ''}`}>
                    <li>
                        <Button variant="text" color="inherit"
                            startIcon={<SupportAgentIcon />}
                            type="button" onClick={async () => {
                                window.location.href = 'mailto:suporte@oguardalivros.com';
                            }}> Contato
                        </Button>
                        {/* <a href="#" className="navbar-link">Contato</a> */}
                    </li>
                    <li>
                        <Button variant="text" color="inherit"
                            startIcon={<WhatsAppIcon />}
                            type="button" onClick={async () => {
                                window.open('https://wa.me/5513997784036', '_blank')//todo: cria constante
                            }}> WhatsApp/Suporte
                        </Button>
                    </li>
                    <li>
                    <Button variant="text" color="inherit"
                            startIcon={<LoginIcon />}
                            type="button" onClick={async () => {
                                navigate(ROTA_LOGIN)
                            }}> Login
                        </Button>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;

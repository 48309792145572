import React, { useEffect, useState } from 'react';
import Input from '../../components/Input';
import { useAuth } from '../../hooks/auth';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom'; // Importe o useHistory do React Router
import {
    Container,
    Logo,
    Form,
    FormTitle,
    CheckBoxLabel,
    Rodape,
} from './styles';
import { Button, Checkbox, CircularProgress, FormControlLabel, LinearProgress, Paper } from '@mui/material';
import UCForgetPasswordService from './useCases/uc-forget-password';
import { useActionContext } from '../../hooks/actions';
import { BarToProgress } from '../../components/Layout/styles';
import UCChangePasswordService from './useCases/uc-change-password';
import { ROTA_LOGIN } from '../../constants/uri/constants.uri';


const ForgetPass: React.FC = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmarPassword, setConfirmarPassword] = useState<string>('');
    const [codeotc, setCodeOtc] = useState<string>('');
    const [isFocused, setIsFocused] = useState(false);

    const { signIn,
        setDialogContextText,
        setTitle,
        handleOpen
    } = useAuth();


    const {
        showLinearProgress,
        linearProgressRunning
    } = useActionContext();

    const alerto = (title: string, content: string) => {
        handleOpen(true)
        setTitle(title)
        setDialogContextText(content)
    }

    const forgetPassword = async () => {
        if (!email) {
            setIsFocused(true)
            alerto('Info', 'Necessário informar o email')

        } else {
            showLinearProgress(true)
            const response: any = await UCForgetPasswordService.run(email)
            showLinearProgress(false)
            if (response.ok) {
                const responseResult = await response.json()
                alerto('Info', responseResult.message)
            }
        }
    }

    const clearForm = () => {
        setEmail('')
        setConfirmarPassword('')
        setCodeOtc('')
    }

    const handleCompararPassword = async (value: string) => {
        if (password != confirmarPassword) {
            alerto('Erro', 'A senha digitada não é igual a senha confirmada!')
        }
    }

    const handleTrocarPassword = async () => {
        showLinearProgress(true)
        if (!password ||
            !confirmarPassword ||
            !codeotc ||
            !email) {
            alerto('Info', 'Preencha todos os campos')
        } else {

            if (password != confirmarPassword) {
                alerto('Erro', 'A senha digitada não é igual a senha confirmada!')
            } else {
                const requestUCChangePasswordService = await UCChangePasswordService.run(email, codeotc, password, confirmarPassword)
                const requestUCChangePasswordServiceResult = await requestUCChangePasswordService.json()
                if (requestUCChangePasswordService.ok) {
                    alerto('Info', 'A senha foi atualizada com sucesso!')
                    clearForm()
                } else {
                    alerto('Erro', requestUCChangePasswordServiceResult.error)
                }
            }
        }
        showLinearProgress(false)
    }


    useEffect(() => {
        clearForm()
    }, [])

    return (
        <>
            <Container>
                {linearProgressRunning && (<BarToProgress >
                    {linearProgressRunning && (<LinearProgress />)}
                    <div>
                        <CircularProgress size={80} />
                    </div>
                </BarToProgress>)}

                <Form >
                    <FormTitle>Guarda Livros</FormTitle>


                    <Paper elevation={2} style={{ padding: '20px', margin: '10px auto' }}>
                        <h3>Passo 1 - Solicitar Código!</h3>
                        <Input
                            name='email'
                            type="text"
                            placeholder="Digite seu Email de acesso ao sistema  "
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button variant="text" color="inherit"
                            type="button" onClick={async () => {
                                await forgetPassword()
                            }}> Solicitar Código </Button>
                    </Paper>


                    <Paper elevation={2} style={{ padding: '20px', margin: '10px auto' }}>
                        <h3>Passo 2 - Trocar a Senha!</h3>
                        <Input
                            name='otc'
                            type="text"
                            placeholder="Código OTC"
                            required
                            value={codeotc}
                            onChange={(e) => setCodeOtc(e.target.value)}
                        />
                        <Input
                            name='pass'
                            type="password"
                            placeholder="Nova Senha"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        <Input
                            name='confirmpass'
                            type="password"
                            placeholder="Confirmar Nova Senha"
                            required
                            value={confirmarPassword}
                            onChange={(e) => setConfirmarPassword(e.target.value)}
                            onBlur={(e) => {
                                handleCompararPassword(e.target.value)
                            }}
                        />
                        <Button variant="text" color="inherit"
                            type="button" onClick={async () => {
                                await handleTrocarPassword()
                            }}> Confirmar </Button>
                        <Button variant="text" color="inherit"
                            type="button" onClick={async () => {
                                //await forgetPassword()
                                navigate(ROTA_LOGIN)
                            }}> Cancelar </Button>
                    </Paper>
                    {/* </Paper> */}
                    {/* <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={termsAccepted}
                                onChange={(e) => setTermsAccepted(e.target.checked)}
                            />
                        }
                        label="Aceito os termos de uso"
                    />

                </div> */}


                </Form>
                <Rodape>

                    <footer>
                        <Button variant="text" color="inherit"
                            startIcon={<HomeIcon />}
                            type="button" onClick={async () => {
                                navigate('/')
                            }}> Home
                        </Button>
                        <Button variant="text" color="inherit"
                            startIcon={<SupportAgentIcon />}
                            type="button" onClick={async () => {
                                window.location.href = 'mailto:suporte@oguardalivros.com';
                            }}> Contato
                        </Button>
                        <Button variant="text" color="inherit"
                            startIcon={<WhatsAppIcon />}
                            type="button" onClick={async () => {
                                window.open('https://wa.me/5513997784036', '_blank')
                            }}> WhatsApp/Suporte
                        </Button>

                    </footer>
                </Rodape>
            </Container>
        </>
    );
}

export default ForgetPass;
import React from "react";
import { Container } from "./style";
import MenuResponsivo from "../Menu";


const MainHeader: React.FC = () => { 

    

    return (
        <Container>            
            <MenuResponsivo />
        </Container>
    )
}

export default MainHeader;
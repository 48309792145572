import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    margin: 7px 0;
    padding: 10px;

    border-radius: 5px;    
`;

export const Title = styled.span`
    font-weight:bolder;
    border-bottom:1px solid ${props=>props.theme.color.black};
`;


import styled from 'styled-components';

export const Container = styled.div`
    height: 100vh;    
    
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: ${props => props.theme.color.info};
`;

export const Logo = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: 20px;

    > h2 {
        color: ${props => props.theme.color.black}; 
        margin-left: 7px;        
    }

    > img {
        width: 100px;
        height: 100px;        
        margin: 0 auto;
    }
`;

export const Form = styled.form`
    width: 380px;
    height: 525px;
    padding: 10px;
    border-radius: 10px;
    background-color: ${props => props.theme.color.tertiary};
    h3,h2{
        text-align:center;        
    }
    
    button {
        margin:5px auto;    
    }    
    
`;

export const FormTitle = styled.h1`
    margin-bottom: 25px;

    color: ${props => props.theme.color.info}; 
    text-align:center;
    &:after {
        content: '';
        display: block;
        width: 100%;
        border-bottom: 10px solid ${props => props.theme.color.info};  
    }
`;

export const CheckBoxLabel = styled.label`
    /* Estilos do rótulo do checkbox */
`;

export const Rodape = styled.div`
  background-color: ${props => props.theme.color.info};
  
  text-align: center;
  font-size: 45px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
  color: white;

  button {
    padding: 0 10px;
    
    text-decoration: none;
    margin: 0 3px;
  }
`;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importe o useHistory do React Router
import { useAuth } from '../../hooks/auth';
import { useActionContext } from '../../hooks/actions';
import { Breadcrumbs, Button, FormControl, Grid, InputLabel, Link, MenuItem, Paper, Select, TextField, Tooltip, Typography } from '@mui/material';
import { Container, Containerd, GridoContainer } from './styles';
import { useLocation } from 'react-router-dom';
import RequisicaoDocumentoForm from './components/formulario';
import { ROTA_CLIENTES } from '../../constants/uri/constants.uri';
import Grido from '../../components/Grido';
import { GridColDef } from '@mui/x-data-grid';
import UCRetornarRequisicaoDocumentoByClientIdService from './useCases/uc-consultarPorClientID-requisicao-documento';
import { getUserData } from '../../services/storage.service';
import moment from 'moment';
import BottomNavigationCtrl from '../../components/CadastroUsuario/components/bottom-navigation';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UCRemoverRequisicaoDocumentoService from './useCases/uc-remover-requisicao-documento';
import QuestionDialog from '../../components/QuestionDialog';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export interface RequisicaoDocumento {
    id?: number
    clientId: number
    entityId: number
    requisicaoLabel: string
    requisicaoMotivoDescricao: string
    direction?: string
    fill?: boolean
}

export interface RequireDocumentWithEntity {
    id: number;
    documentRequireName: string;
    documentRequireDescription: string;
    clientId: number;
    clientName?: string;
    created_at?: Date,
    updated_at?: Date
}


const RequisicaoDocumento: React.FC = () => {

    const removerRequisicao = async () => {
        try {
            showLinearProgress(true)
            const response = await UCRemoverRequisicaoDocumentoService.run(Number(formData.id), signOutByExpiresProcess)
            if (response.ok) {
                alerto('Info', 'Requisição removida com sucesso!')
            } else {
                alerto('Erro', 'Ocorreu um erro ao remover o registro, contate o administrador')
            }

            showLinearProgress(false)
            loadData()
        } catch (error: any) {
            alerto('Erro', error.message)
            showLinearProgress(false)
        }
    }

    const alerto = (title: string, content: string) => {
        handleOpen(true)
        setTitle(title)
        setDialogContextText(content)
    }

    const [formData, setFormData] = useState<RequisicaoDocumento>({
        clientId: 0,
        entityId: 0,
        requisicaoLabel: '',
        requisicaoMotivoDescricao: '',
        direction: 'out'
    });

    const questionRemoverRequisicao = async (id: any) => {
        setOpenDialog(true)
        setOpenDialogText('Remoção de Usuário')
        setOpenDialogQuestionContext(`Deseja remover o registro ${id}`)
        setFormData({ ...formData, id: id })
    }

    const navigate = useNavigate()
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const UC = getUserData()
    const [value, setValue] = React.useState<number>(0);

    const {
        show_cadCli,
        setShowCadCli,
        handleCloseQuestionDialog,
        setPathName } = useActionContext()

    const {
        setDialogContextText,
        setTitle,
        handleOpen,
        signOut
    } = useAuth()

    const {
        setOpenDialog,
        setOpenDialogText,
        setOpenDialogQuestionContext,
        showLinearProgress
    } = useActionContext();

    const signOutByExpiresProcess = () => {
        signOut();
        navigate('/')
    }

    //grido
    const [requisicaoDocumentoWithEntity, setRequisicaoDocumentoWithEntity] = useState<RequireDocumentWithEntity[]>([])
    const [requisicaoFromClientDocumentoWithEntity, setRequisicaoFromClientDocumentoWithEntity] = useState<RequireDocumentWithEntity[]>([])

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 10 },
        { field: 'documentRequireName', headerName: 'Título Doc.', width: 140 },
        { field: 'documentRequireDescription', headerName: 'Desc. Doc', width: 380 },
        { field: 'fill', headerName: 'Pendente', width: 180 },
        {
            field: 'created_at', headerName: 'Criado', width: 180,
            renderCell: (params) => (
                <div>
                    {moment(params.row.created_at).format('DD/MM/YYYY hh:mm:ss')}
                </div>)
        },
        {
            field: 'updated_at', headerName: 'Atualizado', width: 180,
            renderCell: (params) => (
                <div>
                    {params.row.updated_at ? moment(params.row.updated_at).format('DD/MM/YYYY hh:mm:ss') : '-'}
                </div>)
        },
        {
            field: 'actions',
            headerName: 'Ações',
            width: 250,
            headerAlign: 'left',
            renderCell: (params) => (
                <div>
                    <Button startIcon={
                        <Tooltip title='Editar'>
                            <EditIcon />
                        </Tooltip>
                    } onClick={() => {
                        const req: string = localStorage.getItem('requisicaoDocumento') as string
                        let requisicaoDocumento = JSON.parse(req)

                        requisicaoDocumento.requisicaoMotivoDescricao = params.row.documentRequireDescription
                        requisicaoDocumento.requisicaoLabel = params.row.documentRequireName
                        requisicaoDocumento.id = params.row.id
                        localStorage.setItem('requisicaoDocumento', JSON.stringify(requisicaoDocumento))

                        setFormData(
                            {
                                requisicaoLabel: params.row.documentRequireName,
                                requisicaoMotivoDescricao: params.row.documentRequireDescription,
                                clientId: 0,
                                entityId: 0,
                                id: Number(params.row.id)
                            })

                        setShowCadCli(true)
                        setValue(0)
                    }}>
                    </Button>

                    <Button startIcon={
                        <Tooltip title='Remover'>
                            <DeleteIcon />
                        </Tooltip>
                    }
                        onClick={async () => {
                            await questionRemoverRequisicao(params.row.id)
                        }}>
                    </Button>
                </div>
            )
        }
    ]


    const columnsFromCli: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 10 },
        { field: 'documentRequireName', headerName: 'Título Doc.', width: 140 },
        { field: 'documentRequireDescription', headerName: 'Desc. Doc', width: 380 },
        { field: 'fill', headerName: 'Pendente', width: 180 },
        {
            field: 'created_at', headerName: 'Criado', width: 180,
            renderCell: (params) => (
                <div>
                    {moment(params.row.created_at).format('DD/MM/YYYY hh:mm:ss')}
                </div>)
        },
        {
            field: 'updated_at', headerName: 'Atualizado', width: 180,
            renderCell: (params) => (
                <div>
                    {params.row.updated_at ? moment(params.row.updated_at).format('DD/MM/YYYY hh:mm:ss') : '-'}
                </div>)
        },
        // {
        //     field: 'actions',
        //     headerName: 'Ações',
        //     width: 250,
        //     headerAlign: 'left',
        //     renderCell: (params) => (
        //         <div>
        //             <Button startIcon={
        //                 <Tooltip title='Editar'>
        //                     <EditIcon />
        //                 </Tooltip>
        //             } onClick={() => {
        //                 const req: string = localStorage.getItem('requisicaoDocumento') as string
        //                 let requisicaoDocumento = JSON.parse(req)

        //                 requisicaoDocumento.requisicaoMotivoDescricao = params.row.documentRequireDescription
        //                 requisicaoDocumento.requisicaoLabel = params.row.documentRequireName
        //                 requisicaoDocumento.id = params.row.id
        //                 localStorage.setItem('requisicaoDocumento', JSON.stringify(requisicaoDocumento))

        //                 setFormData(
        //                     {
        //                         requisicaoLabel: params.row.documentRequireName,
        //                         requisicaoMotivoDescricao: params.row.documentRequireDescription,
        //                         clientId: 0,
        //                         entityId: 0,
        //                         id: Number(params.row.id)
        //                     })

        //                 setShowCadCli(true)
        //                 setValue(0)
        //             }}>
        //             </Button>

        //             <Button startIcon={
        //                 <Tooltip title='Remover'>
        //                     <DeleteIcon />
        //                 </Tooltip>
        //             }
        //                 onClick={async () => {
        //                     await questionRemoverRequisicao(params.row.id)
        //                 }}>
        //             </Button>
        //         </div>
        //     )
        // }
    ]

    const loadData = async () => {
        try {
            showLinearProgress(true)
            const req: string = localStorage.getItem('requisicaoDocumento') as string
            const requisicaoDocumento = JSON.parse(req)
            const docsRequired = await UCRetornarRequisicaoDocumentoByClientIdService.run(requisicaoDocumento.clientId, signOutByExpiresProcess, 'out')
            if (docsRequired.ok) {
                const result = await docsRequired.json()
                const gridData = result.map((el: any) => {
                    return {
                        ...el,
                        clientName: el.client.name,
                        fill: el.fill ? 'Não' : 'Sim'
                    }
                })

                setRequisicaoDocumentoWithEntity(gridData)
            }
            showLinearProgress(false)
        } catch (error: any) {
            alerto('Erro', error.message)
            showLinearProgress(false)
        }

    }


    const loadDataRequiredFromClient = async () => {
        try {
            const req: string = localStorage.getItem('requisicaoDocumento') as string
            const requisicaoDocumento = JSON.parse(req)

            const docsRequired = await UCRetornarRequisicaoDocumentoByClientIdService.run(requisicaoDocumento.clientId, signOutByExpiresProcess, 'in')

            if (docsRequired.ok) {
                const result = await docsRequired.json()


                const gridData = result.map((el: any) => {
                    return {
                        ...el,
                        clientName: el.client.name,
                        fill: el.fill ? 'Não' : 'Sim'
                    }
                })

                setRequisicaoFromClientDocumentoWithEntity(gridData)
            }
            showLinearProgress(false)

        } catch (error: any) {
            alerto('Erro', error.message)
            showLinearProgress(false)
        }
    }

    const clearSetFormData = () => {
        setFormData({ clientId: 0, entityId: 0, requisicaoLabel: '', requisicaoMotivoDescricao: '', direction: '', id: 0 })
    }

    useEffect(() => {
        loadData()
        loadDataRequiredFromClient()
    }, [])


    return (
        <Containerd>

            <QuestionDialog
                handleClose={handleCloseQuestionDialog}
                handleConfirm={async () => {

                    try {
                        removerRequisicao()
                    } catch (error) {

                    }

                    handleCloseQuestionDialog()
                }}
            ></QuestionDialog>

            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover"
                    sx={{ cursor: 'pointer' }}
                    color="inherit"
                    onClick={(e) => {
                        e.preventDefault()
                        navigate(ROTA_CLIENTES)
                        setPathName(ROTA_CLIENTES)
                    }}
                >
                    Clientes
                </Link>
                <Typography color="text.primary">Requisição de Documentos</Typography>
            </Breadcrumbs>

            {show_cadCli && (<RequisicaoDocumentoForm
                onConfirm={() => {
                    loadData()
                    clearSetFormData()
                }}
                documentRequireDescription={formData.requisicaoMotivoDescricao}
                documentRequireName={formData.requisicaoLabel}
                id={Number(formData.id)}
                direction='out'
            />)}
            {!show_cadCli && (
                <Paper elevation={24} style={{ padding: '20px', margin: '30px auto' }}>
                    <h2>
                        Requisições Criadas para {params.get('entityName')}
                    </h2>
                    <GridoContainer>
                        <Grido
                            _columns={columns}
                            _rows={requisicaoDocumentoWithEntity}
                            onClick={(e) => { console.log(e) }}
                            pageSize={10}
                        ></Grido>
                    </GridoContainer>
                </Paper>
            )}

            {!show_cadCli && (
                <Paper elevation={24} style={{ padding: '20px', margin: '30px auto' }}>
                    <h2>
                        Requisições Criadas por {params.get('entityName')}
                    </h2>
                    <GridoContainer>
                        <Grido
                            _columns={columnsFromCli}
                            _rows={requisicaoFromClientDocumentoWithEntity}
                            onClick={(e) => { console.log(e) }}
                            pageSize={10}
                        ></Grido>
                    </GridoContainer>
                </Paper>
            )}

            <BottomNavigationCtrl
                labelCadastro='Criar Req.'
                labelGrid='Listar Req.'
                setShowCadastro={() => { setShowCadCli(true) }}
                setHideCadastro={() => { setShowCadCli(false) }}
                value={value}
                onSetValue={setValue}
            />

        </Containerd>
    )
}

export default RequisicaoDocumento;
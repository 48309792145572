import React, { ReactNode } from "react";

import { DataGrid, GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridRowEditStopParams, GridRowEditStopReasons, GridRowModel, GridValueGetterParams, MuiEvent } from '@mui/x-data-grid';
import { Container } from "./styles";

type IGrido = {
    _rows: any[],
    _columns: any[],
    onClick: (e: any) => void,
    pageSize: number
}

const Grido: React.FC<IGrido> = ({
    _rows,
    _columns,
    onClick,
    pageSize
}: IGrido) => {

    return (

        <Container>
            <div style={{ clear: 'both', height: 450, width: '100%', padding: '15px 0 0 0' }}>
                <DataGrid
                    rows={_rows}
                    columns={_columns}
                    paginationMode="client"
                    initialState={{
                        pagination: { paginationModel: { pageSize: pageSize } },
                    }}
                    pageSizeOptions={[pageSize]}
                    onRowDoubleClick={(params: any) => {
                        
                    }}
                    onRowEditStop={(params: GridRowEditStopParams, event: any) => {                        
                        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
                            event.defaultMuiPrevented = true;
                        }
                    }}
                />
            </div>
        </Container >


    )
}

export default Grido;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importe o useHistory do React Router
import { useAuth } from '../../hooks/auth';
import ICadastroUsuarioFormData, { useActionContext } from '../../hooks/actions';
import { Container, Button, Paper, Grid } from '@mui/material';
import UCConsultarUsuariosService from './useCases/uc-consultar-usuarios.service';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Refresh } from '@mui/icons-material';
import Grido from '../../components/Grido';
import { GridColDef } from '@mui/x-data-grid';
import { GridoContainer } from './styles';
import CadastrarUsuario from '../../components/CadastroUsuario';
import UCRemoverUsuarioService from './useCases/uc-remover-usuario.service';
import { REGISTRO_NAO_ENCONTRADO, REGISTRO_REMOVIDO, SUCESSO, WARNING } from './useCases/constants/admin-message.constants';
import QuestionDialog from '../../components/QuestionDialog';
import BottomNavigationCtrl from '../../components/CadastroUsuario/components/bottom-navigation';
import GroupIcon from '@mui/icons-material/Group';
import UCConsultarEntidadesByFatherIdService from './useCases/uc-findentities-byfathers';

const Master: React.FC = () => {

    const { signOut, handleOpen, setTitle, setDialogContextText } = useAuth();
    const [value, setValue] = React.useState<number>(0);
    const [rows, setRows] = useState<any[]>([]);
    const [rowsChild, setRowsChild] = useState<any[]>([]);
    const [dataForm, setDataForm] = useState<any | null>(null)

    const navigate = useNavigate()

    const {
        show_FormCadUser,
        setShowFormCadUser,
        setCadastroUsuarioFormData,
        showLinearProgress,
        setOpenDialog,
        setOpenDialogText,
        setOpenDialogQuestionContext } = useActionContext()

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 1 },
        { field: 'name', headerName: 'Nome', width: 140 },
        { field: 'email', headerName: 'Email', width: 180 },
        { field: 'emailSecundary', headerName: 'Email Sec.', width: 180 },
        {
            field: 'plano', headerName: 'Plano', width: 100,
            renderCell: (params) => (
                //params.row.plano == "1" ? "Básico" : (params.row.plano == "2" ? "Intermediário" : "Avançado")
                renderPlanoName(params.row.plano)
            )
        },
        {
            field: 'active', headerName: 'Ativo', width: 100,
            renderCell: (params) => (
                params.row.active === true ? 'Sim' : 'Não'
            )
        },
        {
            field: 'roles', headerName: 'Perfil', width: 100,
            renderCell: (params) => (
                params.row.roles[0].name
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            align: 'right',
            width: 190,
            renderCell: (params) => (
                <div>
                    <Button startIcon={<EditIcon />} onClick={() => {
                        handleEditar(params.row)
                        setValue(0)
                    }}>
                    </Button>
                    <Button startIcon={<DeleteIcon />}
                        onClick={() => {
                            setOpenDialogText('Remoção de Usuário')
                            setOpenDialogQuestionContext(`Deseja remover o registro ${params.row.email}?`)
                            setDataForm(params.row)
                            setOpenDialog(true)
                        }}>
                    </Button>

                    <Button
                        disabled={params.row.roles === "Master"}
                        startIcon={<GroupIcon />}
                        onClick={async () => {
                            setDataForm(params.row)
                            const userId: number = params.row.id
                            await findChilds(userId)
                        }}>
                    </Button>

                </div>
            ),
        }
    ]

    const columnsChild: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 1 },
        { field: 'name', headerName: 'Nome', width: 140 },
        { field: 'email', headerName: 'Email', width: 180 },
        // {
        //     field: 'plano', headerName: 'Plano', width: 100,
        //     renderCell: (params) => (
        //         renderPlanoName(params.row.plano)
        //     )
        // },
        {
            field: 'active', headerName: 'Ativo', width: 100,
            renderCell: (params) => (
                params.row.active === true ? 'Sim' : 'Não'
            )
        },
        {
            field: 'roles', headerName: 'Perfil', width: 100,
            renderCell: (params) => (
                params.row.roles[0].name
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            align: 'left',
            width: 100,
            renderCell: (params) => (
                <div>
                    <Button startIcon={<EditIcon />} onClick={() => {
                        handleEditar(params.row)
                        setValue(0)
                    }}>
                    </Button>
                    {/* <Button startIcon={<DeleteIcon />}
                        onClick={() => {
                            setOpenDialogText('Remoção de Usuário')
                            setOpenDialogQuestionContext(`Deseja remover o registro ${params.row.email}?`)
                            setDataForm(params.row)
                            setOpenDialog(true)
                        }}>
                    </Button> */}
                </div>
            ),
        }
    ]

    const renderPlanoName = (planoId: string) => {
        let planoName = ''

        if (planoId === '1') {
            planoName = 'Básico'
        }

        if (planoId === '2') {
            planoName = 'Intermediário'
        }

        if (planoId === '3') {
            planoName = 'Avançado'
        }
        return planoName
    }


    const findChilds = async (userId: number) => {
        try {
            showLinearProgress(true)
            const response = await UCConsultarEntidadesByFatherIdService.run(userId, signOut)
            if (response.ok) {
                const retorno = await response.json()

                const users = retorno.map((u: any) => {
                    return {
                        id: u.id,
                        name: u.name,
                        email: u.email,
                        active: u.active === 1,
                        plano: u.plano,
                        roles: 'Funcionario'
                    }
                })
                setRowsChild(users)
            } else {
                const error = await response.json()
                setTitle(WARNING)
                setDialogContextText(error.message || error.error)
            }

            showLinearProgress(false)
        } catch (error: any) {
            setTitle(WARNING)
            setDialogContextText(error)
            showLinearProgress(false)
        }
    }

    const deleteUser = async (data: ICadastroUsuarioFormData) => {
        try {
            showLinearProgress(true)
            if (data.id) {
                handleOpen(true)
                const ret = await UCRemoverUsuarioService.run(data.id)
                if (ret === 1) {
                    setTitle(SUCESSO)
                    setDialogContextText(REGISTRO_REMOVIDO)
                } else {
                    setTitle(WARNING)
                    setDialogContextText(REGISTRO_NAO_ENCONTRADO)
                }
            }
            await loadUsers()
            showLinearProgress(false)
        } catch (error) {
            showLinearProgress(false)
        }
    }

    const loadUsers = async () => {
        try {
            showLinearProgress(true)
            const usuarios: any = await UCConsultarUsuariosService.run()

            if (usuarios.error) {
                showLinearProgress(false)
                throw new Error(usuarios.error.message)
            }
            const users = usuarios.map((u: any) => {
                return {
                    id: u.id,
                    name: u.name,
                    email: u.email,
                    active: u.active,
                    plano: u.plano,
                    roles: u.roles[0].name,
                    emailSecundary: u.emailSecundary || ''
                }
            })
            setRows(users)
            showLinearProgress(false)
        } catch (error: any) {
            showLinearProgress(false)
            if (error.message === 'Unauthorized') {
                signOut()
                navigate('/')
            }

        }
    }

    const handleEditar = (data: ICadastroUsuarioFormData) => {

        let role = "1"

        if (data.roles === 'Admin') {
            role = "2"
        }

        if (data.roles === 'Funcionario') {
            role = "3"
        }

        setShowFormCadUser(true)
        
        setCadastroUsuarioFormData({
            id: data.id,
            name: data.name,
            email: data.email,
            plano: data.plano,
            password: '',
            roles: role, // Adicione um estado para a opção selecionada no Select
            active: data.active,
            emailSecundary: data.emailSecundary
        })
    }

    useEffect(() => {
        loadUsers()
        handleCloseQuestionDialog()
        setShowFormCadUser(true)
    }, [])

    const { handleCloseQuestionDialog } = useActionContext();

    return (
        <Container>
            <QuestionDialog
                handleClose={handleCloseQuestionDialog}
                handleConfirm={async () => {
                    if (dataForm) {
                        await deleteUser(dataForm)
                    }
                    handleCloseQuestionDialog()
                }}
            ></QuestionDialog>

            {show_FormCadUser && (<CadastrarUsuario
                onHandleSubmit={loadUsers}
                visibleActive={true}
                visiblePassword={false}
                visiblePlanChoice={true}
                visibleRole={true}
            />)
            }


            {!show_FormCadUser && (<Paper elevation={24} style={{ padding: '20px', margin: '30px auto', }}>
                <Grid container spacing={2} alignItems="start" >
                    <Grid item xs={10}>
                        <h2>Lista de Usuários</h2>
                    </Grid>
                    <Grid item xs={2}>
                        <Button type="submit" variant="text" color="info"
                            onClick={async () => {
                                await loadUsers()
                            }}
                        >
                            <Refresh />
                        </Button>
                    </Grid>
                </Grid>


                <GridoContainer>
                    <Grido
                        pageSize={10}
                        _columns={columns}
                        _rows={rows}
                        onClick={() => { }}
                    />
                </GridoContainer>
                <br />
                <GridoContainer>
                    <h3>
                        Clientes da Contabilidade: {dataForm?.name}
                    </h3>
                    <Grido
                        pageSize={10}
                        _columns={columnsChild}
                        _rows={rowsChild}
                        onClick={() => { }}
                    />
                </GridoContainer>

            </Paper>)}
            <BottomNavigationCtrl
                labelCadastro='Cadastro Usuário'
                labelGrid='Lista de Usuarios'
                setShowCadastro={() => { setShowFormCadUser(true) }}
                setHideCadastro={() => { setShowFormCadUser(false) }}
                value={value}
                onSetValue={setValue}
            />
        </Container>
    );
};

export default Master;
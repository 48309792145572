import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, RadioGroup, Select, TextField } from '@mui/material';
import { RequisicaoDocumento } from '../..';
import UCGravarRequisicaoDocumentoService from '../../useCases/uc-gravar-requisicao-documento';
import { getUserData } from '../../../../services/storage.service';
import { useAuth } from '../../../../hooks/auth';
import { useActionContext } from '../../../../hooks/actions';
import UCAtualizarRequisicaoDocumentoService from '../../useCases/uc-atualizar-requisicao-documento';
import { StyledTitle } from '../../styles';
import Radio from '@mui/material/Radio';


export interface DocumentRequireForm {
    id: number
    documentRequireDescription: string
    documentRequireName: string,
    direction: string,
    onConfirm: () => void
}

const RequisicaoDocumentoForm: React.FC<DocumentRequireForm> = ({
    documentRequireName,
    documentRequireDescription,
    id,
    direction,
    onConfirm }) => {

    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const UC = getUserData()

    const {
        setDialogContextText,
        setTitle,
        handleOpen,
        signOut
    } = useAuth()

    const {
        showLinearProgress
    } = useActionContext();

    const [formData, setFormData] = useState<RequisicaoDocumento>({
        clientId: 0,
        entityId: 0,
        requisicaoLabel: '',
        requisicaoMotivoDescricao: '',
        direction: '',
        fill: false
    });

    const clearForm = () => {
        setFormData({ ...formData, requisicaoLabel: '', requisicaoMotivoDescricao: '', id: 0 })
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        showLinearProgress(true)
        if (Number(formData.id) === 0) {
            await gravarRequisicao()
        } else {
            await atualizarRequisicao()
        }

        showLinearProgress(false)
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const alerto = (title: string, content: string) => {
        handleOpen(true)
        setTitle(title)
        setDialogContextText(content)
    }

    const atualizarRequisicao = async () => {
        try {
            const response = await UCAtualizarRequisicaoDocumentoService.run(formData, signOut)
            if (response.ok) {
                alerto('Info', 'Requisição atualizada com sucesso!')
            } else {
                alerto('Erro', 'Ocorreu um erro ao inserir o registro, contate o administrador')
            }
            onConfirm()
            clearForm()
            showLinearProgress(false)
        } catch (error: any) {
            alerto('Erro', error.message)
            showLinearProgress(false)
            clearForm()
            onConfirm()
        }
    }

    const gravarRequisicao = async () => {
        try {
            formData.direction = direction            
            const response = await UCGravarRequisicaoDocumentoService.run(formData, signOut)
            if (response.ok) {
                alerto('Info', 'Requisição criada com sucesso, uma notificação será enviada!')
            } else {
                alerto('Erro', 'Ocorreu um erro ao inserir o registro, contate o administrador')
            }
            onConfirm()
            clearForm()
            showLinearProgress(false)
        } catch (error: any) {
            alerto('Erro', error.message)
            showLinearProgress(false)
            clearForm()
            onConfirm()
        }
    }

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;        
        setFormData({ ...formData, fill: value === 'true' });
    };

    useEffect(() => {
        const req: string = localStorage.getItem('requisicaoDocumento') as string
        const requisicaoDocumento = JSON.parse(req)
        if (requisicaoDocumento) {
            let entityId = requisicaoDocumento.entityId// params.get('entityId')
            let clientId = requisicaoDocumento.clientId//  params.get('clientId')        

            setFormData({
                clientId: Number(clientId),
                entityId: Number(entityId),
                requisicaoLabel: documentRequireName || '',
                requisicaoMotivoDescricao: documentRequireDescription || '',
                id: id || 0
            })
        }
    }, [])






    return (
        <>
            <Paper elevation={24} style={{ padding: '5px', margin: '5px auto', maxWidth: '100%' }}>

                <StyledTitle condition={String(Number(formData.id) <= 0 && direction == 'out')}>
                    Cadastro de Requisicao de Documento para: {params.get('entityName')}
                </StyledTitle>
                <StyledTitle condition={String(Number(formData.id) <= 0 && direction == 'in')}>
                    Cadastro de Requisicao de Documento
                </StyledTitle>

                <StyledTitle condition={String(Number(formData.id) > 0 && direction == 'out')}>
                    Atualizando Requisição de Documento para: {params.get('entityName')} - id: {formData.id}
                </StyledTitle>

                <StyledTitle condition={String(Number(formData.id) > 0 && direction == 'in')}>
                    Atualizando Requisição de Documento
                </StyledTitle>


                {/* {Number(formData.id) > 0 && (<h3>
                    Atualizando Requisição de Documento para: {params.get('entityName')} - id: {formData.id}
                </h3>)} */}

                <Paper elevation={24} style={{ padding: '20px', margin: '30px auto', maxWidth: '100%' }}  >
                    <form
                        onSubmit={handleSubmit}>
                        <Grid container alignItems="flex-start" spacing={2} >
                            <Grid item xs={12}>
                                {/* <InputLabel id="txtTituloRequisicao">Título da Requisição</InputLabel> */}
                                <TextField
                                    id='requisicaoLabel'
                                    fullWidth
                                    label="Título da Requisição"
                                    type="text"
                                    name="requisicaoLabel"
                                    value={formData.requisicaoLabel}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }} // Define o limite máximo de caracteres para 50
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='requisicaoMotivoDescricao'
                                    fullWidth
                                    label="Descrição da Requisição"
                                    type="text"
                                    name="requisicaoMotivoDescricao"
                                    multiline
                                    rows={4}
                                    value={formData.requisicaoMotivoDescricao}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 499 }} // Define o limite máximo de caracteres para 50
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Resolvido</FormLabel>
                                    <RadioGroup
                                        name="fill"
                                        value={formData.fill ? true : false}
                                        onChange={handleRadioChange}
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Sim" />
                                        <FormControlLabel value="false" control={<Radio />} label="Não" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="text" color="primary">
                                    Gravar
                                </Button>
                                <Button variant="text" color="primary" onClick={clearForm} >
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Paper >
        </>
    )
}

export default RequisicaoDocumentoForm;
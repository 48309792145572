import Box from '@mui/material/Box';
import styled from 'styled-components';

export const Containerd = styled.div`
    padding:22px 0 0 0;
    h2{
        text-align:center;
        padding:0 0 5px 0;
    }    
    
    h4, h3{        
        padding:0 0 5px 0;        
    }

    width:80%;
    margin:0 auto;

    .MuiPaper-root{
        div.blue{
            margin:10px 0 0 0;
            width:290px;
        }        
    }
    .MuiAvatar-root{
        background-color:${props => props.theme.color.info}
    }

    
        .MuiButton-root {
            margin:0 5px 0 0;
            width:180px;
        }

        @media only screen and (max-width: 767px) {        
            display:inline;  
            .MuiButton-root {
                margin:0 0px 5px 0;
                width:100%;
            }      
        }   
    
`

export const GridoContainer = styled.div`

    .visualization-date{
        background-color: ${props => props.theme.color.warning} !important
    }


    .MuiDataGrid-columnHeaderTitle{
        color: ${props => props.theme.color.black}
    }

    .MuiDataGrid-cellContent, .MuiDataGrid-cell--withRenderer{
        color: ${props => props.theme.color.info}
    }    
     
`

export const Desktop = styled.div`
    display:none;    
    @media only screen and (min-width: 768px) {
        display:inline;        
    }  

`

export const Mobile = styled.div`
    display:none;
    @media only screen and (max-width: 767px) {        
        display:inline;        
    }    
`

export const Squaro = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  background-color:${props => props.theme.color.warning};
`;

// Estilo para o texto ao lado
export const LegendText = styled.span`
  font-size: 14px;  
`;

// Estilo para o container
export const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  padding:2px;
`;

export const ContainerBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin:20px 0 20px 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
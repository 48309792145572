import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth } from '../../hooks/auth';
import { useActionContext } from '../../hooks/actions';


interface IAlertDialogProps {
    handleClose: (e: any) => void;
    handleConfirm: (e: any) => void;
}

const QuestionDialog: React.FC<IAlertDialogProps> = ({ handleClose, handleConfirm }: IAlertDialogProps) => {

    const { open_Dialog, open_DialogTitle, dialogQuestionContextText } = useActionContext();

    return (
        <div>
            <Dialog
                open={open_Dialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {open_DialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogQuestionContextText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleConfirm}>Confirma</Button>
                    <Button onClick={handleClose}>Cancela</Button>                    
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default QuestionDialog;
import React, { useEffect, useState } from "react";
import { Container } from "./style";
// import { useAuth } from "../../hooks/auth";
import TreeViewMaster from "../TreeViewMaster";
import { getUserData, Role, UserData } from "../../services/storage.service";
import { checkRolesOnUserLogged } from "../../services/checkRoles/check-roles.service";
import TreeViewContabilidade from "../TreeViewContabilidade";
import TreeViewGenerica from "../TreeViewClientes";
import { useLocation } from 'react-router-dom';
import { useActionContext } from "../../hooks/actions";
import { ROTA_REQUISICAO_DOCUMENTO } from "../../constants/uri/constants.uri";

interface ITreeviewGenericaProps {
    headerText: string,
    cadastroText: string,
    listarText: string,
    onSetShow: () => void,
    onSetHide: () => void,
}

const Aside: React.FC = () => {

    const {
        setShowFormCadContabilidade,
        setShowCadCli,
        path_Name,
        setPathName
    } = useActionContext()

    const isMaster: boolean = checkRolesOnUserLogged(['master'])
    const isAdmin: boolean = checkRolesOnUserLogged(['admin'])
    const location = useLocation();

    const [treeViewData, setTreeViewData] = useState<ITreeviewGenericaProps>({
        headerText: '',
        cadastroText: '',
        listarText: '',
        onSetShow: () => { },
        onSetHide: () => { }
    });

    

    useEffect(() => {
        
         if (location.pathname === ROTA_REQUISICAO_DOCUMENTO) {
            setTreeViewData({
                 headerText: 'Requisição Documento',
                 cadastroText: 'Criar Requisição',
                 listarText: 'Listar Requisições',
                 onSetHide: () => { setShowCadCli(false) },
                 onSetShow: () => { setShowCadCli(true) }
             })
         }
        
        if (location.pathname === '/clientes') {
            setTreeViewData({
                headerText: 'Clientes',
                cadastroText: 'Cadastro de Clientes',
                listarText: 'Listar Clientes',
                onSetHide: () => { setShowCadCli(false) },
                onSetShow: () => { setShowCadCli(true) }
            })
        }

        if (location.pathname === '/' || location.pathname === 'doc-ent') {
            setTreeViewData({
                headerText: 'Contabilidade',
                cadastroText: 'Cadastre sua Contabilidade',
                listarText: 'Contabilidade',
                onSetHide: () => { setShowFormCadContabilidade(false) },
                onSetShow: () => { setShowFormCadContabilidade(true) }
            })
        }

    }, [path_Name])

    return (
        <Container>            
            {isMaster ? <TreeViewMaster /> : null}
            {/* {isAdmin ? <TreeViewContabilidade /> : null} */}
            {isAdmin ? <TreeViewGenerica
                cadastroText={treeViewData.cadastroText}
                headerText={treeViewData.headerText}
                listarText={treeViewData.listarText}
                onSetHide={treeViewData.onSetHide}
                onSetShow={treeViewData.onSetShow}
            /> : null}

        </Container>
    )
}

export default Aside;
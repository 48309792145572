import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcherNotLogged } from "../../../services/fetcher.service"

const run = async (email: string, otc: string, password: string, confirmPassword: string) => {

    const body = {
        email,
        otc,
        password,
        confirmPassword
    }
    const url = `${URI_BASE}/forget-pass/change`

    return await fetcherNotLogged(url, 'POST', body)
}

const UCChangePasswordService = {
    run
}

export default UCChangePasswordService
import styled from "styled-components";
import { createTheme } from '@mui/material/styles';

export const Container = styled.div`            
    .MuiDataGrid-main {
        background-color:${props => props.theme.dataGrid.rowBackgroundColor};
        color:${props => props.theme.color.primary}    
    }

    .MuiDataGrid-footerContainer{
        background-color:${props => props.theme.color.gray};
        color:${props => props.theme.color.primary}    
    }

`;
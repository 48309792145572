import { createContext, useContext, useState } from "react";
import { DEFAULT_PASSWORD } from "./constants/constants";

interface IActionContext {
    linearProgressRunning: boolean
    showLinearProgress: (visible: boolean) => void
    setShowFormCadUser: (showFormCadUser: boolean) => void,
    show_FormCadUser: boolean,
    setCadastroUsuarioFormData: (formData: ICadastroUsuarioFormData) => void,
    cadastroUsuarioformData: ICadastroUsuarioFormData,
    setOpenDialog: (openDialog: boolean) => void,
    open_Dialog: boolean,
    setOpenDialogText: (open_DialogTitle: string) => void,
    open_DialogTitle: string,
    handleCloseQuestionDialog: () => void,
    dialogQuestionContextText: string,
    setOpenDialogQuestionContext: (value: string) => void,
    setShowFormCadContabilidade: (show: boolean) => void,
    show_FormCadContabilidade: boolean,
    setShowCadCli: (show: boolean) => void,
    show_cadCli: boolean,
    setPathName: (pathName: string) => void,
    path_Name: string,
    setNotifyNewDoc: (newDoc: string) => void,
    new_Doc: string,
    setRequireDocsCount: (docsCount: number) => void,
    docs_count: number,
}

export default interface ICadastroUsuarioFormData {
    id?: string,
    name: string,
    email: string,
    emailSecundary?: string,
    password: string,
    plano: string,
    roles: string, // Adicione um estado para a opção selecionada no Select
    active: boolean
}

const ActionContext = createContext<IActionContext>({} as IActionContext);

type Props = {
    children?: React.ReactNode
};

const ActionProvider: React.FC<Props> = ({ children }) => {

    const [docs_count, set_RequireDocsCount] = useState<number>(0);

    const setRequireDocsCount = (docsCount: number) => {
        set_RequireDocsCount(docsCount)
    }

    const [linearProgressRunning, set_LinearProgressRunning] = useState<boolean>(false);

    const showLinearProgress = (visible: boolean) => {
        set_LinearProgressRunning(visible)
    }

    //mostra ou o grid ou o form no cadastro de usuário
    const [show_FormCadUser, set_showFormCadUser] = useState<boolean>(true);

    const setShowFormCadUser = (showFormCadUser: boolean) => {
        set_showFormCadUser(showFormCadUser)
    }

    const [open_Dialog, set_OpenDialog] = useState<boolean>(false)

    const setOpenDialog = (openDialog: boolean) => {
        set_OpenDialog(openDialog)
    }

    const [open_DialogTitle, set_OpenDialogTitle] = useState<string>('')

    const setOpenDialogText = (open_DialogTitle: string) => {
        set_OpenDialogTitle(open_DialogTitle)
    }

    //mostra ou o grid ou o form no cadastro de usuário
    const [show_FormCadContabilidade, set_show_FormCadContabilidade] = useState<boolean>(true);

    const setShowFormCadContabilidade = (show: boolean) => {
        set_show_FormCadContabilidade(show)
    }

    const [cadastroUsuarioformData, set_CadastroUsuarioFormData] = useState<ICadastroUsuarioFormData>({
        name: '',
        email: '',
        emailSecundary: '',
        password: DEFAULT_PASSWORD,
        plano: '',
        roles: '', // Adicione um estado para a opção selecionada no Select
        active: true
    });

    const setCadastroUsuarioFormData = (cadastroUsuarioFormData: ICadastroUsuarioFormData) => {
        set_CadastroUsuarioFormData(cadastroUsuarioFormData)
    }

    const [dialogQuestionContextText, set__questionDialogContextText] = useState<string>('');

    const setOpenDialogQuestionContext = (open_DialogQuestionContext: string) => {
        set__questionDialogContextText(open_DialogQuestionContext)
    }


    const handleCloseQuestionDialog = () => {
        set_OpenDialog(false)
    }


    //mostra ou o grid ou o form no cadastro de usuário
    const [show_cadCli, set_show_cadCli] = useState<boolean>(true);

    const setShowCadCli = (show: boolean) => {
        set_show_cadCli(show)
    }

    const [path_Name, set_pathName] = useState<string>('');
    const setPathName = (pathName: string) => {
        set_pathName(pathName)
    }

    const [new_Doc, set_newDoc] = useState<string>('');
    const setNotifyNewDoc = (newDoc: string) => {
        set_newDoc(newDoc)
    }

    return (
        <ActionContext.Provider value={{
            linearProgressRunning,
            showLinearProgress,
            setShowFormCadUser,
            show_FormCadUser,
            setCadastroUsuarioFormData,
            cadastroUsuarioformData,
            setOpenDialog,
            open_Dialog,
            setOpenDialogText,
            open_DialogTitle,
            handleCloseQuestionDialog,
            dialogQuestionContextText,
            setOpenDialogQuestionContext,
            setShowFormCadContabilidade,
            show_FormCadContabilidade,
            setShowCadCli,
            show_cadCli,
            setPathName,
            path_Name,
            setNotifyNewDoc,
            new_Doc,
            setRequireDocsCount,
            docs_count
        }}>
            {children}
        </ActionContext.Provider>
    );
}


function useActionContext(): IActionContext {
    const context = useContext(ActionContext);

    return context;
}

export { ActionProvider, useActionContext };
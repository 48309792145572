import React, { useEffect, useState } from "react";
import { Container, Title } from "./styles";
import QuestionDialog from "../QuestionDialog";
import { useActionContext } from "../../hooks/actions";
import { useAuth } from "../../hooks/auth";
import { useNavigate } from 'react-router-dom';
import { UserData, getUserData } from "../../services/storage.service";
import UCAtualizarTermosUsuarioService from "./usecase/uc-atualizar-usuario-termos.service";
import { ERRO, REGISTRO_ATUALIZADO, SUCESSO } from '../../views/Master/useCases/constants/admin-message.constants';
import { MENSAGEM_NAO_AUTORIZADO } from "../../hooks/constants/friendly-messages.constants";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface ICadastroUsuarioFormData {
    id: string,
    termos: boolean;
}

const TermosUso: React.FC = () => {


    const {
        showLinearProgress
    } = useActionContext()

    const { handleOpen, setTitle, setDialogContextText } = useAuth();

    const uc: UserData = getUserData()

    const [cadastroUsuarioformData, setCadastroUsuarioFormData] = useState<ICadastroUsuarioFormData>({
        id: '',
        termos: true
    });

    const navigate = useNavigate()

    const {
        signOut
    } = useAuth()

    const {
    } = useActionContext();

    const signOutByExpiresProcess = () => {
        signOut();
        navigate('/')
    }

    const updateTermos = async () => {
        try {
            showLinearProgress(true)
            const retorno: any = await UCAtualizarTermosUsuarioService.run(String(uc.sub), true)

            if (retorno === 1) {
                setTitle(SUCESSO)
                setDialogContextText(REGISTRO_ATUALIZADO)
            } else {
                setTitle(ERRO)
                if (retorno.error.statusCode === 401) {
                    setDialogContextText(MENSAGEM_NAO_AUTORIZADO)
                }
                else {
                    if (typeof retorno.error === 'string') {
                        setDialogContextText(retorno.error)
                    } else {
                        setDialogContextText(retorno.error.message.toString())
                    }
                }
            }
            showLinearProgress(false)
        } catch (error: any) {
            handleOpen(true)
            setTitle(ERRO)
            setDialogContextText(error.message)
            showLinearProgress(false)
        }
    }

    useEffect(() => {        
        setCadastroUsuarioFormData({ id: String(uc.sub), termos: uc.termos || uc.roles[0].id === 1 })
    }, [])

    return (
        <Container>
            <div>
                <Dialog
                    open={!cadastroUsuarioformData.termos}
                    onClose={() => {
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Termos de Uso do Sistema de Gerenciamento de Documentos - O GUARDALIVROS
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Title>
                                Bem-vindo ao Sistema de Gerenciamento de Documentos (GED) de O GuardaLivros!
                            </Title>
                            <br />
                            <span>
                                Ao acessar ou utilizar nosso sistema GED, você concorda em cumprir estes Termos.
                                Estes Termos de Uso ("Termos") regem o uso do nosso sistema GED por você, o usuário.
                            </span>
                            <br />
                            <br />
                            <Title>
                                Descrição do Serviço
                            </Title>
                            <br />
                            <span>
                                Nosso sistema GED permite que sua Contabilidade faça o upload e o gerenciamento de documentos fiscais, como DARFs, GPS, relatórios contábeis e outros documentos relacionados, para facilitar o acesso e o compartilhamento com seus clientes.
                            </span>
                            <br />
                            <br />
                            <Title>
                                Acesso e Uso do Sistema GED
                            </Title>
                            <br />
                            <span>
                                Você concorda em usar o sistema GED apenas para fins legítimos relacionados aos serviços contábeis prestados pela sua Contabilidade.
                                Você concorda em não utilizar o sistema GED para qualquer atividade ilegal, fraudulenta, difamatória, obscena ou que viole os direitos de terceiros.
                                Você é responsável por manter a confidencialidade de suas credenciais de login e por todas as atividades que ocorrerem sob sua conta.
                                Você concorda em não tentar acessar áreas protegidas por medidas de segurança do sistema GED sem autorização adequada.
                            </span>
                            <br />
                            <br />
                            <Title>
                                Propriedade Intelectual
                            </Title>
                            <br />
                            <span>
                                Todo o conteúdo disponibilizado no sistema GED, incluindo documentos, logotipos, marcas registradas e outros materiais, é de propriedade da [GUARDALIVROS] ou de seus licenciadores e está protegido por leis de propriedade intelectual.
                                Você concorda em não reproduzir, distribuir, modificar ou criar obras derivadas do conteúdo do sistema GED sem autorização prévia por escrito da [GUARDALIVROS].
                            </span>
                            <br />
                            <br />
                            <Title>
                                Isenção de Garantias e Limitação de Responsabilidade
                            </Title>
                            <br />
                            <span>
                                O sistema GED é fornecido "no estado em que se encontra" e "conforme disponível", sem garantias de qualquer tipo, expressas ou implícitas.
                                A [GUARDALIVROS] não será responsável por quaisquer danos diretos, indiretos, incidentais, especiais, consequenciais ou punitivos decorrentes do uso ou da incapacidade de usar o sistema GED.
                            </span>
                            <br />
                            <br />
                            <Title>
                                Alterações nos Termos de Uso
                            </Title>
                            <br />
                            <span>
                                A [GUARDALIVROS] reserva-se o direito de modificar estes Termos de Uso a qualquer momento, mediante aviso prévio razoável.
                                O uso contínuo do sistema GED após tais alterações constituirá sua aceitação dos Termos de Uso revisados.
                            </span>
                            <br />
                            <br />
                            <Title>
                                Disposições Gerais
                            </Title>
                            <br />
                            <span>
                                Estes Termos de Uso constituem o acordo completo entre você e a [GUARDALIVROS] em relação ao uso do sistema GED e substituem todos os acordos anteriores ou contemporâneos entre as partes sobre esse assunto.
                                Se qualquer disposição destes Termos for considerada inválida ou inexequível por um tribunal competente, as demais disposições permanecerão em pleno vigor e efeito.
                                Estes Termos serão regidos e interpretados de acordo com as leis do [país ou estado], sem consideração aos seus princípios de conflitos de leis.
                                Ao utilizar nosso sistema GED, você concorda com estes Termos de Uso. Se você não concorda com estes Termos, por favor, não utilize o sistema GED.
                            </span>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={async () => {
                            try {
                                await updateTermos()
                                setCadastroUsuarioFormData({ id: String(uc.sub), termos: true })
                            } catch (error: any) {
                                handleOpen(true)
                                setTitle(ERRO)
                                setDialogContextText(error.message)
                                showLinearProgress(false)
                            }
                        }}>Aceitar</Button>
                        <Button disabled onClick={() => { }}>Cancela</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Container>
    )
}

export default TermosUso
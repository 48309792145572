import React, { useEffect, useState } from 'react';
import { Containerd, Desktop, Mobile } from './styles';
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import { UserData, getUserData } from '../../../../services/storage.service';
import UCGravarEntidadeClienteService from '../../useCases/uc-gravar-entidade-cliente';
import { useAuth } from '../../../../hooks/auth';
import { useActionContext } from '../../../../hooks/actions';
import UCAtualizarEntidadeContabilidadeService from '../../useCases/uc-editar-entidade-cliente';
import UCConsultarContabilidadesByUserIdService from '../../useCases/uc-consultar-entidade-contabilidades';
import { useNavigate } from 'react-router-dom';
import UCCadastrarUsuarioService from '../../../Master/useCases/uc-cadastrar-usuario.service';
import { DEFAULT_PASSWORD } from '../../../../hooks/constants/constants';
import UCAtualizarUsuarioService from '../../../Master/useCases/uc-atualizar-usuario.service';
import UCAtualizarUsuarioFromAccountabilitydService from '../../useCases/uc-atualizar-usuarioFromAccountability';
import errorMessageService from '../../../../services/errorMessage.service';


type IContabilidadesDesktopProps = {
    onEdit: () => any
}


interface Contabilidades {
    id: number
    name: string
}


export interface FormState {
    id?: number,
    contabilidade: string;
    email?: string;
    telefone?: string;
    contato?: string;
    emailContato: string;
    telefoneContato: string;
    tipoPessoa: string;
    documento: string;
    userId: number;
    entityTypeId: number,
    entityId: number,
    userIdFuncionario?: number
}

const ClientesDesktop: React.FC<IContabilidadesDesktopProps> = ({ onEdit }) => {

    const navigate = useNavigate()

    const { handleOpen, setTitle, setDialogContextText, signOut } = useAuth();
    const {
        showLinearProgress,
    } = useActionContext()

    const userData: UserData = getUserData()

    const alerto = (title: string, content: string) => {
        handleOpen(true)
        setTitle(title)
        setDialogContextText(content)
    }

    const [buttonActionName, setButtonActionName] = useState<string>('Cadastrar')
    const [listaContabilidades, setListaContabilidades] = useState<Contabilidades[]>([])
    const [documentType, setdocumentType] = useState<string>('cpf')
    const [buttonDisabled, setbuttonDisabled] = useState<boolean>(false)


    // Use o estado para rastrear os valores do formulário
    const [formData, setFormData] = useState<FormState>({
        id: 0,
        contabilidade: '',
        email: '',
        telefone: '',
        contato: '',
        emailContato: '',
        telefoneContato: '',
        tipoPessoa: '',
        documento: '',
        userId: Number(userData.sub),
        entityTypeId: 1,//sempre 1 pq é Contabilidade
        entityId: 0
    });

    const clearForm = () => {
        setFormData({
            id: 0,
            contabilidade: '',
            email: '',
            telefone: '',
            contato: '',
            emailContato: '',
            telefoneContato: '',
            tipoPessoa: '',
            documento: '',
            userId: Number(userData.sub),//usuario do registro
            entityTypeId: 2,//sempre 2 pq é Cliente
            entityId: 0
        })
        setButtonActionName('Cadastrar')
    }

    // Lidar com a mudança nos campos do formulário
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData(({
            ...formData,
            [name]: value,
        }));
    };

    const signOutByExpiresProcess = () => {
        signOut();
        navigate('/')
    }

    // Lidar com o envio do formulário
    const handleSubmit = async (e: React.FormEvent) => {

        try {            
            setbuttonDisabled(true)
            formData.entityId = listaContabilidades[0].id

            showLinearProgress(true)
            e.preventDefault();

            let msg = ``

            if (buttonActionName === 'Cadastrar') {

                //todo: criar usuario do tipo funcionario para o cliente poder acessar os documentos
                const responseUser: any = await UCCadastrarUsuarioService.run(
                    formData.contabilidade,
                    String(formData.email),
                    DEFAULT_PASSWORD,
                    3,
                    true,
                    "1",
                    formData.emailContato
                )

                if (responseUser.error) {
                    alerto('Erro', `Erro ao criar usuário para o Cliente: ${responseUser.error.message}`)
                    showLinearProgress(false)
                    setbuttonDisabled(false)
                    return
                }

                formData.userIdFuncionario = responseUser.id
                const response: any = await UCGravarEntidadeClienteService.run(formData, signOutByExpiresProcess)

                if (response.ok && responseUser.id) {
                    msg = 'Cliente criada com sucesso'
                    setTitle('Sucesso')
                    alerto('Sucesso', msg)
                    clearForm()
                } else {
                    const err = await response.json()
                    msg = err.error.message || err.error

                    if (err?.error?.message) {
                        if (Array.isArray(err.error.message)) {
                            msg = err.error.message.join(' - ')
                        }
                    }
                    errorMessageService(msg, response, alerto)                    
                }
            } else {
                const response: any = await UCAtualizarEntidadeContabilidadeService.run(formData, signOutByExpiresProcess)
                if (response.ok) {
                    msg = 'Cliente atualizado com sucesso'
                    setTitle('Sucesso')
                    alerto('Sucesso', msg)
                    clearForm()
                    const reto = await UCAtualizarUsuarioFromAccountabilitydService.run(String(formData.userIdFuncionario), formData.contabilidade, formData.email, signOut)
                } else {
                    const err = await response.json()
                    msg = err.error.message || err.error
                    errorMessageService(msg, response, alerto)
                    //alerto('Erro', msg)
                }
            }
            showLinearProgress(false)
            setbuttonDisabled(false)
        } catch (error: any) {
            alerto('Erro', error.message)
        }
    };

    const formatarCpf = (cpf: string) => {
        // Remove caracteres não numéricos
        cpf = cpf.replace(/\D/g, '');

        // Adiciona os pontos e traço
        cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

        return cpf.substring(0, 14);
    }

    const formatarCnpj = (cnpj: string) => {
        cnpj = cnpj.replace(/\D/g, '');
        cnpj = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
        return cnpj.substring(0, 18);
    }

    const handleInputChangeCnpj = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData(({
            ...formData,
            [name]: formatarCnpj(value),
        }));
    };

    const handleInputChangeCpf = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData(({
            ...formData,
            [name]: formatarCpf(value),
        }));
    };

    const loadContabilidades = async () => {        
        const _contabilidadesResponse = await UCConsultarContabilidadesByUserIdService.run(signOutByExpiresProcess)

        if (_contabilidadesResponse.ok) {

            const _contabilidades = await _contabilidadesResponse.json()
            const contabilidadesMapped = _contabilidades.map((cont: Contabilidades) => {
                const c: Contabilidades = {
                    id: cont.id,
                    name: cont.name
                }
                return c;
            })

            setListaContabilidades(contabilidadesMapped)
        } else {
            const msg = await _contabilidadesResponse.json()
            errorMessageService(msg, _contabilidadesResponse, alerto)
        }
    }

    useEffect(() => {
        const val = onEdit()
        if (val.userId) {
            setButtonActionName('Editar')
            setFormData({
                "id": val.id,
                contabilidade: val.name,
                "email": val.email,
                telefone: val.phoneNumber,
                contato: val.contact,
                emailContato: val.contactEmail,
                telefoneContato: val.contactPhone,
                tipoPessoa: val.personType,
                documento: val.document,
                "userId": val.userId,
                "entityTypeId": val.entityTypeId,
                entityId: val.entityId,
                userIdFuncionario: val.userIdFuncionario
            })

            if (val.tipoPessoa === 'PF') {
                setdocumentType('PF')
            } else {
                setdocumentType('PJ')
            }
        }

        loadContabilidades()
    }, [])

    return (

        <Containerd>
            <Desktop>
                <Paper elevation={24} style={{ padding: '20px', margin: '30px auto', maxWidth: '1200px' }} >
                    <h2>
                        Clientes de: {listaContabilidades.length > 0 ? listaContabilidades[0].name : ''}
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Cliente"
                                    fullWidth
                                    name="contabilidade"
                                    value={formData.contabilidade}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type='email'
                                    label="Email"
                                    fullWidth
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type='tel'
                                    label="Telefone"
                                    fullWidth
                                    name="telefone"
                                    value={formData.telefone}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Contato"
                                    fullWidth
                                    name="contato"
                                    value={formData.contato}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Email Contato"
                                    fullWidth
                                    name="emailContato"
                                    value={formData.emailContato}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Telefone Contato"
                                    fullWidth
                                    name="telefoneContato"
                                    value={formData.telefoneContato}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth style={{ margin: '0 30px 0 0' }}>
                                    <InputLabel id="tipoPessoa-label">Tipo Pessoa</InputLabel>
                                    <Select
                                        required
                                        labelId="tipoPessoa-label"
                                        id="tipoPessoa"
                                        name="tipoPessoa"
                                        value={formData.tipoPessoa}
                                        onChange={(e: any) => {
                                            setFormData({ ...formData, tipoPessoa: e.target.value })
                                            if (e.target.value === 'PF') {
                                                setdocumentType('PF')
                                            } else {
                                                setdocumentType('PJ')
                                            }
                                        }}
                                    >
                                        <MenuItem value="PF">Física</MenuItem>
                                        <MenuItem value="PJ">Jurídica</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                {documentType === 'PF' && (<TextField
                                    label="CPF"
                                    fullWidth
                                    name="documento"
                                    value={formData.documento}
                                    onChange={handleInputChangeCpf}
                                    inputProps={{ maxLength: 14 }}

                                />)}
                                {documentType === 'PJ' && (<TextField
                                    label="CNPJ"
                                    fullWidth
                                    name="documento"
                                    value={formData.documento}
                                    onChange={handleInputChangeCnpj}
                                    inputProps={{ maxLength: 18 }}
                                />)}
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    disabled={buttonDisabled}
                                    type="submit" variant="text" color="inherit" style={{ marginTop: '20px', marginRight: '10px' }}>
                                    {buttonActionName}
                                </Button>
                                <Button variant="text" color="inherit" style={{ marginTop: '20px' }} onClick={clearForm} >
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Desktop>
            <Mobile>
                <Paper elevation={24} style={{ padding: '20px', margin: '30px auto', maxWidth: '1200px' }} >
                    <h2>
                        Clientes de: {listaContabilidades.length > 0 ? listaContabilidades[0].name : ''}
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Cliente"
                                    fullWidth
                                    name="contabilidade"
                                    value={formData.contabilidade}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type='email'
                                    label="Email"
                                    fullWidth
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type='tel'
                                    label="Telefone"
                                    fullWidth
                                    name="telefone"
                                    value={formData.telefone}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Contato"
                                    fullWidth
                                    name="contato"
                                    value={formData.contato}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Email Contato"
                                    fullWidth
                                    name="emailContato"
                                    value={formData.emailContato}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Telefone Contato"
                                    fullWidth
                                    name="telefoneContato"
                                    value={formData.telefoneContato}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth style={{ margin: '0 30px 0 0' }}>
                                    <InputLabel id="tipoPessoa-label">Tipo Pessoa</InputLabel>
                                    <Select
                                        required
                                        labelId="tipoPessoa-label"
                                        id="tipoPessoa"
                                        name="tipoPessoa"
                                        value={formData.tipoPessoa}
                                        onChange={(e: any) => {
                                            setFormData({ ...formData, tipoPessoa: e.target.value })
                                            if (e.target.value === 'PF') {
                                                setdocumentType('PF')
                                            } else {
                                                setdocumentType('PJ')
                                            }
                                        }}
                                    >
                                        <MenuItem value="PF">Física</MenuItem>
                                        <MenuItem value="PJ">Jurídica</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                {documentType === 'PF' && (<TextField
                                    label="CPF"
                                    fullWidth
                                    name="documento"
                                    value={formData.documento}
                                    onChange={handleInputChangeCpf}
                                    inputProps={{ maxLength: 14 }}

                                />)}
                                {documentType === 'PJ' && (<TextField
                                    label="CNPJ"
                                    fullWidth
                                    name="documento"
                                    value={formData.documento}
                                    onChange={handleInputChangeCnpj}
                                    inputProps={{ maxLength: 18 }}
                                />)}
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="text" color="inherit" style={{ marginTop: '20px', marginRight: '10px' }}>
                                    {buttonActionName}
                                </Button>
                                <Button variant="text" color="inherit" style={{ marginTop: '20px' }} onClick={clearForm} >
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Mobile>
        </Containerd>

    )
}

export default ClientesDesktop;

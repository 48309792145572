
import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher, fetcher2 } from "../../../services/fetcher.service"
import { FormState } from "../components"

const run = async (idToDelete: number, callback: any) => {
    
    const url = `${URI_BASE}/employee-documents/${idToDelete}`

    return await fetcher2(url, 'DELETE', null, callback)
}

const UCRemoverEmployeeDocsService = {
    run
}

export default UCRemoverEmployeeDocsService
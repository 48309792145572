import { useEffect, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useActionContext } from '../../hooks/actions';

const TreeViewMaster = () => {

    const { setShowFormCadUser } = useActionContext()

    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    useEffect(() => {
        setOpen(true);
    })

    return (
        <List>
            <ListItem button onClick={handleToggle}>
                <ListItemIcon>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Master" />
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button onClick={() => {
                        setShowFormCadUser(true)
                    }}>
                        <ListItemIcon>
                            <PersonAddAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="Cadastro Usuário" />
                    </ListItem>
                    <ListItem button onClick={() => {
                        setShowFormCadUser(false)
                    }}>
                        <ListItemIcon>
                            <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="Listar Usuários" />
                    </ListItem>                    
                </List>
            </Collapse>
        </List>
    );
};

export default TreeViewMaster;

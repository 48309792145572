import AlterDialog from './components/Dialog';
import { useAuth } from './hooks/auth';

import Routes from './routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/pt-br';


function App() {

  const { handleClose } = useAuth();

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">

        <AlterDialog
          handleClose={handleClose}
        ></AlterDialog>

        <Routes />
      </LocalizationProvider>

    </>
  );
}

export default App;

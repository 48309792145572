import { Box, Button, Card, CardContent, CardHeader, Grid, LinearProgress, Paper, Tab, Tabs, Tooltip, styled } from "@mui/material";
import { useNavigate } from 'react-router-dom'; // Importe o useHistory do React Router
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useAuth } from "../../hooks/auth";
import { UserData, getUserData } from "../../services/storage.service";
import { Containerd, GridoContainer } from "./styles";
import CloudUpload from '@mui/icons-material/CloudUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ForumIcon from '@mui/icons-material/Forum';
import Avatar from '@mui/material/Avatar';
import { GridColDef } from "@mui/x-data-grid";
import Grido2 from "../../components/Grido2";
import UCGetRequiredDocsService from "../DocumentosEntidadeEmployee/useCases/uc-geRequireDocuments.service";
import UCGetEntityByUserIdFuncionarioService from "../DocumentosEntidadeEmployee/useCases/uc-getEntityByUserIdFuncionario.service";
import moment from 'moment'
import { useActionContext } from "../../hooks/actions";
import UCGravarEmployeeDocsFromClientService from "./useCases/uc-gravar-employeeDocs.service";
import UCUploadEmployeeDocsService from "../DocumentosEntitdade/useCases/uc-upload-employeeDocs.service";
import UCFillRequireDocumentsService from "./useCases/uc-fill-requiredDoc.service";
import { ROTA_LOGIN } from "../../constants/uri/constants.uri";
import errorMessageService from "../../services/errorMessage.service";
import RequisicaoDocumentoForm from "../RequisicaoDocumento/components/formulario";
import { RequireDocumentWithEntity, RequisicaoDocumento } from "../RequisicaoDocumento";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UCRetornarRequisicaoDocumentoByClientIdService from "../RequisicaoDocumento/useCases/uc-consultarPorClientID-requisicao-documento";
import Grido from "../../components/Grido";
import UCRemoverRequisicaoDocumentoService from "../RequisicaoDocumento/useCases/uc-remover-requisicao-documento";
import QuestionDialog from "../../components/QuestionDialog";

export interface FormState {
    id: string | null;
    documentName: string;
    documentUrl: string;
    documentGuid: string;
    documentDescription: string;
    file: any;
    dateToWarn: Date | null;
    visualizationDate: Date | null;
    createdDate: Date | null;
    udpatedDate: Date | null;
    entityId: number;
    file_size: number;
    rejectedDoc: boolean;
    rejectDescription: string;
}

export interface DocumentRequire {
    id: number
    documentRequireName: string
    documentRequireDescription: string
    created_at: string
    updated_at: any
    accountability: Accountability
    client: Client
}

export interface Accountability {
    id: number
    name: string
    email: string
    phoneNumber: string
    contact: string
    contactEmail: string
    contactPhone: string
    personType: string
    document: string
    userId: number
    entityTypeId: number
    entityId: any
    userIdFuncionario: any
}

export interface Client {
    id: number
    name: string
    email: string
    phoneNumber: string
    contact: string
    contactEmail: string
    contactPhone: string
    personType: string
    document: string
    userId: number
    entityTypeId: number
    entityId: number
    userIdFuncionario: number
}


const initialFormData: FormState = {
    id: null,
    documentName: '',
    documentUrl: '',
    documentGuid: '',
    documentDescription: '',
    file: null,
    dateToWarn: null,
    visualizationDate: null,
    createdDate: null,
    udpatedDate: null,
    entityId: 0,
    file_size: 0,
    rejectedDoc: false,
    rejectDescription: ''
};


const Comunicacao: React.FC = () => {

    const navigate = useNavigate()

    const signOutByExpiresProcess = () => {
        signOut();
        navigate(ROTA_LOGIN)
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const fileInputRef = useRef<HTMLInputElement | null>(null);




    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>, params: any) => {
        try {
            showLinearProgress(true)
            const files = event.target.files;

            //verifico se tem algum arquivo selecionado
            if (files && files.length > 0) {
                const _file = files[0]
                const _fileSize = _file.size
                const _fileSizeLimit = 10 * 1024 * 1024;


                const modifiedFileName = removerCaracteresEspeciais(_file.name);
                const modifiedFile = new File([_file], modifiedFileName, { type: _file.type });


                //tamanho limite
                if (_fileSize > _fileSizeLimit) {
                    alerto('Atenção', 'Tamanho excede o limite de 10 MB')
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }

                    return
                }

                const formDataToAppend = new FormData();
                formDataToAppend.append('file', modifiedFile);

                //upload pra pegar guid e url
                const retorno = await UCUploadEmployeeDocsService.run(formDataToAppend, signOutByExpiresProcess)

                if (!retorno.ok) {
                    const retornoMessage: any = await retorno.json()
                    errorMessageService(`Erro ao realizar upload do arquivo: ${retornoMessage.error}`, retorno, alerto)
                } else {

                    const fileUploadedData = await retorno.json()
                    formData.documentGuid = fileUploadedData.name
                    formData.documentUrl = fileUploadedData.url
                    //formData.file = files[0]
                    formData.file_size = _fileSize / 1024
                    formData.documentName = params.documentRequireName
                    formData.documentDescription = params.documentRequireDescription

                    const ret = await UCGravarEmployeeDocsFromClientService.run(formData, signOutByExpiresProcess)

                    if (ret.ok) {
                        //atulizar a requisição como filled
                        let updateRequireDocument = {
                            "id": params.id,
                            "fill": true
                        }

                        const requestUpdateDocument = await UCFillRequireDocumentsService.run(updateRequireDocument, signOutByExpiresProcess)
                        if (requestUpdateDocument.ok) {
                            //mensagem de envio com sucesso
                            alerto('Info', 'Documento Enviado com sucesso')
                            await loadRequireDocuments()
                        } else {
                            const requestUpdateDocumentMessage = await requestUpdateDocument.json()
                            alerto('Erro', 'Erro ao enviar documento')
                        }


                    } else {
                        alerto('Erro', 'Erro ao enviar documento')
                        errorMessageService('Erro ao enviar documento', ret, alerto)
                    }
                    showLinearProgress(false)
                }
            }
            showLinearProgress(false)
        } catch (error) {
            alerto('Erro', 'Erro ao enviar documento')
            showLinearProgress(false)
        }
    };

    const removerCaracteresEspeciais = (texto: string): string => {
        // Remover acentos, cedilha, til e outros caracteres especiais
        const textoSemAcentos = texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        // Remover caracteres especiais, exceto alfanuméricos, espaços, hífens e pontos
        return textoSemAcentos.replace(/[^\w\s.-]/gi, '');
    }

    const [formData, setFormData] = useState<FormState>(initialFormData);

    const [formDataRequireDocument, setFormDataRequireDocument] = useState<RequisicaoDocumento>({
        clientId: 0,
        entityId: 0,
        requisicaoLabel: '',
        requisicaoMotivoDescricao: '',
        direction: 'out'
    });

    const clearSetFormDataRequireDocument = () => {
        setFormDataRequireDocument({ clientId: 0, entityId: 0, requisicaoLabel: '', requisicaoMotivoDescricao: '', direction: '' })
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 1 },
        { field: 'documentRequireName', headerName: 'Documento Solicitado', width: 180 },
        { field: 'documentRequireDescription', headerName: 'Desc. Documento', width: 330 },
        {
            field: 'created_at', headerName: 'Criado', width: 160,
            renderCell: (params) => (
                <div>
                    {params.row.created_at ? moment(params.row.created_at).format('DD/MM/YYYY hh:mm:ss') : '-'}
                </div>)
        },
        {
            field: '',
            headerName: 'Ações',
            width: 250,
            headerAlign: 'left',
            renderCell: (params) => (
                <div>
                    <input
                        type="file"
                        id={`file-input` + params.row.id}
                        accept={'.pdf, .doc, .docx, .jpg, .jpeg, ;npg, .bmp, .zip, .xml'}
                        style={{ display: 'none' }}
                        onChange={(e) => {
                            handleFileChange(e, params.row)
                        }}
                    />
                    <label htmlFor={`file-input` + params.row.id}>
                        <Button
                            id={params.row.id}
                            variant="text"
                            startIcon={
                                <CloudUpload />
                            }
                            component="span"
                        >
                            Enviar
                        </Button>
                    </label>
                </div>)
        }
    ]

    const uc = getUserData()

    const {
        signOut,
        setDialogContextText,
        setTitle,
        handleOpen
    } = useAuth()

    const {
        showLinearProgress,
        setRequireDocsCount,
        handleCloseQuestionDialog,
        setOpenDialog,
        setOpenDialogText,
        setOpenDialogQuestionContext
    } = useActionContext()

    const alerto = (title: string, content: string) => {
        handleOpen(true)
        setTitle(title)
        setDialogContextText(content)
    }

    const loadRequireDocuments = async () => {

        try {
            showLinearProgress(true)
            const id = Number(uc.sub)
            //encontrar o entity dono do usuario em questao
            const responseEntityFromUser = await UCGetEntityByUserIdFuncionarioService.run(id, signOutByExpiresProcess)

            if (!responseEntityFromUser.ok) {
                await errorMessageService("Erro ao resgatar documentos da entidade", responseEntityFromUser, alerto)
            } else {
                const entity = await responseEntityFromUser.json()
                const docsRequest = await UCGetRequiredDocsService.run(Number(entity.id), signOutByExpiresProcess)

                if (!docsRequest.ok) {
                    await errorMessageService("Erro ao resgatar documentos da entidade", docsRequest, alerto)
                } else {

                    const docsRequestReturn = await docsRequest.json()
                    setDocumentRequire(docsRequestReturn)
                    setRequireDocsCount(docsRequestReturn.length)
                }
            }
            showLinearProgress(false)
        } catch (error: any) {
            alerto('Info', `Erro: ${error.message}`)
            showLinearProgress(false)
        }
    }

    const [documentRequire, setDocumentRequire] = useState<DocumentRequire[]>([])
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }


    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    //grido
    const [requisicaoDocumentoWithEntity, setRequisicaoDocumentoWithEntity] = useState<RequireDocumentWithEntity[]>([])

    const columnsReqDoc: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 10 },
        { field: 'documentRequireName', headerName: 'Título Doc.', width: 140 },
        { field: 'documentRequireDescription', headerName: 'Desc. Doc', width: 380 },
        { field: 'fill', headerName: 'Pendente', width: 180 },
        {
            field: 'created_at', headerName: 'Criado', width: 180,
            renderCell: (params) => (
                <div>
                    {moment(params.row.created_at).format('DD/MM/YYYY hh:mm:ss')}
                </div>)
        },
        {
            field: 'updated_at', headerName: 'Atualizado', width: 180,
            renderCell: (params) => (
                <div>
                    {params.row.updated_at ? moment(params.row.updated_at).format('DD/MM/YYYY hh:mm:ss') : '-'}
                </div>)
        },
        {
            field: 'actions',
            headerName: 'Ações',
            width: 250,
            headerAlign: 'left',
            renderCell: (params) => (
                <div>
                    <Button startIcon={
                        <Tooltip title='Editar'>
                            <EditIcon />
                        </Tooltip>
                    } onClick={() => {

                        const req: string = localStorage.getItem('requisicaoDocumento') as string
                        let requisicaoDocumento = JSON.parse(req)

                        requisicaoDocumento.requisicaoMotivoDescricao = params.row.documentRequireDescription
                        requisicaoDocumento.requisicaoLabel = params.row.documentRequireName
                        requisicaoDocumento.id = params.row.id
                        localStorage.setItem('requisicaoDocumento', JSON.stringify(requisicaoDocumento))

                        setFormDataRequireDocument(
                            {
                                requisicaoLabel: params.row.documentRequireName,
                                requisicaoMotivoDescricao: params.row.documentRequireDescription,
                                clientId: 0,
                                entityId: 0,
                                id: Number(params.row.id)
                            })

                        //setShowCadCli(true)
                        //setValue(0)
                    }}>
                    </Button>                 
                    
                    
                    <Button startIcon={
                        <Tooltip title='Remover'>
                            <DeleteIcon />
                        </Tooltip>
                    }
                        onClick={async () => {
                            await questionRemoverRequisicao(params.row.id)
                        }}>
                    </Button>
                </div>
            )
        }
    ]


    const questionRemoverRequisicao = async (id: any) => {
        setOpenDialog(true)
        setOpenDialogText('Remoção de Usuário')
        setOpenDialogQuestionContext(`Deseja remover o registro ${id}`)
        setFormDataRequireDocument({ ...formDataRequireDocument, id: id })
    }

    const removerRequisicao = async () => {
        try {
            showLinearProgress(true)
            const response = await UCRemoverRequisicaoDocumentoService.run(Number(formDataRequireDocument.id), signOutByExpiresProcess)
            if (response.ok) {
                alerto('Info', 'Requisição removida com sucesso!')
            } else {
                alerto('Erro', 'Ocorreu um erro ao remover o registro, contate o administrador')
            }

            showLinearProgress(false)
            loadData()
        } catch (error: any) {
            alerto('Erro', error.message)
            showLinearProgress(false)
        }
    }

    const loadData = async () => {

        showLinearProgress(true)
        const id = Number(uc.sub)
        //encontrar o entity dono do usuario em questao
        const responseEntityFromUser = await UCGetEntityByUserIdFuncionarioService.run(id, signOutByExpiresProcess)

        if (!responseEntityFromUser.ok) {
            await errorMessageService("Erro ao resgatar documentos da entidade", responseEntityFromUser, alerto)
        } else {
            const entity = await responseEntityFromUser.json()
            if (entity) {
                const requisicaoDocumento = { clientId: entity.id, entityId: entity.entityId }
                localStorage.setItem('requisicaoDocumento', JSON.stringify(requisicaoDocumento))

                const docsRequired = await UCRetornarRequisicaoDocumentoByClientIdService.run(entity.id, signOutByExpiresProcess, 'in')
                if (docsRequired.ok) {
                    const result = await docsRequired.json()
                    const gridData = result.map((el: any) => {
                        return {
                            ...el,
                            clientName: el.client.name,
                            fill: el.fill ? 'Não' : 'Sim'
                        }
                    })
                    setRequisicaoDocumentoWithEntity(gridData)
                }
            }
        }
        showLinearProgress(false)
    }


    useEffect(() => {
        loadRequireDocuments()//requeridos ao cliente
        loadData() // requeridos pelo cliente
    }, [])


    return (
        <Containerd>
            <QuestionDialog
                handleClose={handleCloseQuestionDialog}
                handleConfirm={async () => {

                    try {
                        removerRequisicao()
                    } catch (error) {

                    }

                    handleCloseQuestionDialog()
                }}
            ></QuestionDialog>

            <Card variant="outlined">
                <CardHeader
                    avatar={
                        <Avatar aria-label="icon" >
                            <ForumIcon />
                        </Avatar>
                    }
                    title="Comunicação"
                    subheader="Facilitando a comunicação entre sua Contabilidade e Você!"
                />
                <CardContent>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Recebida" {...a11yProps(0)} />
                                <Tab label="Solicitada" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <GridoContainer>
                            <Paper elevation={1} style={{ padding: '20px', margin: '05px auto' }} >
                                <h3>
                                    Faça o UPLOAD de Documentos que a sua Contabilidade requeriu a você!</h3>
                                <h4>Atenção!</h4>
                                <p> <span className='destaque'> Não</span> utilize arquivos com nomes com caracteres especiais como (<span className='destaque'>`'~^ç@#$%&</span>), (<span className='destaque'>hifen</span>), (<span className='destaque'>virgula</span>), etc... </p>
                            </Paper>
                            <Grido2
                                height={600}
                                onLoad={() => {
                                    //loadClientes()
                                }}
                                pageSize={10}
                                _columns={columns}
                                _rows={documentRequire}
                                onClick={() => { }}
                            />
                        </GridoContainer>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>

                        <RequisicaoDocumentoForm
                            onConfirm={() => {
                                loadData()
                                clearSetFormDataRequireDocument()
                            }}
                            documentRequireDescription={formDataRequireDocument.requisicaoMotivoDescricao}
                            documentRequireName={formDataRequireDocument.requisicaoLabel}
                            id={Number(formDataRequireDocument.id)}
                            direction='in'
                        />

                        <GridoContainer>
                            <Grido
                                _columns={columnsReqDoc}
                                _rows={requisicaoDocumentoWithEntity}
                                onClick={(e) => { console.log(e) }}
                                pageSize={10}
                            ></Grido>
                        </GridoContainer>

                    </CustomTabPanel>
                </CardContent>
            </Card>

        </Containerd>
    )
}

export default Comunicacao;
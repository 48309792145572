import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher2 } from "../../../services/fetcher.service"

const run = async (id: string, name?: string, email?: string, callback?: any) => {

    if (!id) throw Error("Id não identificado")
    let userData: any = {}
    if (name) userData.name = name
    if (email) userData.email = email
    
    const url = `${URI_BASE}/usuario/accountability/${id}`
    const response = await fetcher2(url, 'PUT', userData, callback)
    return response
}

const UCAtualizarUsuarioFromAccountabilitydService = {
    run
}

export default UCAtualizarUsuarioFromAccountabilitydService
import React from "react";
import { BarToProgress, Grid } from './styles'
import MainHeader from "../MainHeader";
import Aside from "../Aside";
import Content from "../Content";
import { CircularProgress, LinearProgress } from "@mui/material";
import { useActionContext } from "../../hooks/actions";
import TermosUso from "../termos-uso";

type Props = {
    children?: React.ReactNode
};


const LayoutAccountabilityClient: React.FC<Props> = ({ children }) => {

    const { linearProgressRunning } = useActionContext()

    return (
        <>
            <Grid>
                <MainHeader />
                <Content>
                {linearProgressRunning && (<BarToProgress >
                        {linearProgressRunning && (<LinearProgress />)}
                        <div>                            
                            <CircularProgress size={80} />
                        </div>
                    </BarToProgress>)}
                    {/* {children} */}
                    {children}
                    <TermosUso></TermosUso>
                </Content>
            </Grid>
        </>
    )
}

export default LayoutAccountabilityClient;
import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import {
    TextField,
    Button,
    Grid,
    Paper,
    Input
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { DatePicker } from '@mui/x-date-pickers';
import { useLocation } from 'react-router-dom';
import { Desktop, Mobile } from '../styles';
import UCGravarEmployeeDocsService from '../useCases/uc-gravar-employeeDocs.service';
import { useAuth } from '../../../hooks/auth';
import { useNavigate } from 'react-router-dom';
import UCUploadEmployeeDocsService from '../useCases/uc-upload-employeeDocs.service';
import { useActionContext } from '../../../hooks/actions';

export interface FormState {
    id: string | null;
    documentName: string;
    documentUrl: string;
    documentGuid: string;
    documentDescription: string;
    file: any;
    dateToWarn: Date | null;
    visualizationDate: Date | null;
    createdDate: Date | null;
    udpatedDate: Date | null;
    entityId: number;
    file_size: number;
    rejectedDoc: boolean;
    rejectDescription: string;
}

const initialFormData: FormState = {
    id: null,
    documentName: '',
    documentUrl: '',
    documentGuid: '',
    documentDescription: '',
    file: null,
    dateToWarn: null,
    visualizationDate: null,
    createdDate: null,
    udpatedDate: null,
    entityId: 0,
    file_size: 0,
    rejectedDoc: false,
    rejectDescription: ''
};

const DocumentForm: React.FC = () => {

    const { search } = useLocation();
    const params = new URLSearchParams(search);

    const [formData, setFormData] = useState<FormState>(initialFormData);
    const [_entityName, setEntityName] = useState<string>('');
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const fileInputRefMob = useRef<HTMLInputElement | null>(null);
    const [buttonDisabled, setbuttonDisabled] = useState<boolean>(false)
    const navigate = useNavigate()

    const {
        setDialogContextText,
        setTitle,
        handleOpen,
        signOut
    } = useAuth()

    const {
        showLinearProgress,
        setNotifyNewDoc
    } = useActionContext()

    const signOutByExpiresProcess = () => {
        signOut();
        navigate('/')
    }


    const alerto = (title: string, content: string) => {
        handleOpen(true)
        setTitle(title)
        setDialogContextText(content)
    }


    const handleSubmit = async (e: React.FormEvent) => {
        try {
            setbuttonDisabled(true)
            e.preventDefault();

            const formDataToAppend = new FormData();
            formDataToAppend.append('file', formData.file);
            showLinearProgress(true)

            const retorno = await UCUploadEmployeeDocsService.run(formDataToAppend, signOutByExpiresProcess)
            //todo: verificar retorno da variavel retorno e dar throw de erro
            if (!retorno.ok) {
                const retornoMessage: any = await retorno.json()
                throw new Error(`Erro ao realizar upload do arquivo: ${retornoMessage.error}`)
            }

            const fileUploadedData = await retorno.json()
            formData.documentGuid = fileUploadedData.name
            formData.documentUrl = fileUploadedData.url

            const response = await UCGravarEmployeeDocsService.run(formData, signOutByExpiresProcess)

            if (response.ok) {
                const retorno = await response.json()
                setNotifyNewDoc(retorno.id)
                alerto('Upload de arquivo', 'Registro criado com sucesso')
            }
            else {
                const error = await response.json()
                let message = error.error.message || error.error
                alerto('Erro', message)
            }

            // Limpar o formulário após a submissão, se necessário
            const entityId = params.get('entityId');
            setFormData({ ...initialFormData, entityId: Number(entityId) });

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }

            if (fileInputRefMob.current) {
                fileInputRefMob.current.value = '';
            }

            //re-setando o entityId para conseguir continuar inserindo documentos

            showLinearProgress(false)
            setbuttonDisabled(false)
        } catch (error: any) {
            showLinearProgress(false)
            let message = error.message || error.error || error?.error?.message
            alerto('Erro', message)
            setbuttonDisabled(false)
        }
    };

    useEffect(() => {
        const entityName = params.get('entityName');
        const entityId = params.get('entityId');
        setEntityName(String(entityName))
        setFormData({ ...formData, entityId: Number(entityId) })
    }, [])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData(({
            ...formData,
            [name]: value,
        }));
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {

        const files = event.target.files;

        //verifico se tem algum arquivo selecionado
        if (files && files.length > 0) {
            const _file = files[0]
            const modifiedFileName = removerCaracteresEspeciais(_file.name);
            const modifiedFile = new File([_file], modifiedFileName, { type: _file.type });

            const _fileSize = _file.size
            const _fileSizeLimit = 10 * 1024 * 1024;

            if (_fileSize > _fileSizeLimit) {
                alerto('Atenção', 'Tamanho excede o limite de 10 MB')
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }

                if (fileInputRefMob.current) {
                    fileInputRefMob.current.value = '';
                }

                return
            }

            setFormData(({
                ...formData,
                file: modifiedFile,
                file_size: _fileSize / 1024
            }));
        }
    };

    const removerCaracteresEspeciais = (texto: string): string => {
        // Remover acentos, cedilha, til e outros caracteres especiais
        const textoSemAcentos = texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        // Remover caracteres especiais, exceto alfanuméricos, espaços, hífens e pontos
        return textoSemAcentos.replace(/[^\w\s.-]/gi, '');
    }

    const handleDateChange = (date: Date | null) => {

        setFormData(({
            ...formData,
            dateToWarn: date,
        }));
    };


    return (
        <>
            <Desktop>
                <Paper elevation={24} style={{ padding: '20px', margin: '30px auto', maxWidth: '1200px' }} >
                    <h2>
                        Cadastro de Documentos de : {_entityName}
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <DatePicker
                                    name='dateToWarn'
                                    value={formData.dateToWarn}
                                    onChange={handleDateChange}
                                    sx={{ width: '100%' }} label="Data de Vencimento do Documento" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Nome Lógico do Arquivo"
                                    fullWidth
                                    name="documentName"
                                    value={formData.documentName}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <h4>Arquivo para upload</h4>
                                <Input
                                    name='file'
                                    type="file"
                                    inputRef={fileInputRef}
                                    inputProps={{
                                        accept: '.pdf, .doc, .docx',
                                    }}

                                    onChange={handleFileChange}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ margin: '15px 0 0 0' }}>
                                <TextField

                                    label="Descrição do Documento"
                                    fullWidth
                                    name="documentDescription"
                                    value={formData.documentDescription}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Paper                                    
                                    elevation={24} style={{ padding: '10px', margin: '3px auto', maxWidth: '1200px', minWidth: '300px' }}>
                                <h4>Atenção!</h4>
                                    <p> <span className='destaque'> Não</span> utilize arquivos com nomes com caracteres especiais como (<span className='destaque'>`'~^ç@#$%&</span>), (<span className='destaque'>hifen</span>), (<span className='destaque'>virgula</span>), etc... </p>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    disabled={buttonDisabled}
                                    type="submit" variant="outlined" color="inherit" style={{ marginTop: '5px', marginRight: '10px' }}>
                                    Gravar
                                </Button>
                                <Button variant="outlined" color="inherit" style={{ marginTop: '5px' }} >
                                    Cancelar
                                </Button>
                            </Grid>
                          
                        </Grid>
                    </form>
                </Paper>
            </Desktop>
            <Mobile>
                <Paper elevation={24} style={{ padding: '20px', margin: '30px auto', maxWidth: '1200px' }} >
                    <h2>
                        Cadastro de Documentos de : {_entityName}
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <DatePicker
                                    name='dateToWarn'
                                    value={formData.dateToWarn}
                                    onChange={handleDateChange}
                                    sx={{ width: '100%' }} label="Data de Vencimento do Documento" />
                            </Grid>


                            <Grid item xs={12}>
                                <TextField
                                    label="Nome Lógico do Arquivo"
                                    fullWidth
                                    name="documentName"
                                    value={formData.documentName}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h4>Arquivo para upload</h4>
                                <Input
                                    name='file'
                                    type="file"
                                    inputRef={fileInputRefMob}
                                    inputProps={{
                                        accept: '.pdf, .doc, .docx',
                                    }}

                                    onChange={handleFileChange}
                                />
                            </Grid>

                            <Grid item xs={12} sx={{ margin: '15px 0 0 0' }}>
                                <TextField
                                    label="Descrição do Documento"
                                    fullWidth
                                    name="documentDescription"
                                    value={formData.documentDescription}
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Paper elevation={24} style={{ padding: '10px', margin: '3px auto', maxWidth: '1200px', minWidth: '300px' }}>
                                    <h4>Atenção!</h4>
                                    <p> <span className='destaque'>Não</span> utilize arquivos com nomes com caracteres especiais como (<span className='destaque'>`'~^ç@#$%&</span>), (<span className='destaque'>hifen</span>), (<span className='destaque'>virgula</span>), etc... </p>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="text" color="inherit" style={{ marginTop: '20px', marginRight: '10px' }}>
                                    Gravar
                                </Button>
                                <Button variant="text" color="inherit" style={{ marginTop: '20px' }} >
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Mobile>

        </>
    );
};

export default DocumentForm;
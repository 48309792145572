import { RequisicaoDocumento } from ".."
import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher2 } from "../../../services/fetcher.service"

const run = async (idToDelete: number, callback: any) => {

    const url = `${URI_BASE}/require-documents/delete-by-id/${idToDelete}`

    return await fetcher2(url, 'DELETE', null, callback)
}

const UCRemoverRequisicaoDocumentoService = {
    run
}

export default UCRemoverRequisicaoDocumentoService
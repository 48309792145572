import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher } from "../../../services/fetcher.service"

const run = async (id: string, name?: string, email?: string, password?: string, roleId?: number, active?: boolean, plano?: string, emailSecundary?: string
) => {

    if (!id) throw Error("Id não identificado")
    let userData: any = {}
    if (name) userData.name = name
    if (email) userData.email = email
    if (password) userData.password = password
    if (roleId) userData.roles = [{ id: roleId }]
    if (plano) userData.plano = plano
    if (emailSecundary) userData.emailSecundary = emailSecundary
    userData.active = active

    const url = `${URI_BASE}/usuario/${id}`

    const retorno = await fetcher<Number>(url, 'PUT', userData)

    return retorno
}

const UCAtualizarUsuarioService = {
    run
}

export default UCAtualizarUsuarioService

import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher, fetcher2 } from "../../../services/fetcher.service"
import { FormState } from "../components/contabilidade-desktop"

const run = async (formState: FormState, callback: any) => {
    const formPersistance = {
        "name": formState.contabilidade,
        "email": formState.email,
        "phoneNumber": formState.telefone,
        "contact": formState.contato,
        "contactEmail": formState.emailContato,
        "contactPhone": formState.telefoneContato,
        "userId": formState.userId,
        "personType": formState.tipoPessoa,
        "document": formState.documento,
        "entityTypeId": formState.entityTypeId
    }
    const url = `${URI_BASE}/entity/`

    return await fetcher2(url, 'POST', formPersistance,callback)
}

const UCGravarEntidadeContabilidadeService = {
    run
}

export default UCGravarEntidadeContabilidadeService

import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher2 } from "../../../services/fetcher.service"

const run = async (entityId: number, callback: any) => {
    const url = `${URI_BASE}/require-documents/not-filled/${entityId}`

    return await fetcher2(url, 'GET', null, callback)
}

const UCGetRequiredDocsService = {
    run
}

export default UCGetRequiredDocsService
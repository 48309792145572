import React, { useEffect, useState } from "react";
import { Avatar, Badge, Box, Button, Container, Divider, IconButton, ListItemIcon, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MenuIcon from '@mui/icons-material/Menu';
import { Menu } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom'; // Importe o useHistory do React Router
import { useActionContext } from "../../hooks/actions";
import { useAuth } from "../../hooks/auth";
import { Containerd, StyledAppBar } from "./style";
import { checkRolesOnUserLogged } from "../../services/checkRoles/check-roles.service";
import { UserData, getUserData } from "../../services/storage.service";
import { VerifiedUser, Settings, Logout } from "@mui/icons-material";
import ModalUpdateUser from "./components/modal-update-user";
import SupportAgentSharpIcon from '@mui/icons-material/SupportAgentSharp';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import UCRetornarNotFilledRequisicaoDocumentoByClientIdService from "./use-cases/uc-consultarNotFilledPorClientID-requisicao-documento";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailIcon from '@mui/icons-material/Mail';

import ForumIcon from '@mui/icons-material/Forum';
import { ROTA_LOGIN } from "../../constants/uri/constants.uri";

const MenuResponsivo: React.FC = () => {

    const { signOut, handleOpen, setTitle, setDialogContextText } = useAuth()
    const {
        setCadastroUsuarioFormData,
        setPathName,
        docs_count
    } = useActionContext()

    const alerto = (title: string, content: string) => {
        handleOpen(true)
        setTitle(title)
        setDialogContextText(content)
    }

    const navigate = useNavigate()
    const uc: UserData = getUserData()

    const isMaster: boolean = checkRolesOnUserLogged(['master'])
    const isAdmin: boolean = checkRolesOnUserLogged(['admin'])
    const isFuncionario: boolean = checkRolesOnUserLogged(['funcionario'])

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [showModal, setShowModal] = React.useState<boolean>(false);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleEditUserData = async () => {
        setShowModal(true)
        const uc = getUserData()
    }

    const retrieveNotFilledRequireDocuments = async () => {
        //UCRetornarNotFilledRequisicaoDocumentoByClientIdService.run()
    }


    useEffect(() => {
    }, []);

    return (
        <Containerd>
            <StyledAppBar position="static" elevation={10} >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <MenuBookIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            GuardaLivros
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}

                                color="secondary"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {isMaster && (
                                    <MenuItem key={'Master'} onClick={() => {
                                        navigate('/')
                                        setPathName('/')
                                        handleCloseNavMenu()
                                    }}>
                                        <Typography textAlign="center"> Master </Typography>
                                    </MenuItem>
                                )
                                }

                                {isAdmin && (<MenuItem key={'Contabilidades'} onClick={() => {
                                    navigate('/')
                                    setPathName('/')
                                    handleCloseNavMenu()
                                }}>
                                    <Typography textAlign="center"> Contabilidades </Typography>
                                </MenuItem>)}
                                {isAdmin && (<MenuItem key={'Clientes'} onClick={() => {
                                    
                                    navigate('/clientes')
                                    setPathName('/clientes')
                                    handleCloseNavMenu()
                                }}>
                                    <Typography textAlign="center"> Clientes </Typography>
                                </MenuItem>)}
                                {isAdmin && (<MenuItem key={'Plano'} onClick={() => {
                                    navigate('/plano')
                                    setPathName('/plano')
                                    handleCloseNavMenu()
                                }}>
                                    <Typography textAlign="center"> Plano </Typography>
                                </MenuItem>)}

                                {isFuncionario && (<MenuItem key={'Documentos'} onClick={() => {
                                    navigate('/')
                                    setPathName('/')
                                    handleCloseNavMenu()
                                }}>
                                    <Typography textAlign="center"> Documentos</Typography>
                                </MenuItem>)}
                                {isFuncionario && (<MenuItem key={'Comunicacao'} onClick={() => {
                                    navigate('/com')
                                    setPathName('/com')
                                    handleCloseNavMenu()
                                }}>
                                    <Typography textAlign="center"> Comunicação ({docs_count})</Typography>
                                </MenuItem>)}
                                
                          
                            </Menu>
                        </Box>
                        <MenuBookIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            GuardaLivros
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                            {isMaster && (<Button
                                key={'Master'}
                                onClick={() => {
                                    navigate('/')
                                    setPathName('/')
                                    handleCloseNavMenu()
                                }}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Master
                            </Button>)}

                            {isAdmin && (<Button
                                key={'Contabilidades'}
                                onClick={() => {
                                    navigate('/')
                                    setPathName('/')
                                    handleCloseNavMenu()
                                }}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Contabilidades
                            </Button>)}
                            {isAdmin && (<Button
                                key={'Clientes'}
                                onClick={() => {
                                    
                                    navigate('/clientes')
                                    setPathName('/clientes')
                                    handleCloseNavMenu()
                                }}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Clientes
                            </Button>)}
                            {isAdmin && (<Button
                                key={'Plano'}
                                onClick={() => {
                                    navigate('/plano')
                                    setPathName('/plano')
                                    handleCloseNavMenu()
                                }}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Plano
                            </Button>)}
                            {isFuncionario && (<Button
                                key={'/'}
                                onClick={() => {
                                    navigate('/')
                                    setPathName('/')
                                    handleCloseNavMenu()
                                }}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Documentos
                            </Button>)}
                            {isFuncionario && (<Button
                                key={'Com'}
                                onClick={() => {
                                    navigate('/com')
                                    setPathName('/com')
                                    handleCloseNavMenu()
                                }}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                <span>
                                    Comunicação
                                </span>
                                <Badge badgeContent={docs_count} color="info">
                                    <ForumIcon color="inherit" />
                                </Badge>
                            </Button>)}
                           
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Configurações">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="G" src="/static/images/avatar/2.jpg" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem>
                                    <ListItemIcon>
                                        <VerifiedUser fontSize="small" />
                                    </ListItemIcon>
                                    <Typography textAlign="center">
                                        Olá {uc.username.toUpperCase()}
                                    </Typography>
                                </MenuItem>
                                <Divider />
                                <MenuItem key="config"
                                    onClick={() => {
                                        handleEditUserData()
                                    }}
                                >
                                    <ListItemIcon>
                                        <Settings fontSize="small" />
                                    </ListItemIcon>
                                    <Typography textAlign="center">Configurações</Typography>
                                </MenuItem>

                                <MenuItem key="SuporteEmail"
                                    onClick={() => {
                                        alerto('Email', 'suporte@oguardalivros.com')
                                    }}
                                >
                                    <ListItemIcon>
                                        <MailIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography textAlign="center">Suporte Email</Typography>
                                </MenuItem>

                                <MenuItem key="SuporteZap"
                                    onClick={() => {
                                        window.open('https://wa.me/5513997784036','_blank')                                        
                                    }}
                                >
                                    <ListItemIcon>
                                        <WhatsAppIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography textAlign="center">Suporte WhatsApp</Typography>
                                </MenuItem>                                

                                {/* <MenuItem key="Termos"
                                    onClick={async () => {
                                        const filename = window.location.href+ "TermosUso.pdf";
                                        const response = await fetch(filename);
                                        const pdfBlob = await response.blob();
                                        

                                        if (pdfBlob) {                                             
                                            const link = document.createElement('a');
                                            link.href = window.URL.createObjectURL(pdfBlob);
                                            link.download = '99999'
            
                                            // Simule um clique no link para iniciar o download
                                            link.click();
            
                                         }
                                        
                                        
                                        
                                    }}
                                >

                                    <ListItemIcon>
                                        <AssignmentTurnedInIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography textAlign="center">Termos</Typography>
                                </MenuItem> */}


                                <MenuItem key='Logout' onClick={
                                    () => {
                                        handleCloseUserMenu()
                                        signOut()
                                        setCadastroUsuarioFormData({
                                            active: false,
                                            email: '',
                                            name: '',
                                            password: '',
                                            plano: '',
                                            roles: '',
                                            id: ''
                                        })
                                        navigate(ROTA_LOGIN)
                                        setPathName('/')
                                    }
                                }>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    <Typography textAlign="center">Logout</Typography>
                                </MenuItem>


                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </StyledAppBar>
            <ModalUpdateUser
                open={showModal}
                title="Atualizar Dados"
                onClose={() => {
                    setShowModal(false)
                }}
            />
        </Containerd>
    )
}

export default MenuResponsivo;
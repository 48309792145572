import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher } from "../../../services/fetcher.service"

const run = async (id: string) => {

    if (!id) throw Error("Id não identificado")
    
    const url = `${URI_BASE}/usuario/${id}`

    const retorno = await fetcher<Number>(url, 'DELETE', null)

    return retorno
}

const UCRemoverUsuarioService = {
    run
}

export default UCRemoverUsuarioService
import { RequisicaoDocumento } from ".."
import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher2 } from "../../../services/fetcher.service"

const run = async (formState: RequisicaoDocumento, callback: any) => {
    const formBody = {
        id:formState.id,
        documentRequireName: formState.requisicaoLabel,
        documentRequireDescription: formState.requisicaoMotivoDescricao,
        fill: formState.fill
    }

    const url = `${URI_BASE}/require-documents`

    return await fetcher2(url, 'PUT', formBody, callback)
}

const UCAtualizarRequisicaoDocumentoService = {
    run
}

export default UCAtualizarRequisicaoDocumentoService
import React, { useRef, useEffect } from 'react';
import { InputHTMLAttributes } from 'react';

import { Container } from './styles';

type IInputProps = InputHTMLAttributes<HTMLInputElement> & {
  focused?: boolean;
};

const Input: React.FC<IInputProps> = ({ focused, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Se a propriedade focused for verdadeira, defina o foco no input
    if (focused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focused]); // Certifique-se de passar a propriedade focused como dependência do useEffect

  return <Container ref={inputRef} {...rest} />;
};

export default Input;

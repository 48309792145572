
import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher2 } from "../../../services/fetcher.service"

const run = async (entityId: number, favorite: boolean, callback: any) => {
    const url = `${URI_BASE}/employee-documents/${entityId}`
    const formData = {
        favorite
    }

    return await fetcher2(url, 'PUT', formData, callback)
}

const UCFavoriteEmployeeDocumento = {
    run
}

export default UCFavoriteEmployeeDocumento
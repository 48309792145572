export default {
    title: 'light',
    color: {
        primary: '#DCDCDC',
        secondary: '#FFFFFF',
        tertiary: '#F5F5F5',
        white: '#FFFFFF',
        black: '#000000',
        gray: '#BFBFBF',
        success: '#03BB85',
        info: '#3B5998',
        warning: '#FF6961'
    },
    palette: {
        primary: {
            main: '#007bff',
        },
        secondary: {
            main: '#ff5722',
        },
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
    },
    dataGrid: {
        headerBackgroundColor: '#333',
        rowBackgroundColor: '#f5f5f5',
    }
}
import React, { useEffect, useState } from 'react';
import { Containerd, Desktop, Mobile } from './styles';
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import { UserData, getUserData } from '../../../../services/storage.service';
import UCGravarEntidadeContabilidadeService from '../../useCases/uc-gravar-entidade-contabilidade';
import { useAuth } from '../../../../hooks/auth';
import { useActionContext } from '../../../../hooks/actions';
import UCAtualizarEntidadeContabilidadeService from '../../useCases/uc-editar-entidade-contabilidade';
import UCConsultarEntidadesByUserIdService from '../../useCases/uc-consultar-entidades-contabilidade';
import errorMessageService from '../../../../services/errorMessage.service';


type IContabilidadesDesktopProps = {
    onEdit: () => any
}

export interface FormState {
    id?: number,
    contabilidade: string;
    email?: string;
    telefone?: string;
    contato?: string;
    emailContato: string;
    telefoneContato: string;
    tipoPessoa: string;
    documento: string;
    userId: number;
    entityTypeId: number
}

const ContabilidadesDesktop: React.FC<IContabilidadesDesktopProps> = ({ onEdit }) => {

    const alerto = (title: string, content: string) => {
        handleOpen(true)
        setTitle(title)
        setDialogContextText(content)
    }



    const { handleOpen, setTitle, setDialogContextText, signOut } = useAuth();
    const {
        showLinearProgress,
    } = useActionContext()

    const userData: UserData = getUserData()

    const [buttonActionName, setButtonActionName] = useState<string>('Cadastrar')
    const [documentType, setdocumentType] = useState<string>('cpf')
    const [isDisabledForCreateNew, setIsDisabledForCreateNew] = useState<boolean>(false);


    // Use o estado para rastrear os valores do formulário
    const [formData, setFormData] = useState<FormState>({
        id: 0,
        contabilidade: '',
        email: '',
        telefone: '',
        contato: '',
        emailContato: '',
        telefoneContato: '',
        tipoPessoa: '',
        documento: '',
        userId: Number(userData.sub),
        entityTypeId: 1//sempre 1 pq é Contabilidade
    });

    const clearForm = () => {
        setFormData({
            id: 0,
            contabilidade: '',
            email: '',
            telefone: '',
            contato: '',
            emailContato: '',
            telefoneContato: '',
            tipoPessoa: '',
            documento: '',
            userId: Number(userData.sub),
            entityTypeId: 1//sempre 1 pq é Contabilidade
        })
        setButtonActionName('Cadastrar')
    }


    const formatarCpf = (cpf: string) => {
        // Remove caracteres não numéricos
        cpf = cpf.replace(/\D/g, '');

        // Adiciona os pontos e traço
        cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

        return cpf.substring(0, 14);
    }

    const formatarCnpj = (cnpj: string) => {
        cnpj = cnpj.replace(/\D/g, '');
        cnpj = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
        return cnpj.substring(0, 18);
    }

    // Lidar com a mudança nos campos do formulário
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData(({
            ...formData,
            [name]: value,
        }));
    };


    const handleInputChangeCnpj = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData(({
            ...formData,
            [name]: formatarCnpj(value),
        }));
    };

    const handleInputChangeCpf = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData(({
            ...formData,
            [name]: formatarCpf(value),
        }));
    };

    // Lidar com o envio do formulário
    const handleSubmit = async (e: React.FormEvent) => {
        showLinearProgress(true)
        e.preventDefault();

        let msg = ``

        if (buttonActionName === 'Cadastrar') {

            const response: any = await UCGravarEntidadeContabilidadeService.run(formData, signOut)
            if (response.ok) {
                msg = 'Contabilidade criada com sucesso'
                setTitle('Sucesso')
                alerto('Sucesso', msg)
                clearForm()
            } else {
                const err = await response.json()
                msg = err.error.message || err.error

                if (err?.error?.message) {
                    if (Array.isArray(err.error.message)) {
                        msg = err.error.message.join(' - ')
                    }
                }
                errorMessageService(msg, response, alerto)
                //alerto('Erro', msg)
            }
        } else {
            const response: any = await UCAtualizarEntidadeContabilidadeService.run(formData, signOut)
            if (response.ok) {
                msg = 'Contabilidade atualizada com sucesso'
                setTitle('Sucesso')
                alerto('Sucesso', msg)
                clearForm()
            } else {
                const err = await response.json()
                msg = err.error.message || err.error
                errorMessageService(msg, response, alerto)
                //alerto('Erro', msg)
            }
        }
        showLinearProgress(false)
        setIsDisabledForCreateNew(true)
    };

    const loadContabilidades = async () => {
        const response: any = await UCConsultarEntidadesByUserIdService.run(signOut)
        let isAlreadyCreated: boolean = false
        if (response.ok) {
            const contabilidades = await response.json()
            //ja tem Contabilidade criada?
            isAlreadyCreated = buttonActionName === "Cadastrar" && contabilidades.length > 0;
        }
        else {
            const error = await response.json()
            let message = error.error.message || error.error
            errorMessageService(message, response, alerto)            
            return 0
        }
        return isAlreadyCreated
    }



    useEffect(() => {
        showLinearProgress(true)
        loadContabilidades()
            .then((isAlreadyCreated: any) => {
                const val = onEdit()
                if (val.userId) {
                    setButtonActionName('Editar')
                    setFormData({
                        "id": val.id,
                        contabilidade: val.name,
                        "email": val.email,
                        telefone: val.phoneNumber,
                        contato: val.contact,
                        emailContato: val.contactEmail,
                        telefoneContato: val.contactPhone,
                        tipoPessoa: val.personType,
                        documento: val.document,
                        "userId": val.userId,
                        "entityTypeId": val.entityTypeId
                    })

                    if (val.personType === 'PF') {
                        setdocumentType('PF')
                    } else {
                        setdocumentType('PJ')
                    }
                } else {
                    setIsDisabledForCreateNew(isAlreadyCreated)
                }

            })
            .catch((error: any) => {
                let message = error.error.message || error.error
                alerto('Erro', message)
            })
            .finally(() => {
                showLinearProgress(false)
            })
    }, [isDisabledForCreateNew])



    return (

        <Containerd>
            <Desktop>
                <Paper elevation={24} style={{ padding: '20px', margin: '30px auto', maxWidth: '1200px' }} >
                    <h2>
                        Cadastre sua Contabilidade
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <TextField
                                    label="Contabilidade"
                                    fullWidth
                                    name="contabilidade"
                                    value={formData.contabilidade}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type='email'
                                    label="Email"
                                    fullWidth
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type='tel'
                                    label="Telefone"
                                    fullWidth
                                    name="telefone"
                                    value={formData.telefone}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Contato"
                                    fullWidth
                                    name="contato"
                                    value={formData.contato}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Email Contato"
                                    fullWidth
                                    name="emailContato"
                                    value={formData.emailContato}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Telefone Contato"
                                    fullWidth
                                    name="telefoneContato"
                                    value={formData.telefoneContato}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth style={{ margin: '0 30px 0 0' }}>
                                    <InputLabel id="tipoPessoa-label">Tipo Pessoa</InputLabel>
                                    <Select
                                        required
                                        labelId="tipoPessoa-label"
                                        id="tipoPessoa"
                                        name="tipoPessoa"
                                        value={formData.tipoPessoa}
                                        onChange={(e: any) => {
                                            setFormData({ ...formData, tipoPessoa: e.target.value })
                                            if (e.target.value === 'PF') {
                                                setdocumentType('PF')
                                            } else {
                                                setdocumentType('PJ')
                                            }
                                        }}
                                    >
                                        <MenuItem value="PF">Física</MenuItem>
                                        <MenuItem value="PJ">Jurídica</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                {documentType === 'PF' && (<TextField
                                    label="CPF"
                                    fullWidth
                                    name="documento"
                                    value={formData.documento}
                                    onChange={handleInputChangeCpf}
                                    inputProps={{ maxLength: 14 }}

                                />)}
                                {documentType === 'PJ' && (<TextField
                                    label="CNPJ"
                                    fullWidth
                                    name="documento"
                                    value={formData.documento}
                                    onChange={handleInputChangeCnpj}
                                    inputProps={{ maxLength: 18 }}
                                />)}
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    disabled={isDisabledForCreateNew}
                                    type="submit" variant="text" color="inherit" style={{ marginTop: '20px', marginRight: '10px' }}>
                                    {buttonActionName}
                                </Button>
                                <Button variant="text" color="inherit" style={{ marginTop: '20px' }} onClick={clearForm} >
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Desktop>
            <Mobile>
                <Paper elevation={24} style={{ padding: '20px', margin: '30px auto', maxWidth: '1200px' }} >
                    <h2>
                        Cadastro de Contabilidade
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Contabilidade"
                                    fullWidth
                                    name="contabilidade"
                                    value={formData.contabilidade}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type='email'
                                    label="Email"
                                    fullWidth
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type='tel'
                                    label="Telefone"
                                    fullWidth
                                    name="telefone"
                                    value={formData.telefone}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Contato"
                                    fullWidth
                                    name="contato"
                                    value={formData.contato}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Email Contato"
                                    fullWidth
                                    name="emailContato"
                                    value={formData.emailContato}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Telefone Contato"
                                    fullWidth
                                    name="telefoneContato"
                                    value={formData.telefoneContato}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth style={{ margin: '0 30px 0 0' }}>
                                    <InputLabel id="tipoPessoa-label">Tipo Pessoa</InputLabel>
                                    <Select
                                        required
                                        labelId="tipoPessoa-label"
                                        id="tipoPessoa"
                                        name="tipoPessoa"
                                        value={formData.tipoPessoa}
                                        onChange={(e: any) => {
                                            setFormData({ ...formData, tipoPessoa: e.target.value, documento: '' })
                                            if (e.target.value === 'PF') {
                                                setdocumentType('PF')
                                            } else {
                                                setdocumentType('PJ')
                                            }
                                        }}
                                    >
                                        <MenuItem value="PF">Física</MenuItem>
                                        <MenuItem value="PJ">Jurídica</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                {documentType === 'PF' && (<TextField
                                    label="CPF"
                                    fullWidth
                                    name="documento"
                                    value={formData.documento}
                                    onChange={handleInputChangeCpf}
                                    inputProps={{ maxLength: 14 }}

                                />)}
                                {documentType === 'PJ' && (<TextField
                                    label="CNPJ"
                                    fullWidth
                                    name="documento"
                                    value={formData.documento}
                                    onChange={handleInputChangeCnpj}
                                    inputProps={{ maxLength: 18 }}
                                />)}
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    disabled={isDisabledForCreateNew}
                                    type="submit" variant="text" color="inherit" style={{ marginTop: '20px', marginRight: '10px' }}>
                                    {buttonActionName}
                                </Button>
                                <Button variant="text" color="inherit" style={{ marginTop: '20px' }} onClick={clearForm} >
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Mobile>
        </Containerd>

    )
}

export default ContabilidadesDesktop;

import { AppBar } from "@mui/material";
import styled from "styled-components";


export const Containerd = styled.div`
    
    
    .MuiButtonBase-root{
        span {
            display:'display:';
            margin:'15px';            
        }
    }

    
    
   

`;

export const StyledAppBar = styled(AppBar)`
     background-color:${props => props.theme.color.info} !important;
     /* style={{ background: '#DCDCDC',color:'black' }}  */
    .css-1q39md6-MuiButtonBase-root-MuiButton-root {
        color:${props => props.theme.color.white};
    }  

    .MuiTypography-root{
        color:${props => props.theme.color.white};
    }
`;
import React, { useEffect, useState } from 'react';
import { Containerd, GridoContainer } from './styles';
import ClientesDesktop from './components/clientes-desktop';
import { GridColDef } from '@mui/x-data-grid';
import { Button, Paper, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useActionContext } from '../../hooks/actions';
import { useAuth } from '../../hooks/auth';
import Grido2 from '../../components/Grido2';
import UCConsultarEntidadeClientesByUserIdService from './useCases/uc-consultar-entidade-cliente';
import QuestionDialog from '../../components/QuestionDialog';
import UCRemoverEntidadeContabilidadeService from './useCases/uc-deletar-entidade-cliente';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import BottomNavigationCtrl from '../../components/CadastroUsuario/components/bottom-navigation';
import { useNavigate } from 'react-router-dom';
import ModalUpload from './components/modal-upload';
import UCRemoverUsuarioService from '../Master/useCases/uc-remover-usuario.service';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { ROTA_DOCUMENTOS_ENTIDADE, ROTA_LOGIN, ROTA_REQUISICAO_DOCUMENTO } from '../../constants/uri/constants.uri';
import errorMessageService from '../../services/errorMessage.service';

const Clientes: React.FC = () => {

    const navigate = useNavigate()

    const [value, setValue] = React.useState<number>(0);

    const [isModalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const {
        setOpenDialog,
        setOpenDialogText,
        setOpenDialogQuestionContext,
        show_cadCli,
        showLinearProgress,
        setShowCadCli,
        handleCloseQuestionDialog,
        setPathName,
        setShowFormCadUser
    } = useActionContext();
    

    const {
        setDialogContextText,
        setTitle,
        handleOpen,
        signOut
    } = useAuth()

    const [estado, setEstado] = useState<number>(0);
    const [clientesRows, setClientesRows] = useState<any[]>([]);

    const [idToDelete, setIdToDelete] = useState<number>(0);
    const [idToDeleteUser, setIdToDeleteUser] = useState<number>(0);

    const [rowToEdit, setRowToEdit] = useState<any>({});

    const alerto = (title: string, content: string) => {
        handleOpen(true)
        setTitle(title)
        setDialogContextText(content)
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 1 },
        { field: 'name', headerName: 'Contabilidade', width: 180 },
        { field: 'email', headerName: 'Email', width: 180 },
        { field: 'phoneNumber', headerName: 'Tel.', width: 110 },
        {
            field: 'contact', headerName: 'Contato', width: 120,
        },
        {
            field: 'contactEmail', headerName: 'Email Cont.', width: 180,
        },
        { field: 'contactPhone', headerName: 'Tel. Contato', width: 110 },
        { field: 'personType', headerName: 'T. Pessoa', width: 130, align: 'center' },
        { field: 'document', headerName: 'Documento', width: 150 },
        {
            field: 'actions',
            headerName: 'Ações',
            width: 250,
            headerAlign: 'left',
            renderCell: (params) => (
                <div>
                    <Button startIcon={
                        <Tooltip title='Editar'>
                            <EditIcon />
                        </Tooltip>
                    } onClick={() => {                        
                        setRowToEdit(params.row)
                        setShowCadCli(true)
                        setValue(0)
                    }}>
                    </Button>

                    <Button startIcon={
                        <Tooltip title='Remover'>
                            <DeleteIcon />
                        </Tooltip>
                    }
                        onClick={() => {
                            setOpenDialog(true)
                            setOpenDialogText('Remoção de Usuário')
                            setOpenDialogQuestionContext(`Deseja remover o registro ${params.row.name} - id (${params.row.id})?`)
                            setIdToDelete(Number(params.row.id))
                            setIdToDeleteUser(Number(params.row.userIdFuncionario))
                        }}>
                    </Button>
                    <Button startIcon={
                        <Tooltip title='Upload de arquivo'>
                            <UploadFileIcon />
                        </Tooltip>
                    } onClick={() => {
                        navigate(`${ROTA_DOCUMENTOS_ENTIDADE}?entityName=${params.row.name}&entityId=${params.row.id}`)
                    }}>
                    </Button>
                    <Button startIcon={
                        <Tooltip title='Requisitar Documento'>
                            <QuestionAnswerIcon />
                        </Tooltip>
                    } onClick={() => {
                        
                        const requisicaoDocumento = { clientId: params.row.id, entityId: params.row.entityId }
                        localStorage.setItem('requisicaoDocumento', JSON.stringify(requisicaoDocumento))
                        navigate(`${ROTA_REQUISICAO_DOCUMENTO}?entityName=${params.row.name}`)
                        setPathName(ROTA_REQUISICAO_DOCUMENTO)
                        setShowCadCli(true)
                    }}>
                    </Button>

                </div>
            ),
        }
    ]

    const signOutByExpiresProcess = () => {
        signOut();
        navigate(ROTA_LOGIN)
    }

    const loadClientes = async () => {
        showLinearProgress(true)
        const response: any = await UCConsultarEntidadeClientesByUserIdService.run(signOutByExpiresProcess)
        if (response.ok) {
            const clientes = await response.json()
            setClientesRows(clientes)
        }
        else {
            const error = await response.json()
            let message = error.error.message || error.error
            await errorMessageService(message, response, alerto)                        
        }
        showLinearProgress(false)
    }

    useEffect(() => {
        loadClientes()
    }, [estado])

    return (
        <Containerd>
            <QuestionDialog
                handleClose={handleCloseQuestionDialog}
                handleConfirm={async () => {

                    if (idToDelete > 0) {
                        showLinearProgress(true)
                        const response = await UCRemoverEntidadeContabilidadeService.run(idToDelete, signOutByExpiresProcess)
                        if (response.ok) {

                            //todo: remover usuario do cliente, caso tenha
                            const responseFromUserRemoveAction = await UCRemoverUsuarioService.run(idToDeleteUser.toString())
                            if (responseFromUserRemoveAction !== 1) {
                                alerto('Erro', 'Exclusão do cliente falhou ao remover usuário do Cliente, contato o administrador')
                                setEstado(estado + 1)
                                showLinearProgress(false)
                                handleCloseQuestionDialog()
                                return
                            }

                            alerto('Sucesso', 'Registro removido com sucesso')
                            setEstado(estado + 1)
                        } else {
                            const error = await response.json()
                            let message = error.error.message || error.error                            
                            errorMessageService(message, response, alerto)
                        }
                    }
                    showLinearProgress(false)
                    handleCloseQuestionDialog()
                }}
            ></QuestionDialog>

            {show_cadCli && (<ClientesDesktop
                onEdit={() => {
                    setRowToEdit({})
                    return rowToEdit
                }}
            />)}
            <GridoContainer>
                {!show_cadCli && (<Paper elevation={24} style={{ padding: '20px', margin: '30px auto' }}>
                    <h2>
                        Cadastro de Clientes
                    </h2>
                    <Grido2
                        height={600}
                        onLoad={() => {
                            loadClientes()
                        }}
                        pageSize={10}
                        _columns={columns}
                        _rows={clientesRows}
                        onClick={() => { }}
                    />
                </Paper>)}
            </GridoContainer>

            <ModalUpload
                open={isModalOpen}
                onClose={handleCloseModal}
                title='po'
            />


            <BottomNavigationCtrl
                labelCadastro='Cad. de Clientes'
                labelGrid='Listar Clientes'
                setShowCadastro={() => { setShowCadCli(true) }}
                setHideCadastro={() => { setShowCadCli(false) }}
                value={value}
                onSetValue={setValue}
            />
        </Containerd >
    );
}

export default Clientes
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/auth';
import { useActionContext } from '../../hooks/actions';
import { TextField, Container, Button, Paper, Grid, FormControl, InputLabel, Select, MenuItem, Switch, FormControlLabel } from '@mui/material';
import { MENSAGEM_NAO_AUTORIZADO } from '../../hooks/constants/friendly-messages.constants';
import UCCadastrarUsuarioService from '../../views/Master/useCases/uc-cadastrar-usuario.service';
import { ERRO, REGISTRO_ATUALIZADO, REGISTRO_INSERIDO, SUCESSO } from '../../views/Master/useCases/constants/admin-message.constants';
import UCAtualizarUsuarioService from '../../views/Master/useCases/uc-atualizar-usuario.service';
import { DEFAULT_PASSWORD } from '../../hooks/constants/constants';


type ICadastrarUsuario = {
    onHandleSubmit: (e: any) => void
    visiblePlanChoice: boolean
    visibleRole: boolean
    visibleActive: boolean
    visiblePassword: boolean
}

const CadastrarUsuario: React.FC<ICadastrarUsuario> = ({ onHandleSubmit, visiblePlanChoice, visibleRole, visibleActive, visiblePassword }) => {

    const { handleOpen, setTitle, setDialogContextText } = useAuth();

    const {
        showLinearProgress,
        cadastroUsuarioformData,
        setCadastroUsuarioFormData
    } = useActionContext()

    const [action, setAction] = useState<string>('Cadastrar');
    // const [emailSecundario, setEmailSecundario] = useState<string>('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {        
        const { name, value } = e.target;
        setCadastroUsuarioFormData({ ...cadastroUsuarioformData, [name]: value });
    };
    

    const handleSubmit = async (e: React.FormEvent) => {
        try {

            showLinearProgress(true)
            e.preventDefault();

            if (!cadastroUsuarioformData.id) {
                const retorno: any = await UCCadastrarUsuarioService.run(
                    cadastroUsuarioformData.name,
                    cadastroUsuarioformData.email,
                    cadastroUsuarioformData.password ? cadastroUsuarioformData.password : DEFAULT_PASSWORD,
                    Number(cadastroUsuarioformData.roles),
                    cadastroUsuarioformData.active,
                    cadastroUsuarioformData.plano,
                    cadastroUsuarioformData.emailSecundary)

                handleOpen(true)

                if (retorno.error) {
                    setTitle(ERRO)
                    if (retorno.error.statusCode === 401) {
                        setDialogContextText(MENSAGEM_NAO_AUTORIZADO)
                    }
                    else {
                        setDialogContextText(retorno.error.message.toString())
                    }
                } else {
                    setTitle(SUCESSO)
                    setDialogContextText(REGISTRO_INSERIDO)
                    onHandleSubmit(e)

                    setCadastroUsuarioFormData({
                        name: '',
                        email: '',
                        emailSecundary:'',
                        plano: '',
                        password: '',
                        roles: '', // Adicione um estado para a opção selecionada no Select
                        active: false
                    })
                }


            } else {

                const retorno: any = await UCAtualizarUsuarioService.run(
                    cadastroUsuarioformData.id,
                    cadastroUsuarioformData.name,
                    cadastroUsuarioformData.email,
                    cadastroUsuarioformData.password,
                    Number(cadastroUsuarioformData.roles),
                    cadastroUsuarioformData.active,
                    cadastroUsuarioformData.plano,
                    cadastroUsuarioformData.emailSecundary)

                handleOpen(true)

                if (retorno === 1) {
                    setTitle(SUCESSO)
                    setDialogContextText(REGISTRO_ATUALIZADO)
                    setAction('Cadastrar')
                    onHandleSubmit(e)

                    setCadastroUsuarioFormData({
                        name: '',
                        email: '',
                        plano: '',
                        password: '',
                        emailSecundary:'',
                        roles: '', // Adicione um estado para a opção selecionada no Select
                        active: false
                    })
                } else {
                    setTitle(ERRO)
                    if (typeof retorno.error === 'string') {
                        setDialogContextText(retorno.error)
                    }
                    else if (retorno.error.statusCode === 401) {
                        setDialogContextText(MENSAGEM_NAO_AUTORIZADO)
                    }
                    else {
                        setDialogContextText(retorno.error.message.toString())
                    }
                }


            }

            showLinearProgress(false)

        } catch (error: any) {
            handleOpen(true)
            setTitle(ERRO)
            setDialogContextText(error.message)
            showLinearProgress(false)
        }
    };

    useEffect(() => {
        if (cadastroUsuarioformData.id) {
            setAction('Atualizar')
        }
    }, [])

    return (
        <Container>
            <Paper elevation={24} style={{ padding: '20px', margin: '30px auto', maxWidth: '1200px' }}>
                <h2>{action} de Usuário</h2>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Nome"
                                type="text"
                                name="name"
                                value={cadastroUsuarioformData.name}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        {visiblePlanChoice && (<Grid item xs={12}>
                            <FormControl style={{ minWidth: '100%', margin: '0 30px 0 0' }}>
                                <InputLabel id="plano-label">Planos</InputLabel>
                                <Select
                                    required
                                    labelId="plano-label"
                                    id="plano"
                                    name="plano"
                                    value={cadastroUsuarioformData.plano}
                                    onChange={(e: any) => {
                                        setCadastroUsuarioFormData({ ...cadastroUsuarioformData, plano: e.target.value as string });
                                    }}
                                >
                                    <MenuItem value="1">Básico</MenuItem>
                                    <MenuItem value="2">Intermediário</MenuItem>
                                    <MenuItem value="3">Avançado</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>)}
                        {(visibleRole || visibleActive) && (<Grid item xs={12}>
                            {visibleRole && (<FormControl style={{ minWidth: '100px', margin: '0 30px 0 0' }}>
                                <InputLabel id="role-label">Perfil</InputLabel>
                                <Select
                                    required
                                    labelId="role-label"
                                    id="role"
                                    name="role"
                                    value={cadastroUsuarioformData.roles}
                                    onChange={(e: any) => {
                                        setCadastroUsuarioFormData({ ...cadastroUsuarioformData, roles: e.target.value as string });
                                    }}
                                >
                                    <MenuItem value="1">Master</MenuItem>
                                    <MenuItem value="2">Admin</MenuItem>
                                    <MenuItem value="3" disabled>Funcionario</MenuItem>
                                </Select>
                            </FormControl>)}
                            {visibleActive && (<FormControlLabel control={<Switch checked={cadastroUsuarioformData.active}
                                onChange={(e) => {
                                    setCadastroUsuarioFormData({ ...cadastroUsuarioformData, active: !cadastroUsuarioformData.active })
                                }}
                            />} label="Ativo?" />)}

                        </Grid>)}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email"
                                type="email"
                                name="email"
                                value={cadastroUsuarioformData.email}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email Secundario"
                                type="email"
                                name="emailSecundary"
                                value={cadastroUsuarioformData.emailSecundary}
                                onChange={handleChange}                                
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {!visiblePassword && (<InputLabel id="password-label">Senha: {DEFAULT_PASSWORD}</InputLabel>)}
                            {visiblePassword && (<TextField
                                fullWidth
                                label="Senha"
                                type="password"
                                name="password"
                                value={cadastroUsuarioformData.password}
                                onChange={handleChange}
                                required
                            />)}
                        </Grid>
                    </Grid>
                    <Button type="submit" variant="text" color="inherit" style={{ marginTop: '20px', marginRight: '10px' }}>
                        {action}
                    </Button>
                    <Button variant="text" color="inherit" style={{ marginTop: '20px' }} onClick={() => {
                        setCadastroUsuarioFormData({
                            name: '',
                            email: '',
                            emailSecundary:'',
                            password: '',
                            roles: '',
                            plano: '',
                            active: false
                        })
                        setAction('Cadastrar')
                    }}>
                        Cancelar
                    </Button>
                </form>
            </Paper>
        </Container>
    )
}

export default CadastrarUsuario;
import styled from "styled-components";


export const Container = styled.div`
    grid-area: MH;         
    
    @media only screen and (min-width: 768px) {
    /* styles for tablets and desktops */
    }

    @media only screen and (max-width: 767px) {
    /* styles for mobile devices */
        
    }

    /* @media only screen and (min-width: 992px){
        
    } */

`;
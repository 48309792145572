import styled from 'styled-components';
export const Containerd = styled.div`
    margin:5px 0 5px 0;    


    @media only screen and (min-width: 768px) {
        display:none;
    }    

    @media only screen and (max-width: 767px) {
        display:inline-block;
    }




`
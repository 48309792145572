import styled from 'styled-components';

export const Containerd = styled.div`
    h2{
        text-align:center;
        padding:0 0 5px 0;
    }    
    
    h4{        
        padding:0 0 5px 0;        
    }

    width:98%;
    margin:0 auto;
`



export const GridoContainer = styled.div`

    .MuiDataGrid-columnHeaderTitle{
        color: ${props => props.theme.color.black}
    }

    .MuiDataGrid-cellContent, .MuiDataGrid-cell--withRenderer{
        color: ${props => props.theme.color.info}
    }
`

export const Desktop = styled.div`
    display:none;    
    @media only screen and (min-width: 768px) {
        display:inline;        
    }  

`

export const Mobile = styled.div`
    display:none;
    @media only screen and (max-width: 767px) {        
        display:inline;        
    }    
`
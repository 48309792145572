import styled from "styled-components";


export const Container = styled.div`
    padding:5px;
    background-color:${props => props.theme.color.primary};
    color:${props => props.theme.color.info};
    grid-area: AS;     
    height:100%;
    
    @media only screen and (min-width: 768px) {
    /* styles for tablets and desktops */
    }

    @media only screen and (max-width: 767px) {
    /* styles for mobile devices */
        display:none;
    }

    /* @media only screen and (min-width: 992px){
        
    } */

`;

import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher2 } from "../../../services/fetcher.service"

const run = async (callback: any) => {
    const url = `${URI_BASE}/plans`

    return await fetcher2(url, 'GET', null, callback)
}

const UCGetPlanDescriptionService = {
    run
}

export default UCGetPlanDescriptionService
import Box from '@mui/material/Box';
import styled from 'styled-components';

export const Containerd = styled.div`
    h2{
        text-align:center;
        padding:0 0 5px 0;
    }
    
    .css-17vsgdu-MuiPaper-root{
     >   .MuiButton-root {
            margin:0 5px 0 0;
            width:200px;
        }  
    }

    @media only screen and (max-width: 767px) {        
            display:inline;  
            .css-17vsgdu-MuiPaper-root{
            >   .MuiButton-root {
                    margin:0 5px 0 0;
                    width:100%;
                }  
            }            
        }  
    
    
    h4{        
        padding:0 0 5px 0;        
        span{
            color: ${props => props.theme.color.info};
            text-decoration: underline;
        }
    }

    width:98%;
    margin:0 auto;

    .MuiPaper-root{
        span.destaque{
            font-weight:bolder;
            color:red;            
        }
    }
`

export const GridoContainer = styled.div`

    .MuiDataGrid-columnHeaderTitle{
        color: ${props => props.theme.color.black}
    }

    .MuiDataGrid-cellContent, .MuiDataGrid-cell--withRenderer{
        color: ${props => props.theme.color.info}
    }    
     
`

export const Desktop = styled.div`
    display:none;    
    @media only screen and (min-width: 768px) {
        display:inline;        
    }  

`

export const Mobile = styled.div`
    display:none;
    @media only screen and (max-width: 767px) {        
        display:inline;        
    }    
`

export const ContainerBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin:20px 0 20px 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
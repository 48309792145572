
import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher, fetcher2 } from "../../../services/fetcher.service"
import { FormState } from "../components/clientes-desktop"

const run = async (idEntidade: number, callback: any) => {

    const url = `${URI_BASE}/entity/${idEntidade}`

    return await fetcher2(url, 'DELETE', callback)
}

const UCRemoverEntidadeContabilidadeService = {
    run
}

export default UCRemoverEntidadeContabilidadeService

import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcherUpload } from "../../../services/fetcher.service"

const run = async (formData: FormData, callback: any) => {   
    const url = `${URI_BASE}/upload-blob`

    return await fetcherUpload(url, 'POST', formData, callback)
}

const UCUploadEmployeeDocsService = {
    run
}

export default UCUploadEmployeeDocsService
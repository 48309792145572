import React from 'react';
import '../landingpage.css';
import logoGL from '../../../assets/logogl.jpeg';
import { Logo } from './style';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import DiamondIcon from '@mui/icons-material/Diamond';
import CheckIcon from '@mui/icons-material/Check';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Button } from '@mui/material';

const Landing: React.FC = () => {
  return (
    <>
      {/* <header>
        <h1>Gerenciamento de Documentos para Contabilidade</h1>        
      </header>       */}
      <div className="container">
        <Logo>
          <img src={logoGL} alt='' />
        </Logo>
        <div className="section">
          <h2><FingerprintIcon /> Quem Somos </h2>
          <p>Somos uma empresa dedicada a transformar a maneira como contadores e seus clientes interagem e gerenciam documentos. Com anos de experiência no setor contábil e tecnológico, nossa missão é proporcionar uma plataforma intuitiva e eficiente que simplifica a comunicação e a gestão documental. Entendemos as necessidades específicas dos contadores e seus clientes, e desenvolvemos um sistema que elimina a burocracia, otimiza processos e garante segurança e praticidade no acesso e compartilhamento de documentos.</p>
        </div>
        <div className="section">
          <h2><AdsClickIcon />  Nosso Objetivo</h2>
          <p>Nosso objetivo é ser a ponte que conecta contadores e clientes de forma fluida e eficiente. Queremos revolucionar a gestão documental no setor contábil, oferecendo uma plataforma que permita o envio, recebimento e armazenamento de documentos de maneira fácil e segura. Nossa solução visa aumentar a produtividade, reduzir erros e garantir que todas as partes tenham acesso rápido e confiável às informações que precisam, quando precisam.</p>
        </div>
        <div className="section">
          <h2><DiamondIcon /> Nossos Valores</h2>
          <p><CheckIcon /><strong>Inovação</strong>: Estamos constantemente melhorando nossa plataforma para oferecer as mais novas e melhores funcionalidades.</p>
          <p><CheckIcon /><strong>Segurança</strong>: A proteção dos dados dos nossos usuários é nossa prioridade máxima. Utilizamos as melhores práticas de segurança e criptografia para garantir que seus documentos estejam sempre seguros.</p>
          <p><CheckIcon /><strong>Simplicidade</strong>: Acreditamos que a tecnologia deve facilitar a vida das pessoas. Por isso, nosso sistema é intuitivo e fácil de usar, mesmo para aqueles que não têm familiaridade com tecnologia.</p>
          <p><CheckIcon /><strong>Transparência</strong>: Mantemos uma comunicação clara e aberta com nossos clientes, garantindo que estejam sempre informados sobre o status de seus documentos e quaisquer atualizações na plataforma.</p>
          <p><CheckIcon /><strong>Eficiência</strong>: Nossa plataforma é projetada para otimizar o tempo dos contadores e seus clientes, eliminando processos manuais e reduzindo a necessidade de papelada física.</p>
        </div>
        <div className="section">
          <h2><FeaturedPlayListIcon /> Funcionalidades Principais</h2>
          <p><CheckIcon /><strong>Acesso Centralizado</strong>: Tenha todos os documentos importantes de contabilidade em um único lugar, acessível a qualquer momento.</p>
          <p><CheckIcon /><strong>Solicitação de Documentos</strong>: Facilite a solicitação de documentos específicos dos clientes com apenas alguns passos.</p>
          <p><CheckIcon /><strong>Notificações e Lembretes</strong>: Receba notificações sobre novos documentos.</p>
          <p><CheckIcon /><strong>Armazenamento Seguro</strong>: Garanta a segurança dos seus documentos com nosso sistema de armazenamento em nuvem.</p>
          <p><CheckIcon /><strong>Interface Intuitiva</strong>: Navegue pela plataforma de forma simples e intuitiva, sem complicações.</p>
        </div>
        {/* <div className="section">
          <h2>Depoimentos</h2>
          <p>"Desde que começamos a usar o sistema, a comunicação com nossos clientes ficou muito mais eficiente e segura. A plataforma é fácil de usar e economiza um tempo precioso." - Maria Silva, Contadora</p>
          <p>"Antes, era um desafio enorme gerenciar todos os documentos dos nossos clientes. Agora, com o sistema, tudo está mais organizado e acessível. Recomendo a todos os colegas de profissão." - João Pereira, Escritório de Contabilidade Pereira & Associados</p>
        </div> */}
        <div className="section">
          <h2><ConnectWithoutContactIcon /> Entre em Contato</h2>
          <p>Pronto para simplificar a gestão dos seus documentos contábeis? Entre em contato conosco hoje mesmo para saber mais sobre como podemos ajudar seu escritório a se tornar mais eficiente e organizado.</p>
        </div>
      </div>
      <footer className='dafooter'>
        <p>
        <Button variant="text" color="inherit" size={'large'} sx={{fontSize:'20px'}}
                            startIcon={<WhatsAppIcon />}
                            type="button" onClick={async () => {
                                window.open('https://wa.me/5513997784036', '_blank')
                            }}> Vendas
                        </Button>
        </p>
      </footer>
    </>
  );
}

export default Landing;

import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher2 } from "../../../services/fetcher.service"

const run = async (entityId: number, dateIni: any, dateFim: any, callback: any) => {
    const url = `${URI_BASE}/employee-documents/getallin-bydate/${entityId}/${dateIni}/${dateFim}`

    return await fetcher2(url, 'GET', null, callback)
}

const UCGetAllUploadEmployeeDocsServiceINByDateRange = {
    run
}

export default UCGetAllUploadEmployeeDocsServiceINByDateRange
import { jwtDecode } from "jwt-decode";
import { MINHA_CARTEIRA_ACESS_TOKEN } from "../../hooks/constants/localstorage-constants";

export const checkRolesOnUserLogged = (allowedRoles: string[]) => {
    const uc: string = localStorage.getItem(MINHA_CARTEIRA_ACESS_TOKEN) as string;
    if (uc) {        
        const userData: any = jwtDecode(uc);
        const userRoles: any[] = userData.roles; // Obtenha os papéis do usuário (pode estar em seu estado global ou de outra forma)
        const isRoleOk = userRoles.some((role: any) => allowedRoles.includes(role.name.toLowerCase()));        
        return isRoleOk
    }
    return false
}



import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher2, fetcherUpload } from "../../../services/fetcher.service"

const run = async (fileName: string, callback: any) => {
    const url = `${URI_BASE}/upload-blob/glivros/${fileName}`

    return await fetcher2(url, 'GET', null, callback)
}

const UCDownloadEmployeeDocsService = {
    run
}

export default UCDownloadEmployeeDocsService
import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher } from "../../../services/fetcher.service"

const run = async (name: string, email: string, password: string, roleId: number, active: boolean, plano: string, emailSecundary?: string) => {

    const userData = {
        email: email,
        name: name,
        password: password,
        roles: [{ id: roleId }],
        plano: plano,
        active: active,
        emailSecundary:emailSecundary
    }

    const url = `${URI_BASE}/usuario`

    const retorno = await fetcher<{
        name: string,
        email: string,
        id: number,
        statusCode: number
    }>(url, 'POST', userData)

    return retorno
}

const UCCadastrarUsuarioService = {
    run
}

export default UCCadastrarUsuarioService

import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher2 } from "../../../services/fetcher.service"

const run = async (userFuncionarioId: number, callback: any) => {
    const url = `${URI_BASE}/entity/entityfunc/${userFuncionarioId}`

    return await fetcher2(url, 'GET', null, callback)
}

const UCGetEntityByUserIdFuncionarioService = {
    run
}

export default UCGetEntityByUserIdFuncionarioService
import React, { useState } from 'react';
import { Box, Button, Grid, Modal, Paper, TextField, Typography } from '@mui/material';
import { Containerd } from '../../styles';
import UCRejectEmployeeDocument from '../../useCases/uc-reject-employeeDocs.service';
import { EmployeeDocumentsModel } from '../..';
import { useAuth } from '../../../../hooks/auth';
import { useActionContext } from '../../../../hooks/actions';

interface ModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    _EmployeeDocumentsModel: EmployeeDocumentsModel
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
};

const RejectDocumentForm: React.FC<ModalProps> = ({ open, onClose, title, _EmployeeDocumentsModel, onConfirm }) => {

    const {
        signOut,
        handleOpen,
        setTitle,
        setDialogContextText
    } = useAuth()
    const {
        showLinearProgress,
    } = useActionContext()

    const alerto = (title: string, content: string) => {
        handleOpen(true)
        setTitle(title)
        setDialogContextText(content)
    }



    const [documentDescription, setDocumentDescription] = useState<string>('')

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setDocumentDescription(value);
    };

    return (
        <Containerd>
            <Modal open={open} onClose={onClose}>
                <Box sx={style}>
                    <Paper elevation={24} style={{ padding: '20px', margin: '30px auto', maxWidth: '1200px', minWidth: '200px' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align='center' margin={'0 0 20px 0'}>
                            {title}
                        </Typography>
                        <TextField
                            sx={{ minWidth: '300px', height: '350px', maxWidth: '550px' }}
                            multiline
                            rows={12}
                            label="Motivo Rejeição"
                            fullWidth
                            name="documentDescription"
                            value={documentDescription}
                            onChange={handleChange}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button sx={{ width: '100%' }}
                                    //endIcon={<RestartAltIcon />}
                                    onClick={async () => {
                                        try {
                                            showLinearProgress(true)                                                              
                                            await UCRejectEmployeeDocument.run(Number(_EmployeeDocumentsModel.id), documentDescription,Number(_EmployeeDocumentsModel.entityId), signOut)
                                            onClose()
                                            showLinearProgress(false)
                                            setDocumentDescription('');
                                            alerto('Info', 'Registro rejeitado com sucesso, a contabilidade será informada!')
                                        } catch (error: any) {
                                            let message = error.error.message || error.error
                                            alerto('Erro', message)
                                            showLinearProgress(false)
                                            setDocumentDescription('');
                                        }

                                    }}
                                    variant='outlined' color='info'>Sim</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button sx={{ width: '100%' }}
                                    //endIcon={<RestartAltIcon />}
                                    onClick={() => {
                                        onClose()
                                        setDocumentDescription('');
                                    }}
                                    variant='outlined' color='info'>Cancelar</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>

            </Modal>
        </Containerd>
    )
}

export default RejectDocumentForm;
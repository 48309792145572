import styled from 'styled-components';
export const GridoContainer = styled.div`
    .MuiDataGrid-columnHeaderTitle{
        color: ${props =>props.theme.color.black}
    }

    .MuiDataGrid-cellContent, .MuiDataGrid-cell--withRenderer{
        color: ${props =>props.theme.color.info}
    }    
    overflow: auto;
`

import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useActionContext } from '../../hooks/actions';

interface ITreeviewGenericaProps {
    headerText: string,
    cadastroText: string,
    listarText: string,
    onSetShow: () => void,
    onSetHide: () => void,
}

const TreeViewGenerica: React.FC<ITreeviewGenericaProps> = ({
    headerText,
    cadastroText,
    listarText,
    onSetShow,
    onSetHide }) => {

    // const { setShowFormCadContabilidade } = useActionContext()

    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    useEffect(() => {
        setOpen(true);
    })

    return (
        <List>
            <ListItem button onClick={handleToggle}>
                <ListItemIcon>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText primary={headerText} />
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button onClick={() => {
                        //setShowFormCadContabilidade(true)
                        onSetShow()
                    }}>
                        <ListItemIcon>
                            <PersonAddAltIcon />
                        </ListItemIcon>
                        <ListItemText primary={cadastroText} />
                    </ListItem>
                    <ListItem button onClick={() => {
                        //setShowFormCadContabilidade(false)
                        onSetHide()
                    }}>
                        <ListItemIcon>
                            <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText primary={listarText} />
                    </ListItem>
                </List>
            </Collapse>
        </List>
    );
};

export default TreeViewGenerica;
import styled from 'styled-components';



export const Container = styled.div`
    .MuiDataGrid-columnHeader{
        color: ${props => props.theme.color.info}        
    }

    .MuiDataGrid-cellContent{
        color: ${props => props.theme.color.black}        
    }

    .MuiDataGrid-row{
      cursor:pointer
    }

    .MuiGrid-root {
      width: 100%
    }

    color:${props => props.theme.color.white};

 `;

export const Containerd = styled.div`
  h2{
      text-align:center;
      padding:0 0 5px 0;
  }    
  width:98%;
  margin:0 auto;

  .MuiButtonBase-root{        
      min-width:40px;                     
  }
`

export const GridoContainer = styled.div`

  .MuiDataGrid-columnHeaderTitle{
      color: ${props =>props.theme.color.black}
  }

  .MuiDataGrid-cellContent, .MuiDataGrid-cell--withRenderer{
      color: ${props =>props.theme.color.info}
  }    
 
`
export const StyledTitle = styled.h3<{ condition: any }>`
  display: ${props => props.condition=='false' ? 'none' : 'block'};
  font-weight: bold;
  margin-bottom: 20px;
`;

export const RenderByCondition = styled.h3<{ condition: any }>`
  display:${props => Boolean(props.condition) ? 'block' : 'none'}    
`
import React, { createContext, useState, useContext } from 'react';
import AuthService from './services/Auth.service';
import { MINHA_CARTEIRA_ACESS_TOKEN, MINHA_CARTEIRA_LOGGED, MINHA_CARTEIRA_USER_EMAIL } from './constants/localstorage-constants';
import { MENSAGEM_NAO_AUTORIZADO, MENSAGEM_USUARIO_OU_SENHA_INVALIDOS } from './constants/friendly-messages.constants';
import { jwtDecode } from 'jwt-decode';

interface IAuthContext {
    logged: boolean;
    signIn(email: string, password: string): Promise<boolean | undefined>;
    signOut(): void;
    userName: string;
    mostraUserName(_userName: string): void;
    handleClose: () => void;
    handleOpen: (e: any) => void;
    setTitle: (e: any) => void;
    setDialogContextText: (e: any) => void;
    open: boolean;
    title: string;
    dialogContextText: string;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

type Props = {
    children?: React.ReactNode
};


const AuthProvider: React.FC<Props> = ({ children }) => {

    const [title, set_title] = useState<string>('');
    const [open, set_open] = useState<boolean>(false);
    const [dialogContextText, set__dialogContextText] = useState<string>('');

    const handleClose = () => {
        set_open(false)
    }

    const handleOpen = () => {
        set_open(true)
    }

    const setTitle = (title: string) => {
        set_title(title)
    }

    const setDialogContextText = (dialogContextText: string) => {
        set__dialogContextText(dialogContextText)
    }

    const [userName, setuserName] = useState<string>('');

    const [logged, setLogged] = useState<boolean>(() => {
        const isLogged = localStorage.getItem(MINHA_CARTEIRA_LOGGED);
        return !!isLogged;
    });

    const signIn = async (email: string, password: string): Promise<boolean | undefined> => {

        try {
            const response = await AuthService.login(email, password)
            const data = await response.json()
            let retorno: boolean = false

            if (data.access_token) {
                localStorage.setItem(MINHA_CARTEIRA_LOGGED, 'true');

                localStorage.setItem(MINHA_CARTEIRA_ACESS_TOKEN, data.access_token);
                localStorage.setItem(MINHA_CARTEIRA_USER_EMAIL, email);
                setuserName(email)
                setLogged(true);
                retorno = true
            } else {
                handleOpen()
                setTitle(MENSAGEM_NAO_AUTORIZADO)
                setDialogContextText(MENSAGEM_USUARIO_OU_SENHA_INVALIDOS)
                retorno = false
            }

            return retorno
        } catch (error) {
            console.log(error)
        }
    }

    const signOut = () => {
        localStorage.removeItem(MINHA_CARTEIRA_LOGGED);
        setLogged(false);
    }

    const mostraUserName = (_userName: string) => {
        setuserName(_userName)
    }

    return (
        <AuthContext.Provider value={{
            logged,
            signIn, signOut, userName, mostraUserName, handleClose, handleOpen, setTitle, setDialogContextText,
            open,
            title,
            dialogContextText
        }}>
            {children}
        </AuthContext.Provider>
    );
}

function useAuth(): IAuthContext {
    const context = useContext(AuthContext);

    return context;
}

export { AuthProvider, useAuth };

import { FormState } from ".."
import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher2 } from "../../../services/fetcher.service"
// import { FormState } from "../components"

const run = async (formState: any, callback: any) => {
    
    const url = `${URI_BASE}/require-documents`

    return await fetcher2(url, 'PUT', formState, callback)
}

const UCFillRequireDocumentsService = {
    run
}

export default UCFillRequireDocumentsService
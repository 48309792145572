export const URI_BASE = 'https://glivrosback.gentlepond-326e164c.eastus.azurecontainerapps.io'
//export const URI_BASE = 'http://localhost:3000'

export const ROTA_ROOT = '/'
export const ROTA_LOGIN = '/login'
export const ROTA_FORGET_PASS = '/forget-pass'
export const ROTA_MASTER_GROUP_VIEW = '/mc'
export const ROTA_CLIENTES = '/clientes'
export const ROTA_COMUNICACAO = '/com'
export const ROTA_DOCUMENTOS_ENTIDADE = '/doc-ent'
export const ROTA_PLANO = '/plano'
export const ROTA_REQUISICAO_DOCUMENTO = '/requisicao-documento'


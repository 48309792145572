import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcherNotLogged } from "../../../services/fetcher.service"

const run = async (email: string) => {
    

    const url = `${URI_BASE}/forget-pass/${email}`

    return await fetcherNotLogged(url, 'GET', null)
}

const UCForgetPasswordService = {
    run
}

export default UCForgetPasswordService
import { RequisicaoDocumento } from ".."
import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher2 } from "../../../services/fetcher.service"

const run = async (formState: RequisicaoDocumento, callback: any) => {
    
    const formBody = {
        documentRequireName: formState.requisicaoLabel,
        documentRequireDescription: formState.requisicaoMotivoDescricao,
        accountabilityId: formState.entityId,
        clientId: formState.clientId,
        direction: formState.direction
    }
    const url = `${URI_BASE}/require-documents/`

    return await fetcher2(url, 'POST', formBody, callback)
}

const UCGravarRequisicaoDocumentoService = {
    run
}

export default UCGravarRequisicaoDocumentoService
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/auth';
import { useActionContext } from '../../hooks/actions';
import { TextField, Container, Button, Paper, Grid } from '@mui/material';
import { MENSAGEM_NAO_AUTORIZADO } from '../../hooks/constants/friendly-messages.constants';
import { ERRO, REGISTRO_ATUALIZADO, SUCESSO } from '../../views/Master/useCases/constants/admin-message.constants';
import UCAtualizarUsuarioService from '../../views/Master/useCases/uc-atualizar-usuario.service';
import { getUserData } from '../../services/storage.service';

type IAtualizarDadosLogin = {
    onHandleSubmit: (e: any) => void
}

interface ICadastroUsuarioFormData {
    id: string,
    name: string,
    email: string,
    password: string,
}

const AtualizarDadosLogin: React.FC<IAtualizarDadosLogin> = ({ onHandleSubmit }) => {

    const { handleOpen, setTitle, setDialogContextText } = useAuth();

    const [cadastroUsuarioformData, setCadastroUsuarioFormData] = useState<ICadastroUsuarioFormData>({
        id: '',
        name: '',
        email: '',
        password: '',
    });

    const {
        showLinearProgress
    } = useActionContext()


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCadastroUsuarioFormData({ ...cadastroUsuarioformData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        try {

            showLinearProgress(true)
            e.preventDefault(); {

                const retorno: any = await UCAtualizarUsuarioService.run(
                    String(cadastroUsuarioformData.id),
                    cadastroUsuarioformData.name,
                    cadastroUsuarioformData.email,
                    cadastroUsuarioformData.password)

                handleOpen(true)

                if (retorno === 1) {
                    setTitle(SUCESSO)
                    setDialogContextText(REGISTRO_ATUALIZADO)
                    onHandleSubmit(e)

                    setCadastroUsuarioFormData({
                        id: '',
                        name: '',
                        email: '',
                        password: ''
                    })
                } else {
                    setTitle(ERRO)
                    if (retorno.error.statusCode === 401) {
                        setDialogContextText(MENSAGEM_NAO_AUTORIZADO)
                    }
                    else {
                        if (typeof retorno.error === 'string') {
                            setDialogContextText(retorno.error)
                        } else {
                            setDialogContextText(retorno.error.message.toString())
                        }
                    }
                }


            }

            showLinearProgress(false)

        } catch (error: any) {
            handleOpen(true)
            setTitle(ERRO)
            setDialogContextText(error.message)
            showLinearProgress(false)
        }
    };

    const loadData = async () => {
        const uc = await getUserData()
        setCadastroUsuarioFormData({
            id: String(uc.sub),
            name: uc.username,
            email: uc.email,
            password: ''
        })
    }

    useEffect(() => {
        //todo: usar o storage service para pegar o usuario logado e atualizar os campos
        loadData()
    }, [])

    return (
        <Container>
            <Paper elevation={24} style={{ padding: '20px', margin: '30px auto', maxWidth: '1200px', minWidth:'300px' }}>
                <h2>Atualização de Usuário</h2>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Nome"
                                type="text"
                                name="name"
                                value={cadastroUsuarioformData.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email"
                                type="email"
                                name="email"
                                value={cadastroUsuarioformData.email}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* {!visiblePassword && (<InputLabel id="password-label">Senha: {DEFAULT_PASSWORD}</InputLabel>)} */}
                            <TextField
                                fullWidth
                                label="Senha"
                                type="password"
                                name="password"
                                value={cadastroUsuarioformData.password}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Button type="submit" variant="text" color="inherit" style={{ marginTop: '20px', marginRight: '10px' }}>
                        Atualizar
                    </Button>
                    <Button variant="text" color="inherit" style={{ marginTop: '20px' }} onClick={() => {
                        setCadastroUsuarioFormData({
                            id: '',
                            name: '',
                            email: '',
                            password: ''
                        })
                    }}>
                        Cancelar
                    </Button>
                </form>
            </Paper>
        </Container>
    )
}

export default AtualizarDadosLogin;
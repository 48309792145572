import React, { useEffect, useState } from 'react';
import DocumentForm from './components';
import { ContainerBox, Containerd, GridoContainer } from './styles';
import { Breadcrumbs, Button, Link, Paper, Typography } from '@mui/material';
import Grido2 from '../../components/Grido2';
import { GridColDef } from '@mui/x-data-grid';
//import UCGetAllUploadEmployeeDocsService from './useCases/uc-getAllUpload-employeeDocs.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useActionContext } from '../../hooks/actions';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment'
import DownloadIcon from '@mui/icons-material/Download';
import UCDownloadEmployeeDocsService from './useCases/uc-download-employeeDocs.service';
import QuestionDialog from '../../components/QuestionDialog';
import UCRemoverEmployeeDocsService from './useCases/uc-remover-employeeDocs.service';
import UCRemoverEmployeeDocsBlobService from './useCases/uc-remover-employeeDocs-blob.service';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ForumIcon from '@mui/icons-material/Forum';
import { ROTA_CLIENTES } from '../../constants/uri/constants.uri';
import UCGetAllUploadEmployeeDocsServiceIN from './useCases/uc-getAllUpload-employeeDocsIN.service';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import UCGetAllUploadEmployeeDocsService from '../DocumentosEntidadeEmployee/useCases/uc-getAllUpload-employeeDocs.service';
import UCGetAllUploadEmployeeDocsServiceINByDateRange from './useCases/uc-getAllUpload-employeeDocsINByRange.service';


const DocumentosEntidade: React.FC = () => {


    const primeiroDiaDoMes = dayjs().startOf('month');
    const ultimoDoMes = dayjs().endOf('month');

    const handleDateIniChange = (date: any) => {
        setDateIni(date)
    };

    const handleDateFinalChange = (date: any) => {
        setDateFinal(date)
    };

    const [dateIni, setDateIni] = useState<Dayjs | null>(dayjs(primeiroDiaDoMes));
    const [dateFinal, setDateFinal] = useState<Dayjs | null>(dayjs(ultimoDoMes));

    const navigate = useNavigate()
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const [documentsRows, setDocumentsRows] = useState<any[]>([]);
    const [idToDelete, setIdToDelete] = useState<number>(0);
    const [megaBytes, setMegaBytes] = useState<number>(0);
    const [nameToDelete, setNameToDelete] = useState<string>('');
    const [exibindo, setExibindo] = useState<string>('');
    const {
        setOpenDialog,
        setOpenDialogText,
        setOpenDialogQuestionContext,
        handleCloseQuestionDialog
    } = useActionContext();

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 1 },
        {
            field: 'documentName', headerName: 'Doc.', width: 150,
        },
        {
            field: 'dateToWarn', headerName: 'Vencimento', width: 120
        },
        {
            field: 'visualizationDate', headerName: 'Dt. Visualização', width: 180
        },
        {
            field: 'createdDate', headerName: 'Criado', width: 110,
        },
        {
            field: 'documentDescription', headerName: 'Descrição', width: 100, align: 'center',
            renderCell: (params: any) => (
                <div>
                    <Button
                        style={{ maxWidth: '10px' }}
                        variant='contained'
                        onClick={() => {
                            alerto('Descrição', params.row.documentDescription)
                        }}
                    >
                        {params.row.documentDescription.length > 0 ? params.row.documentDescription.substring(0, 3) : params.row.documentDescription}...
                    </Button>
                </div>
            ),
        },
        {
            field: 'rejectedDoc', headerName: 'Rejeição', width: 100, align: 'center',
            renderCell: (params: any) => (
                <div>
                    <Button
                        color='warning'
                        disabled={!params.row.rejectedDoc}
                        style={{ maxWidth: '10px' }}
                        variant='contained'
                        onClick={() => {
                            alerto('Motivo Rejeiçao', params.row.rejectDescription)
                        }}
                    >
                        VER
                    </Button>
                </div>
            ),
        },
        { field: 'file_sizeMB', headerName: 'MB', width: 80, align: 'center' },
        {
            field: 'actions',
            headerName: 'Ações',
            width: 250,
            headerAlign: 'center',
            renderCell: (params) => (
                <div>
                    <Button startIcon={<DeleteIcon />}
                        onClick={() => {
                            setOpenDialog(true)
                            setOpenDialogText('Remoção de documento')
                            setOpenDialogQuestionContext(`Deseja remover o registro - id (${params.row.id})?`)
                            setIdToDelete(Number(params.row.id))
                            setNameToDelete(params.row.documentGuid)
                        }}>
                    </Button>
                    <Button
                        startIcon={<DownloadIcon
                            aria-label='Baixar'
                        />}
                        onClick={async () => {
                            //todo: chamar metodo de download
                            showLinearProgress(true)
                            const download = await UCDownloadEmployeeDocsService.run(params.row.documentGuid, signOutByExpiresProcess)

                            const blob = await download.blob();

                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = params.row.documentGuid;
                            showLinearProgress(false)

                            // Simule um clique no link para iniciar o download
                            link.click();

                            // Libere o objeto URL usado para criar o link
                            window.URL.revokeObjectURL(link.href);

                        }}>
                    </Button>
                </div>
            ),
        }
    ]

    const deleteRecord = async () => {

        try {
            showLinearProgress(true)
            await UCRemoverEmployeeDocsService.run(idToDelete, signOut)
            await UCRemoverEmployeeDocsBlobService.run(nameToDelete, signOut)
            alerto('Sucesso', 'Registro removido com sucesso')
            await selectGetFilter()
            showLinearProgress(false)
        } catch (error: any) {
            alerto('Erro', error.message)
            showLinearProgress(false)
        }

    }

    const selectGetFilter = async () => {
        if (exibindo === 'Todos') {
            await getAllDocuments()
        }

        if (exibindo === 'Requisitados') {
            await getAllDocumentsRequired()
        }

        if (exibindo === 'Não Visualizados') {
            await getAllNoVisualizationDocuments()
        }

        if (exibindo === 'Não Rejeitados') {
            await getAllRejectDocuments()
        }
    }

    const {
        setDialogContextText,
        setTitle,
        handleOpen,
        signOut
    } = useAuth()

    const signOutByExpiresProcess = () => {
        signOut();
        navigate('/')
    }

    const {
        showLinearProgress,
        new_Doc
    } = useActionContext()

    const verificaDatasValidas = () => {
        const isvalidIni = dayjs(dateIni).isValid()
        const isvalidFinal = dayjs(dateFinal).isValid()
        if (!isvalidIni || !isvalidFinal) {
            throw new Error("Verifique as Datas da Pesquisa")
        }
    }

    const getAllDocuments = async () => {
        try {
            verificaDatasValidas()
            setExibindo('Todos')
            showLinearProgress(true)
            const entityId = params.get('entityId')
            const response = await UCGetAllUploadEmployeeDocsService.run(Number(entityId), dateIni?.toISOString(), dateFinal?.toISOString(), signOutByExpiresProcess)

            if (!response.ok) {
                const error = await response.json()
                let message = error.error.message || error.error
                alerto('Erro', message)
            } else {
                const documentos = await response.json()
                let mb = 0
                const documentosFormatted = documentos.map((doc: any) => {
                    mb = mb + Number(kbToMB(Number(doc.file_size)))
                    return {
                        ...doc,
                        dateToWarn: moment(doc.dateToWarn).format('DD/MM/YYYY'),
                        visualizationDate: doc.visualizationDate ? moment(doc.visualizationDate).format('DD/MM/YYYY') : '',
                        createdDate: doc.createdDate ? moment(doc.createdDate).format('DD/MM/YYYY') : '',
                        file_sizeMB: kbToMB(Number(doc.file_size)),
                    }
                })
                setMegaBytes(mb)
                setDocumentsRows(documentosFormatted)
            }

            showLinearProgress(false)
        } catch (error: any) {
            showLinearProgress(false)
            let message =error.message || error.error || error.error.message
            alerto('Erro', message)
        }
    }

    const getAllDocumentsRequired = async () => {
        try {
            verificaDatasValidas()
            setExibindo('Requisitados')
            showLinearProgress(true)
            const entityId = params.get('entityId')

            const response = await UCGetAllUploadEmployeeDocsServiceINByDateRange.run(Number(entityId), dateIni?.toISOString(), dateFinal?.toISOString(), signOutByExpiresProcess)

            if (!response.ok) {
                const error = await response.json()
                let message = error.error.message || error.error
                alerto('Erro', message)
            } else {
                const documentos = await response.json()
                let mb = 0
                const documentosFormatted = documentos.map((doc: any) => {
                    mb = mb + Number(kbToMB(Number(doc.file_size)))
                    return {
                        ...doc,
                        dateToWarn: '-',//moment(doc.dateToWarn).format('DD/MM/YYYY'),
                        visualizationDate: doc.visualizationDate ? moment(doc.visualizationDate).format('DD/MM/YYYY') : '',
                        createdDate: doc.createdDate ? moment(doc.createdDate).format('DD/MM/YYYY') : '',
                        file_sizeMB: kbToMB(Number(doc.file_size)),
                    }
                })
                setMegaBytes(mb)
                setDocumentsRows(documentosFormatted)
            }

            showLinearProgress(false)
        } catch (error: any) {
            showLinearProgress(false)
            let message =error.message || error.error || error.error.message
            alerto('Erro', message)
        }
    }

    const getAllNoVisualizationDocuments = async () => {
        try {
            verificaDatasValidas()
            setExibindo('Não Visualizados')
            showLinearProgress(true)
            const entityId = params.get('entityId')
            const response = await UCGetAllUploadEmployeeDocsService.run(Number(entityId), dateIni?.toISOString(), dateFinal?.toISOString(), signOutByExpiresProcess)

            if (!response.ok) {
                const error = await response.json()
                let message = error.error.message || error.error
                alerto('Erro', message)
            } else {
                const documentos = await response.json()

                const documentosFiltered = documentos.filter((e: any) => {
                    return e.visualizationDate === null
                })

                const documentosFormatted = documentosFiltered.map((doc: any) => {
                    return {
                        ...doc,
                        dateToWarn: moment(doc.dateToWarn).format('DD/MM/YYYY'),
                        visualizationDate: doc.visualizationDate ? moment(doc.visualizationDate).format('DD/MM/YYYY') : '',
                        createdDate: doc.createdDate ? moment(doc.createdDate).format('DD/MM/YYYY') : '',
                        file_sizeMB: kbToMB(Number(doc.file_size)),
                    }
                })
                setDocumentsRows(documentosFormatted)
            }

            showLinearProgress(false)
        } catch (error: any) {
            showLinearProgress(false)
            let message =error.message || error.error || error.error.message
            alerto('Erro', message)
        }
    }

    const getAllRejectDocuments = async () => {
        try {
            verificaDatasValidas()
            setExibindo('Rejeitados')
            showLinearProgress(true)
            const entityId = params.get('entityId')
            const response = await UCGetAllUploadEmployeeDocsService.run(Number(entityId), dateIni?.toISOString(), dateFinal?.toISOString(), signOutByExpiresProcess)

            if (!response.ok) {
                const error = await response.json()
                let message = error.error.message || error.error
                alerto('Erro', message)
            } else {
                const documentos = await response.json()

                const documentosFiltered = documentos.filter((e: any) => {
                    return e.rejectedDoc
                })

                const documentosFormatted = documentosFiltered.map((doc: any) => {
                    return {
                        ...doc,
                        dateToWarn: moment(doc.dateToWarn).format('DD/MM/YYYY'),
                        visualizationDate: doc.visualizationDate ? moment(doc.visualizationDate).format('DD/MM/YYYY') : '',
                        createdDate: doc.createdDate ? moment(doc.createdDate).format('DD/MM/YYYY') : '',
                        file_sizeMB: kbToMB(Number(doc.file_size)),
                    }
                })
                setDocumentsRows(documentosFormatted)
            }

            showLinearProgress(false)
        } catch (error: any) {
            showLinearProgress(false)
            let message =error.message || error.error || error.error.message
            alerto('Erro', message)
        }
    }

    const kbToMB = (kb: number) => {
        var gb = kb / (1024); // 1 GB = 1024^3 bytes
        return gb.toFixed(2);
    }

    const kbToGB = (kb: number) => {
        var gb = kb / (1024 * 1024 * 1024); // 1 GB = 1024^3 bytes
        return gb.toFixed(10);
    }


    const alerto = (title: string, content: string) => {
        handleOpen(true)
        setTitle(title)
        setDialogContextText(content)
    }

    useEffect(() => {
        getAllDocuments()
    }, [new_Doc])

    return (
        <Containerd>
            <QuestionDialog
                handleClose={handleCloseQuestionDialog}
                handleConfirm={async () => {
                    if (idToDelete > 0) {
                        await deleteRecord()
                    }
                    handleCloseQuestionDialog()
                }}
            ></QuestionDialog>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover"
                    sx={{ cursor: 'pointer' }}
                    color="inherit"
                    onClick={(e) => {
                        e.preventDefault()
                        navigate(ROTA_CLIENTES)
                    }}

                >
                    Clientes
                </Link>
                <Typography color="text.primary">Upload de Arquivos</Typography>
            </Breadcrumbs>
            <DocumentForm />
            <GridoContainer>
                <Paper elevation={24} style={{ padding: '20px', margin: '30px auto', maxWidth: '1200px' }}>
                    <h3>
                        Documentos - Total GB({(megaBytes / 1024).toFixed(5)}) - MB({megaBytes.toFixed(2)})
                    </h3>
                    <Paper elevation={24} style={{ padding: '20px', margin: '30px auto', maxWidth: '1200px' }}  >
                        <h4>Filtros por data</h4>
                        <ContainerBox>
                            <DatePicker
                                name='dateIni'
                                value={dateIni}
                                onChange={handleDateIniChange}
                                sx={{ width: '100%' }}
                                label="Data Criação Inicio"
                            />
                            <DatePicker
                                name='dateFinal'
                                value={dateFinal}
                                onChange={handleDateFinalChange}
                                sx={{ width: '100%' }}
                                label="Data Criação Fim"
                            />
                        </ContainerBox>
                        <ContainerBox>
                            <Button
                                endIcon={
                                    <RestartAltIcon />
                                }
                                onClick={() => {
                                    getAllDocuments()
                                }}
                                variant='outlined' color='info'>Criados</Button>
                            <Button
                                endIcon={<FilterAltIcon />}
                                onClick={getAllRejectDocuments}
                                variant='outlined' color='info'>Rejeitados </Button>
                            <Button
                                endIcon={<VisibilityOff />}
                                onClick={getAllNoVisualizationDocuments}
                                variant='outlined' color='info'>Não Visualizados</Button>
                            <Button
                                endIcon={<ForumIcon />}
                                onClick={getAllDocumentsRequired}
                                variant='outlined' color='info'>Requisitados</Button>
                        </ContainerBox>
                    </Paper>

                    <h4>
                        Filtro : <span>{exibindo}</span>
                    </h4>
                    <Grido2
                        height={600}
                        onLoad={() => {

                        }}
                        pageSize={10}
                        _columns={columns}
                        _rows={documentsRows}
                        onClick={() => { }}
                    />
                    <h3>

                    </h3>
                </Paper>
            </GridoContainer>
        </Containerd>
    );

}

export default DocumentosEntidade;
import styled from 'styled-components';
export const GridoContainer = styled.div`
    .MuiDataGrid-columnHeaderTitle{
        color: ${props =>props.theme.color.black}
    }

    .MuiDataGrid-cellContent, .MuiDataGrid-cell--withRenderer{
        color: ${props =>props.theme.color.info}
    }    
    overflow: auto;
`
export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* background-color: ${props => props.theme.color.primary}; */
`;


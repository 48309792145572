import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher2 } from "../../../services/fetcher.service"

const run = async (clientId: number, callback: any, direction?: string) => {

    const url = `${URI_BASE}/require-documents/${clientId}?direction=${direction}`

    return await fetcher2(url, 'GET', null, callback)
}

const UCRetornarRequisicaoDocumentoByClientIdService = {
    run
}

export default UCRetornarRequisicaoDocumentoByClientIdService
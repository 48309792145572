import React from 'react';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';
import CadastrarUsuario from '../../../CadastroUsuario';
import AtualizarDadosLogin from '../../../AtualizarDadosLogin';


interface ModalProps {
    open: boolean;
    onClose: () => void;
    title: string;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'    
};


const ModalUpdateUser: React.FC<ModalProps> = ({ open, onClose, title }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {title}
                </Typography>
                <AtualizarDadosLogin
                    onHandleSubmit={() => {
                        onClose()
                     }}                                                                                
                />
            </Box>

        </Modal>
    );
};

export default ModalUpdateUser;
import { MINHA_CARTEIRA_ACESS_TOKEN } from "../hooks/constants/localstorage-constants"

export const fetcher = async <T>(url: string, method: string, body?: any): Promise<T> => {

    const uc: string = localStorage.getItem(MINHA_CARTEIRA_ACESS_TOKEN) as string

    if (!uc) throw Error("Não autorizado")

    let init: any = {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin        
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${uc}`
        },
    }

    if (body) {
        init.body = JSON.stringify(body)
    }

    const response = await fetch(url, init)

    const data = await response.json()

    return data
}


export const fetcher2 = async (url: string, method: string, body?: any, callback?: any): Promise<any> => {

    const uc: string = localStorage.getItem(MINHA_CARTEIRA_ACESS_TOKEN) as string

    if (!uc) throw Error("Não autorizado")

    let init: any = {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin        
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${uc}`
        },
    }

    if (body) {
        init.body = JSON.stringify(body)
    }

    const response = await fetch(url, init)

    if (!response.ok) {
        if (response.status === 401) {
            if (callback) callback()
        }
    }

    return response
}

export const fetcherUpload = async (url: string, method: string, formData: FormData, callback?: any): Promise<any> => {

    const uc: string = localStorage.getItem(MINHA_CARTEIRA_ACESS_TOKEN) as string

    if (!uc) throw Error("Não autorizado")

    let init: any = {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin        
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            //'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${uc}`
        },
        body: formData
    }
    
    const response = await fetch(url, init)

    if (!response.ok) {
        if (response.status === 401) {
            if (callback) callback()
        }        
    }

    return response
}

export const fetcherNotLogged = async (url: string, method: string, body?: any): Promise<any> => {

    let init: any = {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin        
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'            
        },
    }

    if (body) {
        init.body = JSON.stringify(body)
    }

    const response = await fetch(url, init)
    
    return response
}
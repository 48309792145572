import styled from 'styled-components';

export const Logo = styled.div`
    display: flex;
    align-items: center;
    margin-top: 45px ;
    margin-bottom: 45px ;
    > h2 {
        color: ${props => props.theme.color.black}; 
        margin-left: 7px;
    }

    > img {        
        width: 250px;
        height: 250px;        
        margin: 0 auto;
    }
`;

export const Heador = styled.header`
  color: white;
  padding: 1em 0;
  text-align: center;
`

import React, { useEffect, useState } from 'react';
import { ContainerBox, Containerd, GridoContainer, LegendContainer, LegendText, Squaro } from './styles';
import { Avatar, Button, Card, CardContent, CardHeader, Paper, Tooltip } from '@mui/material';
import Grido2 from '../../components/Grido2';
import { GridColDef } from '@mui/x-data-grid';
import UCGetAllUploadEmployeeDocsService from './useCases/uc-getAllUpload-employeeDocs.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useActionContext } from '../../hooks/actions';
import moment from 'moment'
import DownloadIcon from '@mui/icons-material/Download';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import UCDownloadEmployeeDocsService from './useCases/uc-download-employeeDocs.service';
import { getUserData } from '../../services/storage.service';
import UCGetEntityByUserIdFuncionarioService from './useCases/uc-getEntityByUserIdFuncionario.service';
import UCMarkVisualizationEmployeeDocumento from './useCases/uc-markvisualization-employeeDocs.service';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import QuestionDialog from '../../components/QuestionDialog';
import RejectDocumentForm from './components/rejectDocumentForm';
import { VisibilityOff } from '@mui/icons-material';
import UCGetRequiredDocsService from './useCases/uc-geRequireDocuments.service';
import dayjs, { Dayjs } from 'dayjs';
import ArticleIcon from '@mui/icons-material/Article';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { DatePicker } from '@mui/x-date-pickers';
import './styles.css'
import UCFavoriteEmployeeDocumento from './useCases/uc-favorite.service';
import { FILTROS } from './constants';
import { ROTA_LOGIN } from '../../constants/uri/constants.uri';
import errorMessageService from '../../services/errorMessage.service';

export interface EmployeeDocumentsModel {
    id?: number
    documentName?: string
    documentUrl?: string
    documentGuid?: string
    documentDescription?: string
    dateToWarn?: string
    createdDate?: string
    udpatedDate?: any
    entityId?: number
    file_size?: string
    visualizationDate?: string
    rejectedDoc: boolean
    rejectDescription: any
}


const DocumentosEntidadeFuncionario: React.FC = () => {



    const navigate = useNavigate()
    const {
        setOpenDialog,
        setOpenDialogText,
        setOpenDialogQuestionContext,
        setRequireDocsCount } = useActionContext()

    const [filtro, setFiltro] = useState<string>('')

    const primeiroDiaDoMes = dayjs().startOf('month');
    const ultimoDoMes = dayjs().endOf('month');


    const [documentsRows, setDocumentsRows] = useState<any[]>([]);
    const [noVisualition, setNoVisualition] = useState<number>(0);
    const [dateIni, setDateIni] = useState<Dayjs | null>(dayjs(primeiroDiaDoMes));
    const [dateFinal, setDateFinal] = useState<Dayjs | null>(dayjs(ultimoDoMes));


    const [entityId, setEntityId] = useState<number>(0);

    const [showModal, setShowModal] = useState<boolean>(false);

    const [documentEmployee, setDocumentEmployee] = useState<EmployeeDocumentsModel>({ rejectDescription: null, rejectedDoc: false })

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 1 },
        { field: 'documentName', headerName: 'Doc.', width: 130 },
        {
            field: 'dateToWarn', headerName: 'Vencimento', width: 120, headerAlign: 'center', align: 'center'
        },
        {
            field: 'visualizationDate', headerName: 'Dt. Visualização', width: 120, headerAlign: 'center', align: 'center'
        },
        {
            field: 'createdDate', headerName: 'Criado', width: 120, headerAlign: 'center', align: 'center'
        },
        {
            field: 'documentDescription', headerName: 'Descrição', width: 120, headerAlign: 'center', align: 'center',
            renderCell: (params: any) => (
                <div>
                    <Button
                        style={{ maxWidth: '10px' }}
                        variant='contained'
                        onClick={() => {
                            alerto('Descrição', params.row.documentDescription)
                        }}
                    >
                        ver
                    </Button>
                </div>
            ),
        },
        {
            field: 'rejectedDoc', headerName: 'Rejeitado', width: 80, headerAlign: 'center', align: 'center',
            renderCell: (params: any) => (params.row.rejectedDoc ? 'Sim' : 'Não'),
        },
        {
            field: 'rejectDescription', headerName: 'Rejeição', width: 100, headerAlign: 'center', align: 'center',
            renderCell: (params: any) => (
                <div>
                    <Button
                        disabled={!params.row.rejectedDoc}
                        style={{ maxWidth: '10px' }}
                        variant='contained'
                        onClick={() => {
                            alerto('Motivo da Rejeição', params.row.rejectDescription)
                        }}
                    >
                        VER
                    </Button>
                </div>
            ),
        },
        {
            field: 'actions',
            headerName: 'Ações',
            width: 400,
            headerAlign: 'center',
            align: 'left',
            renderCell: (params) => (
                <div>
                    <Button
                        style={{ maxWidth: '100px' }}
                        startIcon={
                            <Tooltip title='Baixar o arquivo'>
                                <DownloadIcon
                                    aria-label='Baixar'
                                />
                            </Tooltip>
                        }
                        onClick={async () => {
                            try {

                                showLinearProgress(true)

                                const id = Number(params.row.id)

                                const responseFromUpdateDoc = await UCMarkVisualizationEmployeeDocumento.run(id, signOutByExpiresProcess)
                                if (!responseFromUpdateDoc.ok) {
                                    const responseFromUpdateDocError = await responseFromUpdateDoc.json()
                                    alerto('Erro:', responseFromUpdateDocError.error || responseFromUpdateDocError.error.message)
                                }

                                const download = await UCDownloadEmployeeDocsService.run(params.row.documentGuid, signOutByExpiresProcess)

                                const blob = await download.blob();

                                const link = document.createElement('a');
                                link.href = window.URL.createObjectURL(blob);
                                link.download = params.row.documentGuid;

                                // Simule um clique no link para iniciar o download
                                link.click();

                                // Libere o objeto URL usado para criar o link
                                window.URL.revokeObjectURL(link.href);

                                showLinearProgress(false)
                                await getAllDocuments()
                            } catch (error: any) {
                                let message = error.error.message || error.error
                                alerto('Erro', message)
                            }
                            showLinearProgress(false)
                        }}>
                        Baixar
                    </Button>
                    <Button
                        disabled={params.row.rejectedDoc}
                        style={{ maxWidth: '100px' }}
                        color='error'
                        startIcon={
                            <Tooltip title='Rejeitar o arquivo'>
                                <ThumbDownAltIcon />
                            </Tooltip>
                        }
                        onClick={() => {
                            setDocumentEmployee(params.row)
                            setShowModal(true)
                        }}
                    >
                        Rejeitar
                    </Button>
                    <Button
                        className={params.row.favorite ? '' : 'button disabled'}
                        style={{ maxWidth: '100px' }}
                        startIcon={
                            <Tooltip title='Favoritar o arquivo'>
                                <FavoriteIcon />
                            </Tooltip>
                        }
                        onClick={async () => {
                            await favoriteUnfavorite(params,
                                showLinearProgress,
                                signOutByExpiresProcess,
                                alerto,
                                filtro,
                                getAllDocuments,
                                getAllDocumentsWithNoVisualization,
                                getAllDocumentsRejected,
                                getAllDocumentsFavorite);
                        }}
                    >
                        Favoritar
                    </Button>                    
                </div>
            ),
        }
    ]


    const handleDateIniChange = (date: any) => {
        setDateIni(date)
    };

    const handleDateFinalChange = (date: any) => {
        setDateFinal(date)
    };

    const {
        setDialogContextText,
        setTitle,
        handleOpen,
        signOut
    } = useAuth()

    const signOutByExpiresProcess = () => {
        signOut();
        navigate(ROTA_LOGIN)
    }


    const {
        showLinearProgress,
        new_Doc
    } = useActionContext()

    //menu, contador
    const getDocumentRequired = async (entityIdNumber: number) => {
        const docsRequest = await UCGetRequiredDocsService.run(entityIdNumber, signOutByExpiresProcess)
        const docsResult = await docsRequest.json()
        if (docsRequest.ok) {
            setRequireDocsCount(docsResult.length)
        }
    }

    const verificaDatasValidas = () => {
        const isvalidIni = dayjs(dateIni).isValid()
        const isvalidFinal = dayjs(dateFinal).isValid()
        if (!isvalidIni || !isvalidFinal) {
            throw new Error("Verifique as Datas da Pesquisa")
        }
    }

    const getAllDocuments = async () => {
        let entID = 0
        try {
            setFiltro(FILTROS.TODOS)
            verificaDatasValidas()
            showLinearProgress(true)
            const uc = getUserData()
            const id = Number(uc.sub)
            //encontrar o entity dono do usuario em questao
            const responseEntityFromUser = await UCGetEntityByUserIdFuncionarioService.run(id, signOutByExpiresProcess)

            if (!responseEntityFromUser.ok) {
                const retornoMessage: any = await responseEntityFromUser.json()
                errorMessageService("Erro ao resgatar documentos da entidade", responseEntityFromUser, alerto)
            }
            else {

                const entity = await responseEntityFromUser.json()

                setEntityId(Number(entity.id))
                entID = Number(entity.id)

                const response = await UCGetAllUploadEmployeeDocsService.run(Number(entity.id), dateIni?.toISOString(), dateFinal?.toISOString(), signOutByExpiresProcess)

                if (!response.ok) {
                    const error = await response.json()
                    let message = error.error.message || error.error
                    errorMessageService(message, response, alerto)
                    // alerto('Erro', message)
                } else {
                    const documentos = await response.json()

                    const documentosFiltered = documentos.filter((e: any) => {
                        return e.visualizationDate === null
                    })


                    const documentosFormatted = documentos.map((doc: any) => {
                        return {
                            ...doc,
                            dateToWarn: moment(doc.dateToWarn).format('DD/MM/YYYY'),
                            visualizationDate: doc.visualizationDate ? moment(doc.visualizationDate).format('DD/MM/YYYY') : '',
                            createdDate: doc.createdDate ? moment(doc.createdDate).format('DD/MM/YYYY') : ''
                        }
                    })
                    setDocumentsRows(documentosFormatted)
                    setNoVisualition(documentosFiltered.length)
                }
            }
            showLinearProgress(false)
            return entID
        } catch (error: any) {
            showLinearProgress(false)
            let message = error.message || error.error || error.error.message
            alerto('Erro', message)
        }
    }

    const getAllDocumentsWithNoVisualization = async () => {
        try {
            setFiltro(FILTROS.NAOBAIXADOS)
            verificaDatasValidas()
            showLinearProgress(true)
            const uc = getUserData()
            const id = Number(uc.sub)
            //encontrar o entity dono do usuario em questao
            const responseEntityFromUser = await UCGetEntityByUserIdFuncionarioService.run(id, signOutByExpiresProcess)

            if (!responseEntityFromUser.ok) {
                errorMessageService("Erro ao resgatar documentos da entidade", responseEntityFromUser, alerto)
            } else {

                const entity = await responseEntityFromUser.json()

                const response = await UCGetAllUploadEmployeeDocsService.run(Number(entity.id), dateIni?.toISOString(), dateFinal?.toISOString(), signOutByExpiresProcess)

                if (!response.ok) {
                    const error = await response.json()
                    let message = error.error.message || error.error
                    errorMessageService(message, response, alerto)
                } else {
                    const documentos = await response.json()

                    const documentosFiltered = documentos.filter((e: any) => {
                        return e.visualizationDate === null
                    })

                    const documentosFormatted = documentosFiltered.map((doc: any) => {
                        return {
                            ...doc,
                            dateToWarn: moment(doc.dateToWarn).format('DD/MM/YYYY'),
                            visualizationDate: doc.visualizationDate ? moment(doc.visualizationDate).format('DD/MM/YYYY') : '',
                            createdDate: doc.createdDate ? moment(doc.createdDate).format('DD/MM/YYYY') : ''
                        }
                    })
                    setDocumentsRows(documentosFormatted)
                    //setNoVisualition(documentosFormatted.length)
                }
            }
            showLinearProgress(false)
        } catch (error: any) {
            showLinearProgress(false)
            let message = error.message || error.error || error.error.message
            alerto('Erro', message)
        }
    }

    const getAllDocumentsFavorite = async () => {
        try {
            //setFiltro(FILTROS.FAVORITOS)
            //verificaDatasValidas()
            showLinearProgress(true)
            const uc = getUserData()
            const id = Number(uc.sub)
            //encontrar o entity dono do usuario em questao
            const responseEntityFromUser =
                await UCGetEntityByUserIdFuncionarioService.run(id, signOutByExpiresProcess)

            if (!responseEntityFromUser.ok) {
                errorMessageService("Erro ao resgatar documentos da entidade", responseEntityFromUser, alerto)
            } else {

                const entity =
                    await responseEntityFromUser.json()

                //sistema foi desenvolvido em 2024, entao nao precisa ser mais antiga
                const dtPadraoFav = '2023-01-01T03:00:00.000Z'

                //tratando para pegar ultimo dia do mes a pedido do Carlos
                const dtUltimoDiaMes = dayjs(ultimoDoMes)                

                const response =
                    await UCGetAllUploadEmployeeDocsService.run(Number(entity.id), 
                    dtPadraoFav, dtUltimoDiaMes?.toISOString(), signOutByExpiresProcess)

                if (!response.ok) {
                    const error =
                        await response.json()
                    let message = error.error.message || error.error
                    errorMessageService(message, response, alerto)
                } else {
                    const documentos =
                        await response.json()

                    const documentosFiltered = documentos.filter((e: any) => {
                        return e.favorite === true
                    })

                    const documentosFormatted = documentosFiltered.map((doc: any) => {
                        return {
                            ...doc,
                            dateToWarn: moment(doc.dateToWarn).format('DD/MM/YYYY'),
                            visualizationDate: doc.visualizationDate ? moment(doc.visualizationDate).format('DD/MM/YYYY') : '',
                            createdDate: doc.createdDate ? moment(doc.createdDate).format('DD/MM/YYYY') : ''
                        }
                    })
                    setDocumentsRows(documentosFormatted)
                    //setNoVisualition(documentosFormatted.length)
                }
            }
            showLinearProgress(false)
        } catch (error: any) {
            showLinearProgress(false)
            let message = error.message || error.error || error.error.message
            alerto('Erro', message)
        }
    }

    const getAllDocumentsRejected = async () => {
        try {
            setFiltro(FILTROS.REJEITADOS)
            verificaDatasValidas()
            showLinearProgress(true)
            const uc = getUserData()
            const id = Number(uc.sub)
            //encontrar o entity dono do usuario em questao
            const responseEntityFromUser = await UCGetEntityByUserIdFuncionarioService.run(id, signOutByExpiresProcess)

            if (!responseEntityFromUser.ok) {
                errorMessageService("Erro ao resgatar documentos da entidade", responseEntityFromUser, alerto)
            } else {

                const entity = await responseEntityFromUser.json()

                const response = await UCGetAllUploadEmployeeDocsService.run(Number(entity.id), dateIni?.toISOString(), dateFinal?.toISOString(), signOutByExpiresProcess)

                if (!response.ok) {
                    const error = await response.json()
                    let message = error.error.message || error.error
                    errorMessageService(message, response, alerto)
                } else {
                    const documentos = await response.json()

                    const documentosFiltered = documentos.filter((e: any) => {
                        return e.rejectedDoc
                    })

                    const documentosFormatted = documentosFiltered.map((doc: any) => {
                        return {
                            ...doc,
                            dateToWarn: moment(doc.dateToWarn).format('DD/MM/YYYY'),
                            visualizationDate: doc.visualizationDate ? moment(doc.visualizationDate).format('DD/MM/YYYY') : '',
                            createdDate: doc.createdDate ? moment(doc.createdDate).format('DD/MM/YYYY') : ''
                        }
                    })
                    setDocumentsRows(documentosFormatted)
                    //setNoVisualition(documentosFormatted.length)
                }
            }

            showLinearProgress(false)
        } catch (error: any) {
            showLinearProgress(false)
            let message = error.message || error.error || error.error.message
            alerto('Erro', message)
        }
    }

    const { handleCloseQuestionDialog } = useActionContext();

    const alerto = (title: string, content: string) => {
        handleOpen(true)
        setTitle(title)
        setDialogContextText(content)
    }


    useEffect(() => {
        getAllDocuments()
            .then(async (ret: any) => {
                await getDocumentRequired(ret)
            })
    }, [new_Doc])

    return (
        <Containerd>
            <QuestionDialog
                handleClose={handleCloseQuestionDialog}

                handleConfirm={async () => {
                }}

            ></QuestionDialog>
            <RejectDocumentForm
                open={showModal}
                onClose={() => {
                    getAllDocuments()
                    setShowModal(false)
                }}
                onConfirm={() => {
                    getAllDocuments()
                    setShowModal(false)
                }}
                title={`Deseja rejeitar o documento ${documentEmployee.id}?`}
                _EmployeeDocumentsModel={documentEmployee}
            />
            <Card variant="outlined">
                <CardHeader
                    avatar={
                        <Avatar aria-label="icon" >
                            <ArticleIcon />
                        </Avatar>
                    }
                    title="Documentos"
                    subheader="Consulte os Documentos que a sua Contabilidade enviou a Você!"
                />
                <CardContent>
                    <Paper elevation={1} style={{ padding: '20px', margin: '30px auto' }} >
                        <h4>Filtros: {filtro} </h4>
                        <Button
                            endIcon={<RestartAltIcon />}
                            onClick={() => {
                                getAllDocuments()
                            }}
                            variant='outlined' color='info'>Todos</Button>
                        <Button
                            endIcon={<FilterAltIcon />}
                            onClick={getAllDocumentsRejected}
                            variant='outlined' color='info'>Rejeitados </Button>
                        <Button
                            endIcon={<VisibilityOff />}
                            onClick={getAllDocumentsWithNoVisualization}
                            variant='outlined' color='info'>Não Baixados</Button>
                        <ContainerBox>
                            <DatePicker
                                name='dateIni'
                                value={dateIni}
                                onChange={handleDateIniChange}
                                sx={{ width: '100%' }}
                                label="Data Criação Inicio"
                            />
                            <DatePicker
                                name='dateFinal'
                                value={dateFinal}
                                onChange={handleDateFinalChange}
                                sx={{ width: '100%' }}
                                label="Data Criação Fim"
                            />
                        </ContainerBox>

                        <Paper elevation={0}
                            style={{
                                padding: '2px 0 15px 0',
                                margin: '3px auto',
                                width: '100%'
                            }} >
                            <h4>Filtro: Favoritos (Todos) </h4>
                            <Button
                                endIcon={<FavoriteIcon />}
                                onClick={getAllDocumentsFavorite}
                                variant='outlined' color='info'>Favoritos</Button>
                        </Paper>

                        <h4>Legenda</h4>
                        <LegendContainer>
                            <Squaro />
                            <LegendText>Doc. já baixados</LegendText>
                        </LegendContainer>
                    </Paper>
                    <GridoContainer>
                        <Paper elevation={1} style={{ padding: '20px', margin: '30px auto' }}>
                            <h2>
                                Documentos
                            </h2>
                            <Grido2
                                height={600}
                                onLoad={() => {

                                }}
                                getRow={(p: any) => {
                                    return p.row.visualizationDate ? 'visualization-date' : ''
                                }}
                                pageSize={10}
                                _columns={columns}
                                _rows={documentsRows}
                                onClick={() => { }}
                            />
                        </Paper>
                    </GridoContainer>
                </CardContent>
            </Card>
        </Containerd >
    );

}

export default DocumentosEntidadeFuncionario;

async function favoriteUnfavorite(params: any, showLinearProgress: (visible: boolean) => void, signOutByExpiresProcess: () => void, alerto: (title: string, content: string) => void, filtro: string, getAllDocuments: () => Promise<number | undefined>, getAllDocumentsWithNoVisualization: () => Promise<void>, getAllDocumentsRejected: () => Promise<void>,
    getAllDocumentsFavorite: () => Promise<void>) {
    try {
        const favVal = !params.row.favorite;
        showLinearProgress(true);
        const id = Number(params.row.id);
        await UCFavoriteEmployeeDocumento.run(id, favVal, signOutByExpiresProcess);
        showLinearProgress(false);
        let radical = '';
        radical = favVal ? 'Favoritado' : 'Desfavoritado';
        alerto('Sucesso', `Registro ${radical} com sucesso!`);
        if (filtro == FILTROS.TODOS) getAllDocuments();
        if (filtro == FILTROS.NAOBAIXADOS) getAllDocumentsWithNoVisualization();
        if (filtro == FILTROS.REJEITADOS) getAllDocumentsRejected();
        if (filtro == FILTROS.FAVORITOS) getAllDocumentsFavorite();
    } catch (error: any) {
        alerto('Erro', error.message);
        showLinearProgress(false);
    }
}

import { jwtDecode } from "jwt-decode"
import { MINHA_CARTEIRA_ACESS_TOKEN } from "../hooks/constants/localstorage-constants"

export const getUserData = (): UserData => {
  const uc: string = localStorage.getItem(MINHA_CARTEIRA_ACESS_TOKEN) as string
  const userData: UserData = jwtDecode(uc)
  return userData
}


export interface UserData {
  sub?: number
  username: string
  email: string
  password?: string
  roles: Role[]
  iat?: number
  exp?: number
  termos: boolean
}

export interface Role {
  id: number
  name?: string
}

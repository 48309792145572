
import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher2 } from "../../../services/fetcher.service"

const run = async (callback?: any) => {

    const url = `${URI_BASE}/entity/type/1`
    const response = await fetcher2(url, 'GET', null, callback)
    return response
}

const UCConsultarEntidadesByUserIdService = {
    run
}

export default UCConsultarEntidadesByUserIdService
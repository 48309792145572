import React from 'react';
import { BrowserRouter, Route, useNavigate } from 'react-router-dom';
import { Routes } from "react-router-dom";
import SignIn from '../views/Signin';
import ForgetPass from '../views/forget-password';
import LandingPage from '../views/landingPage';
import { ROTA_FORGET_PASS, ROTA_LOGIN, ROTA_ROOT } from '../constants/uri/constants.uri';



const AuthRoutes: React.FC = () => {
    const path = window.location.pathname
    //const navigate = useNavigate()

    // if (path != '/') {
         console.log('aqui:'+ path)
    //     return (
    //         <BrowserRouter>
    //             <Routes>
    //                 {/* <Route path={ROTA_ROOT} element={<LandingPage />} /> */}
    //                 <Route path={ROTA_LOGIN} element={<SignIn />} />
    //                 <Route path={ROTA_FORGET_PASS} element={<ForgetPass />} />
    //             </Routes>
    //         </BrowserRouter>
    //     )
    //}
    
    console.log('acolá')
    return (
        <BrowserRouter>
            <Routes>
                <Route path={ROTA_ROOT} element={<LandingPage />} />
                <Route path={ROTA_LOGIN} element={<SignIn />} />
                <Route path={ROTA_FORGET_PASS} element={<ForgetPass />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AuthRoutes;

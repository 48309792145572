
import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher2 } from "../../../services/fetcher.service"
import { FormState } from "../components"

const run = async (formState: FormState, callback: any) => {
    
    const url = `${URI_BASE}/employee-documents`

    return await fetcher2(url, 'POST', formState, callback)
}

const UCGravarEmployeeDocsService = {
    run
}

export default UCGravarEmployeeDocsService
const errorMessageService = async (messageToTrowWhenNot401: string, requestResponse: any, alerto: any) => {
    
    if (!requestResponse.ok) {
        if (requestResponse.status != 401) {
            alerto("Erro", messageToTrowWhenNot401)
        } else {
            alerto("Erro",'Acesso não autorizado ou expirado')
        }
    }
}

export default errorMessageService;
import { createGlobalStyle, styled } from 'styled-components';

export default createGlobalStyle`
    * {
       margin:0;
       padding:0;
       box-sizing:border-box;
    }

    html, body, #root{
        height:100%;
    }

    *, button, input {
        border:0;
        outline:0;
        font-family:'Roboto',sans-serif;
    }

    button{
        cursor: pointer;
    }
`;

export const Container = styled.div`
`
export const Row = styled.div`
`
export const Column = styled.div`
`
import React, { useState } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useNavigate } from 'react-router-dom'; // Importe o useHistory do React Router
import {
    Container,
    Rodape,
} from './styles';
import { Button } from '@mui/material';
import './style.css'
import Landing from './components/landingpage';
import Navbar from './components/navbar';

const LandingPage: React.FC = () => {
    const navigate = useNavigate()

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleToggle = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };



    return (
        <>
            <div className="App">
                <Navbar />
                <Landing />
            </div>
        </>
    );
}

export default LandingPage;
import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Containerd } from './styles';
import { useActionContext } from '../../../../hooks/actions';
import Paper from '@mui/material/Paper';

interface IBottomNavigationActionProps {
    labelCadastro: string,
    labelGrid: string,
    value: number,
    setShowCadastro: () => void,
    setHideCadastro: () => void,
    onSetValue: (newValue: any) => void
}

const BottomNavigationCtrl: React.FC<IBottomNavigationActionProps> = ({
    labelCadastro,
    labelGrid,
    value,
    setShowCadastro,
    setHideCadastro,
    onSetValue}) => {

    //const [value, setValue] = React.useState<number>(0);

    return (
        <Containerd>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        //setValue(newValue);
                        onSetValue(newValue)
                    }}
                >
                    <BottomNavigationAction
                        label={labelCadastro} icon={<PersonAddAltIcon />}
                        onClick={() => {
                            setShowCadastro()
                        }}
                    />
                    <BottomNavigationAction label={labelGrid} icon={<ListAltIcon />}
                        onClick={() => {
                            setHideCadastro()
                        }} />
                </BottomNavigation>
            </Paper>
        </Containerd>
    )
}

export default BottomNavigationCtrl;

import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher, fetcher2 } from "../../../services/fetcher.service"
import { FormState } from "../components"

const run = async (blobName: string, callback: any) => {

    const url = `${URI_BASE}/upload-blob/${blobName}`

    return await fetcher2(url, 'DELETE', null, callback)
}

const UCRemoverEmployeeDocsBlobService = {
    run
}

export default UCRemoverEmployeeDocsBlobService
import styled, { keyframes }  from "styled-components";


export const Grid = styled.div`    


  //max-height: 100%; /* Defina a altura máxima como 100% para que ele não ultrapasse o espaço alocado */
  //overflow: auto !important;   /* Adicione rolagem quando o conteúdo for maior que a altura máxima */


    @media only screen and (min-width: 768px) {

        background-color:${props => props.theme.color.secondary};
        color:${props => props.theme.color.black};
        
        /* styles for tablets and desktops */
        display:grid;
        grid-template-columns: 100% auto;
        grid-template-rows:68px auto;

        grid-template-areas: 
        'MH'
        'CT';
        
        height:100vh;                        
    }    

    @media only screen and (max-width: 767px) {
        /* styles for mobile devices */
        display:grid;
        grid-template-columns: 100% ;
        grid-template-rows:55px auto;

        grid-template-areas:         
        'MH'
        'CT';

        height:100vh;        
    }
`;


export const BarToProgress = styled.div`        
    /* background-color:${props => props.theme.color.primary};        
    height:5px; */

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); /* Cor preta com opacidade de 0.2 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; 
`;

export const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position:absolute;
  /* height: 100vh; */
  /* background-color: #f0f0f0; */
`;

export const LoadingText = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-right: 10px; /* Ajuste conforme necessário */
`;

export const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  padding:2px;
  border-top: 4px solid #333;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${spinAnimation} 1s linear infinite;
`;

// export const Rodape = styled.div`        
//     background-color: ${props => props.theme.color.info};
//     text-align:center;
//     font-size:22px;
//     footer {                
//         top:97%;
//         left:38%;
//         position: absolute;
//         color:white;        
//         a {
//             margin: 5px;
//             color:white;
//         }
//     }
// `
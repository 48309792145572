import { URI_BASE } from "../../constants/uri/constants.uri"

const login = (email: string, password: string) => {
    const data = {
        email, password
    }
    
    return fetch(`${URI_BASE}/auth/login`,
        {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin        
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },

            body: JSON.stringify({ username: email, password: password })
        }
    )

}

const AuthService = {
    login
}

export default AuthService
import React, { useEffect, useState } from 'react';
import { Containerd, GridoContainer } from './styles';
import ContabilidadesDesktop from './components/contabilidade-desktop';
import { GridColDef } from '@mui/x-data-grid';
import { Button, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useActionContext } from '../../hooks/actions';
import { useAuth } from '../../hooks/auth';
import Grido2 from '../../components/Grido2';
import UCConsultarEntidadesByUserIdService from './useCases/uc-consultar-entidades-contabilidade';
import QuestionDialog from '../../components/QuestionDialog';
import UCRemoverEntidadeContabilidadeService from './useCases/uc-deletar-entidade-contabilidade';
import { UserData, getUserData } from '../../services/storage.service';
import BottomNavigationCtrl from '../../components/CadastroUsuario/components/bottom-navigation';
import { useNavigate } from 'react-router-dom'; // Importe o useHistory do React Router
import { ROTA_LOGIN } from '../../constants/uri/constants.uri';
import errorMessageService from '../../services/errorMessage.service';

const Contabilidades: React.FC = () => {

    const navigate = useNavigate()
    //const userData: UserData = getUserData()
    const [value, setValue] = React.useState<number>(0);

    const {
        setOpenDialog,
        setOpenDialogText,
        setOpenDialogQuestionContext,
        show_FormCadContabilidade,
        showLinearProgress,
        setShowFormCadContabilidade,
        handleCloseQuestionDialog
    } = useActionContext();

    const {
        setDialogContextText,
        setTitle,
        handleOpen,
        signOut
    } = useAuth()

    const [estado, setEstado] = useState<number>(0);
    const [rows, setRows] = useState<any[]>([]);
    const [idToDelete, setIdToDelete] = useState<number>(0);

    const [rowToEdit, setRowToEdit] = useState<any>({});

    const alerto = (title: string, content: string) => {
        handleOpen(true)
        setTitle(title)
        setDialogContextText(content)
    }

    const signOutByExpiresProcess = () => {
        signOut();
        navigate(ROTA_LOGIN)
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 1 },
        { field: 'name', headerName: 'Contabilidade', width: 180 },
        { field: 'email', headerName: 'Email', width: 180 },
        { field: 'phoneNumber', headerName: 'Tel.', width: 110 },
        {
            field: 'contact', headerName: 'Contato', width: 120,
        },
        {
            field: 'contactEmail', headerName: 'Email Cont.', width: 180,
        },
        { field: 'contactPhone', headerName: 'Tel. Contato', width: 110 },
        { field: 'personType', headerName: 'T. Pessoa', width: 130,align:'center' },
        { field: 'document', headerName: 'Documento', width: 150 },
        {
            field: 'actions',
            headerName: 'Ações',
            width: 135,
            renderCell: (params) => (
                <div>
                    <Button startIcon={<EditIcon />} onClick={() => {
                        setRowToEdit(params.row)
                        setShowFormCadContabilidade(true)
                        setValue(0)
                    }}>
                    </Button>

                    <Button startIcon={<DeleteIcon />}
                        onClick={() => {
                            setOpenDialog(true)
                            setOpenDialogText('Remoção de Usuário')
                            setOpenDialogQuestionContext(`Deseja remover o registro ${params.row.name} - id (${params.row.id})?`)
                            setIdToDelete(Number(params.row.id))
                        }}>
                    </Button>
                </div>
            ),
        }
    ]

    const loadContabilidades = async () => {
        showLinearProgress(true)
        const response: any = await UCConsultarEntidadesByUserIdService.run(signOutByExpiresProcess)
        if (response.ok) {
            const contabilidades = await response.json()
            setRows(contabilidades)
        }
        else {            
            const error = await response.json()
            let message = error.error.message || error.error            
            await errorMessageService(message, response, alerto)            
        }
        showLinearProgress(false)
    }

    useEffect(() => {
        loadContabilidades()
    }, [estado])

    return (
        <Containerd>
            <QuestionDialog
                handleClose={handleCloseQuestionDialog}
                handleConfirm={async () => {

                    if (idToDelete > 0) {
                        showLinearProgress(true)                        
                        const response = await UCRemoverEntidadeContabilidadeService.run(idToDelete, signOutByExpiresProcess)
                        if (response.ok) {
                            alerto('Sucesso', 'Registro removido com sucesso')
                            setEstado(estado + 1)
                        } else {
                            const error = await response.json()
                            let message = error.error.message || error.error
                            alerto('Erro', message)
                        }
                    }
                    showLinearProgress(false)
                    handleCloseQuestionDialog()
                }}
            ></QuestionDialog>
            
            {show_FormCadContabilidade && (<ContabilidadesDesktop
                onEdit={() => {
                    setRowToEdit({})
                    return rowToEdit
                }}
            />)}
            <GridoContainer>
                {!show_FormCadContabilidade && (<Paper elevation={24} style={{ padding: '20px', margin: '30px auto' }}>
                    <h2>
                        Contabilidade
                    </h2>
                    <Grido2
                        height={600}
                        onLoad={() => {
                            loadContabilidades()
                        }}
                        pageSize={10}
                        _columns={columns}
                        _rows={rows}
                        onClick={() => { }}
                    />
                </Paper>)}
            </GridoContainer>
            <BottomNavigationCtrl
                labelCadastro='Cad. de Contabilidade'
                labelGrid='Lista de Contabilidades'
                setShowCadastro={() => { setShowFormCadContabilidade(true) }}
                setHideCadastro={() => { setShowFormCadContabilidade(false) }}
                value={value}
                onSetValue={setValue}
            />
        </Containerd >
    );
}

export default Contabilidades
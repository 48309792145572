import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Routes } from "react-router-dom";
import Layout from "../components/Layout";
import Master from '../views/Master';
import { checkRolesOnUserLogged } from '../services/checkRoles/check-roles.service';
import Contabilidades from '../views/Contabilidades';
import Clientes from '../views/Clientes';
import DocumentosEntidade from '../views/DocumentosEntitdade';
import DocumentosEntidadeFuncionario from '../views/DocumentosEntidadeEmployee';
import MasterGroupView from '../views/MasterGroupView';
import DashBoard from '../views/Dashboard';
import Comunicacao from '../views/Comunicacao';
import RequisicaoDocumento from '../views/RequisicaoDocumento';
import { ROTA_CLIENTES, ROTA_COMUNICACAO, ROTA_DOCUMENTOS_ENTIDADE, ROTA_MASTER_GROUP_VIEW, ROTA_PLANO, ROTA_REQUISICAO_DOCUMENTO, ROTA_ROOT } from '../constants/uri/constants.uri';
import LayoutAccountabilityClient from '../components/LayoutAccountabilityClient';

const isMasterRole = () => {

    const isMasterRole = checkRolesOnUserLogged(['master'])
    const isFuncionario = checkRolesOnUserLogged(['funcionario'])

    if (isMasterRole) {
        return ([
            <Route key={1} path={ROTA_ROOT} element={<Master />} />,
            <Route key={1} path={ROTA_MASTER_GROUP_VIEW} element={<MasterGroupView />} />
        ]);        
        
    } else if (isFuncionario) {
        return ([
            <Route key={1} path={ROTA_ROOT} element={<DocumentosEntidadeFuncionario />} />,
            <Route key={2} path={ROTA_COMUNICACAO} element={<Comunicacao />} />            
        ]);
    }
    else {
        return ([
            <Route key={1} path={ROTA_ROOT} element={<Contabilidades />} />,
            <Route key={2} path={ROTA_CLIENTES} element={<Clientes />} />,
            <Route key={2} path={ROTA_COMUNICACAO} element={<Comunicacao />} />,
            <Route key={3} path={ROTA_DOCUMENTOS_ENTIDADE} element={<DocumentosEntidade />} />,
            <Route key={4} path={ROTA_PLANO} element={<DashBoard />} />,
            <Route key={4} path={ROTA_REQUISICAO_DOCUMENTO} element={<RequisicaoDocumento />} />
        ]);
    }
};


const AppRoutes: React.FC = () => {
    const isFuncionario = checkRolesOnUserLogged(['funcionario'])

    return (
        <BrowserRouter>
            {!isFuncionario &&(<Layout>
                <Routes>
                    {isMasterRole()}
                </Routes>
            </Layout>)}
            {isFuncionario &&(<LayoutAccountabilityClient>
                <Routes>
                    {isMasterRole()}
                </Routes>
            </LayoutAccountabilityClient>)}
        </BrowserRouter>
    )
};

export default AppRoutes;
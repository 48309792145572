import React, { useState, useRef } from 'react';
import logoImg from '../../assets/logocaduceu.jpeg';
import logoGL from '../../assets/logogl.jpeg';
import Input from '../../components/Input';
import { useAuth } from '../../hooks/auth';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom'; // Importe o useHistory do React Router
import {
    Container,
    Logo,
    Form,
    FormTitle,
    CheckBoxLabel,
    Rodape,
} from './styles';
import { Button, Checkbox, CircularProgress, FormControlLabel, LinearProgress } from '@mui/material';
import UCForgetPasswordService from './useCases/uc-forget-password';
import { useActionContext } from '../../hooks/actions';
import { BarToProgress } from '../../components/Layout/styles';

const SignIn: React.FC = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [buttonText, setButtonText] = useState<string>('Acessar');
    const [isFocused, setIsFocused] = useState(false);


    const { signIn,
        setDialogContextText,
        setTitle,
        handleOpen
    } = useAuth();


    const {
        showLinearProgress,
        linearProgressRunning
    } = useActionContext();

    const alerto = (title: string, content: string) => {
        handleOpen(true)
        setTitle(title)
        setDialogContextText(content)
    }

    const forgetPassword = async () => {
        if (!email) {
            setIsFocused(true)
            alerto('Info', 'Necessário informar o email')

        } else {
            showLinearProgress(true)
            const response: any = await UCForgetPasswordService.run(email)
            showLinearProgress(false)
            if (response.ok) {
                alerto('Info', response.message)
            }
        }
    }


    return (
        <>
            <Container>
                {linearProgressRunning && (<BarToProgress >
                    {linearProgressRunning && (<LinearProgress />)}
                    <div>
                        <CircularProgress size={80} />
                    </div>
                </BarToProgress>)}

                <Form >
                    <FormTitle>Guarda Livros</FormTitle>

                    <Logo >
                        <img src={logoGL} alt='' />
                    </Logo>
                    <h3>Login</h3>

                    <Input
                        focused={isFocused}
                        type="email"
                        placeholder="e-mail"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <Input
                        type="password"
                        placeholder="senha"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {/* <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={termsAccepted}
                                onChange={(e) => setTermsAccepted(e.target.checked)}
                            />
                        }
                        label="Aceito os termos de uso"
                    />

                </div> */}


                    <Button variant="text" color="inherit"
                        type="button" onClick={async () => {
                            try {
                                setButtonText('Acessando...')
                                const logged = await signIn(email, password)
                                if (logged) { navigate('/') }
                                setButtonText('Acessar')
                            } catch (error) {
                                setButtonText('Acessar')
                            }
                        }}> {buttonText} </Button>
                    <Button variant="text" color="inherit"
                        type="button" onClick={async () => {
                            await navigate('/forget-pass')
                        }}> Esqueci minha senha </Button>


                    {/* <Button variant="text" color="info"
                    type="button" onClick={async () => {                 
                    }}> Visualizar Termos de uso </Button> */}

                </Form>
                <Rodape>
                    <footer>
                        <Button variant="text" color="inherit"
                            startIcon={<HomeIcon />}
                            type="button" onClick={async () => {
                                navigate('/')
                            }}> Home
                        </Button>
                        <Button variant="text" color="inherit"
                            startIcon={<SupportAgentIcon />}
                            type="button" onClick={async () => {
                                window.location.href = 'mailto:suporte@oguardalivros.com';
                            }}> Contato
                        </Button>
                        <Button variant="text" color="inherit"
                            startIcon={<WhatsAppIcon />}
                            type="button" onClick={async () => {
                                window.open('https://wa.me/5513997784036', '_blank')
                            }}> WhatsApp/Suporte
                        </Button>

                    </footer>
                </Rodape>
            </Container>
        </>
    );
}

export default SignIn;
import styled from 'styled-components';

export const Containerd = styled.div`
    h2{
        text-align:center;
        padding:0 0 5px 0;
    }    
`

export const Desktop = styled.div`
    display:none;    
    @media only screen and (min-width: 768px) {
        display:inline;        
    }  

`

export const Mobile = styled.div`
    display:none;
    @media only screen and (max-width: 767px) {        
        display:inline;        
    }    
`
import { URI_BASE } from "../../../constants/uri/constants.uri"
import { fetcher } from "../../../services/fetcher.service"
import { UserData } from "../../../services/storage.service"

const run = async () => {

    const url = `${URI_BASE}/usuario`

    const retorno = await fetcher<{
        name: string,
        email: string,
        id: number,
        statusCode: number
    }>(url, 'GET', null)

    return retorno
}

const UCConsultarUsuariosService = {
    run
}

export default UCConsultarUsuariosService
import { Card, CardContent, CardHeader, Grid, LinearProgress, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import UCGetPlanDescriptionService from "./useCases/uc-getPlan-description.service";
import { useAuth } from "../../hooks/auth";
import { UserData, getUserData } from "../../services/storage.service";
import { Containerd } from "./styles";
import StorageIcon from '@mui/icons-material/Storage';
import InfoIcon from '@mui/icons-material/Info';
import PercentIcon from '@mui/icons-material/Percent';
import { useActionContext } from "../../hooks/actions";
import { useNavigate } from "react-router-dom";
import { ROTA_LOGIN } from "../../constants/uri/constants.uri";

export interface PlanoDetalhe {
    kb: number,
    planoNome: string,
    planoId?: number;
    planoDescricao: string,
    quantidadeCadastros: number
}


const DashBoard: React.FC = () => {
    const navigate = useNavigate()
    const [porcentagemConsumido, setPorcentagemConsumido] = useState<string>('0')
    const [planoDetalhe, setPlanoDetalhe] = useState<PlanoDetalhe>({ kb: 0, planoDescricao: '', planoNome: '', quantidadeCadastros: 0, planoId: 0 })
    const uc: UserData = getUserData()

    const signOutByExpiresProcess = () => {
        signOut();
        navigate(ROTA_LOGIN)
    }

    const {
        signOut
    } = useAuth()

    const {
        showLinearProgress
     } = useActionContext()

    const getPlanDescription = async () => {
        showLinearProgress(true)
        const response = await UCGetPlanDescriptionService.run(signOutByExpiresProcess)

        if (response.ok) {
            const planDescription: PlanoDetalhe = await response.json()
            setPlanoDetalhe(planDescription)
            calculaPorcentagemConsumoPlano(planDescription)            
        }
        showLinearProgress(false)
    }

    const calcularPorcentagem = (valor: number, total: number) => {
        return (valor / total) * 100;
    }

    const calculaPorcentagemConsumoPlano = (pd: PlanoDetalhe) => {

        let total = '0'
        switch (pd.planoNome) {
            case 'Básico':
                total = calcularPorcentagem(pd.kb, 512000).toFixed(2)
                setPorcentagemConsumido(total)
                break;
            case 'Intermediário':
                total = calcularPorcentagem(pd.kb, (2097152)).toFixed(2)
                setPorcentagemConsumido(total)
                break;
            case 'Avançado':
                total = calcularPorcentagem(pd.kb, (4194304)).toFixed(2)
                setPorcentagemConsumido(total)
                break;
            default:
                break;
        }
    }

    const kbToMB = (kb: number) => {
        var gb = kb / (1024); // 1 GB = 1024^3 bytes
        return gb.toFixed(4);
    }

    const kbToGB = (kb: number) => {
        var gb = kb / (1024 * 1024); // 1 GB = 1024^3 bytes
        return gb.toFixed(5);
    }

    useEffect(() => {
        getPlanDescription()
    }, [])


    return (
        <Containerd>
            <Card variant="outlined">
                <CardHeader
                    title="Plano"
                    subheader="Detalhes"
                />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <InfoIcon sx={{ marginBottom: '-5px' }} />  Plano:
                        </Grid>
                        <Grid item xs={6}>
                            {planoDetalhe.planoNome}
                        </Grid>
                        <Grid item xs={6}>
                            <StorageIcon sx={{ marginBottom: '-5px' }} /> Consumido GB:
                        </Grid>
                        <Grid item xs={6}>
                            {kbToGB(planoDetalhe.kb)}
                        </Grid>
                        <Grid item xs={6}>
                            <StorageIcon sx={{ marginBottom: '-5px' }} /> Consumido MB:
                        </Grid>
                        <Grid item xs={6}>
                            {kbToMB(planoDetalhe.kb)}
                        </Grid>
                        <Grid item xs={6}>
                            <PercentIcon sx={{ marginBottom: '-5px' }} />   Consumido em Porcentagem:
                        </Grid>
                        <Grid item xs={6}>
                            {porcentagemConsumido}%
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress variant="determinate" value={Number(porcentagemConsumido)}
                                sx={{ height: '20px' }}
                            />
                        </Grid>

                    </Grid>

                </CardContent>
            </Card>
        </Containerd>
    )
}

export default DashBoard;